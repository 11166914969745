import { type Action, createReducer, on } from "@ngrx/store";
import type { GetManufacturesResponse, Product, ReplacedScreensStatistic } from "@cg/olb/shared";
import type { ChosenProduct } from "@cg/shared";
import * as ProductActions from "./product.actions";

export const PRODUCT_FEATURE_KEY = "product";

export interface ProductState {
  manufacturers: GetManufacturesResponse;
  error?: string | null;
  loaded: boolean;
  selectedManufacturer: string;
  selectedModel: string;
  selectedType: string;
  selectedBuildDate: string;
  selectedProduct: ChosenProduct;
  products: Product[];
  brands: string[];
  models: string[];
  types: string[];
  buildDates: string[];
  replacedScreensStatistic: ReplacedScreensStatistic;
}

export interface ProductPartialState {
  readonly [PRODUCT_FEATURE_KEY]: ProductState;
}

export const initialState: ProductState = {
  manufacturers: null,
  error: null,
  loaded: false,
  selectedManufacturer: null,
  selectedModel: null,
  selectedType: null,
  selectedBuildDate: null,
  selectedProduct: null,
  products: null,
  brands: null,
  models: null,
  types: null,
  buildDates: null,
  replacedScreensStatistic: null
};

const productReducer = createReducer(
  initialState,
  on(
    ProductActions.fetchAllManufacturersSuccess,
    (state: ProductState, { payload }: { payload: GetManufacturesResponse }) => ({
      ...state,
      loaded: true,
      error: null,
      manufacturers: payload
    })
  ),
  on(ProductActions.fetchAllManufacturersFailure, (state: ProductState, { error }: { error: string }) => ({
    ...state,
    error
  })),
  on(ProductActions.setSelectedManufacturer, (state: ProductState, { payload }: { payload: string }) => ({
    ...state,
    selectedManufacturer: payload
  })),
  on(ProductActions.fetchAllModelsSuccess, (state: ProductState, { payload }: { payload: string[] }) => ({
    ...state,
    loaded: true,
    error: null,
    models: payload
  })),
  on(ProductActions.fetchAllModelsFailure, (state: ProductState, { error }: { error: string }) => ({
    ...state,
    error
  })),
  on(ProductActions.setSelectedModel, (state: ProductState, { payload }: { payload: string }) => ({
    ...state,
    selectedModel: payload
  })),
  on(ProductActions.fetchAllTypesSuccess, (state: ProductState, { payload }: { payload: string[] }) => ({
    ...state,
    loaded: true,
    error: null,
    types: payload
  })),
  on(ProductActions.fetchAllTypesFailure, (state: ProductState, { error }: { error: string }) => ({
    ...state,
    error
  })),
  on(ProductActions.setSelectedType, (state: ProductState, { payload }: { payload: string }) => ({
    ...state,
    selectedType: payload
  })),
  on(ProductActions.fetchAllBuildDatesSuccess, (state: ProductState, { payload }: { payload: string[] }) => ({
    ...state,
    loaded: true,
    error: null,
    buildDates: payload
  })),
  on(ProductActions.fetchAllBuildDatesFailure, (state: ProductState, { error }: { error: string }) => ({
    ...state,
    error
  })),
  on(
    ProductActions.fetchAllReplacedScreensStatisticSuccess,
    (state: ProductState, { payload }: { payload: ReplacedScreensStatistic }) => ({
      ...state,
      loaded: true,
      error: null,
      replacedScreensStatistic: payload
    })
  ),
  on(ProductActions.fetchAllReplacedScreensStatisticFailure, (state: ProductState, { error }: { error: string }) => ({
    ...state,
    error
  })),
  on(ProductActions.setSelectedBuildDate, (state: ProductState, { payload }: { payload: string }) => ({
    ...state,
    selectedBuildDate: payload
  })),
  on(ProductActions.fetchAllProducts, (state: ProductState) => ({ ...state, products: null })),
  on(ProductActions.fetchAllProductsSuccess, (state: ProductState, { payload }: { payload: Product[] }) => ({
    ...state,
    loaded: true,
    error: null,
    products: payload
  })),
  on(ProductActions.fetchAllProductsFailure, (state: ProductState, { error }: { error: string }) => ({
    ...state,
    error,
    products: null
  })),
  on(ProductActions.resetAllProducts, (state: ProductState) => ({
    ...state,
    products: null,
    selectedManufacturer: null,
    selectedModel: null,
    selectedType: null,
    selectedBuildDate: null,
    selectedProduct: null
  })),
  on(ProductActions.setChosenProductSuccess, (state: ProductState, { payload }: { payload: ChosenProduct[] }) => ({
    ...state,
    selectedProduct: payload ? payload[0] : null
  })),
  on(ProductActions.setChosenProductFailure, (state: ProductState, { error }: { error: string }) => ({
    ...state,
    error,
    selectedProduct: null
  })),
  on(ProductActions.resetAllManufacturers, (state: ProductState) => ({
    ...state,
    manufacturers: null,
    brands: [],
    models: [],
    types: [],
    buildDates: [],
    selectedManufacturer: null,
    selectedModel: null,
    selectedType: null,
    selectedBuildDate: null
  })),
  on(ProductActions.resetAllModels, (state: ProductState) => ({
    ...state,
    models: [],
    types: [],
    buildDates: [],
    selectedType: null,
    selectedBuildDate: null
  })),
  on(ProductActions.resetAllTypes, (state: ProductState) => ({
    ...state,
    types: [],
    buildDates: []
  })),
  on(ProductActions.resetAllBuildDates, (state: ProductState) => ({
    ...state,
    buildDates: []
  })),
  on(ProductActions.resetProductState, () => ({ ...initialState }))
);

export function reducer(state: ProductState | undefined, action: Action) {
  return productReducer(state, action);
}
