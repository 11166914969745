import { Injectable } from "@angular/core";
import { GetGdvExitIds, Insurance, InsuranceNumber, InsuranceResponse, InsuranceType } from "@cg/olb/shared";
import { CarIdentificationService } from "./car-identification.service";

@Injectable({
  providedIn: "root"
})
export class GdvExitIdService {
  public constructor(private readonly carIdentificationService: CarIdentificationService) {}

  public getExitIdForRejectedGdvTransmission(
    insuranceType: InsuranceType,
    selectedInsurance: Insurance
  ): GetGdvExitIds {
    const hasSelectedNoInsurance =
      selectedInsurance?.number === InsuranceNumber.ANDERE &&
      [InsuranceType.UNKNOWN, InsuranceType.NONE, InsuranceType.FULLY].includes(insuranceType);
    let exitId: GetGdvExitIds = "noAcceptance";
    if (insuranceType === InsuranceType.LIABILITY || hasSelectedNoInsurance) {
      exitId = "basicInsuranceNoCar";
    }
    return exitId;
  }

  public getExitIdForAcceptedGdvTransmission(
    isDuplicate: boolean,
    insuranceResponse: InsuranceResponse,
    insuranceType: InsuranceType,
    selectedInsurance: Insurance
  ): GetGdvExitIds {
    if (isDuplicate) {
      return "duplicate" as GetGdvExitIds;
    }

    const hasLiabilityInsurance = insuranceType === InsuranceType.LIABILITY;
    const hasSelectedNoInsurance = selectedInsurance.number === InsuranceNumber.ANDERE;
    const isInsuranceFound = !!insuranceResponse.coverNote;
    const carIdentifiedExitId = this.carIdentificationService.getCarIdentifiedProcessId(insuranceResponse);

    if (hasLiabilityInsurance || hasSelectedNoInsurance) {
      return !insuranceResponse.vin ? "noData" : carIdentifiedExitId;
    } else {
      let cover: GetGdvExitIds = carIdentifiedExitId;
      let noCover: GetGdvExitIds = carIdentifiedExitId;

      if (!insuranceResponse.vin) {
        cover = "noData";
        noCover = "noAcceptance";
      }
      return isInsuranceFound ? cover : noCover;
    }
  }
}
