import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { combineLatest, Observable, tap } from "rxjs";
import { filter, map, take } from "rxjs/operators";
import { InsuranceService } from "@cg/olb/services";
import {
  ChatInsuranceType,
  Insurance,
  InsuranceNumber,
  InsuranceRequest,
  InsuranceType,
  InsuranceUseCase
} from "@cg/olb/shared";
import { OptionSelectionItem } from "@cg/shared";
import * as InsuranceActions from "./insurance.actions";
import * as InsuranceSelectors from "./insurance.selectors";

@Injectable({
  providedIn: "root"
})
export class InsuranceFacade {
  public loaded$ = this.store.pipe(select(InsuranceSelectors.hasLoadedInsurance));
  public type$ = this.store.pipe(select(InsuranceSelectors.getInsuranceType));
  public chatInsuranceType$ = this.store.pipe(select(InsuranceSelectors.getChatInsuranceType));
  public insurances$ = this.store.pipe(select(InsuranceSelectors.getInsurances));
  public topInsurances$ = this.store.pipe(select(InsuranceSelectors.getTopInsurances));
  public selectedInsurance$ = this.store.pipe(select(InsuranceSelectors.getSelectedInsurance));
  public insuranceResponse$ = this.store.pipe(select(InsuranceSelectors.getInsuranceResponse));
  public carModelFromInsuranceResponse$ = this.store.pipe(select(InsuranceSelectors.getCarModelFromInsuranceResponse));
  public vin$ = this.store.pipe(select(InsuranceSelectors.getVinFromInsuranceResponse));
  public vinChecksum$ = this.store.pipe(select(InsuranceSelectors.getVinChecksumFromInsuranceResponse));
  public gdvVin$ = this.store.pipe(select(InsuranceSelectors.getGdvVin));
  public policyNumber$ = this.store.pipe(select(InsuranceSelectors.getPolicyNumber));
  public damageNumber$ = this.store.pipe(select(InsuranceSelectors.getDamageNumber));

  public constructor(
    private store: Store,
    private readonly insuranceService: InsuranceService
  ) {}

  public loadInsurances() {
    this.store.dispatch(InsuranceActions.loadInsurances());
  }

  public setInsurances(insurances: Insurance[]) {
    this.store.dispatch(InsuranceActions.setInsurances({ payload: insurances }));
  }

  public setInsuranceType(type: InsuranceType) {
    this.store.dispatch(InsuranceActions.setInsuranceType({ payload: type }));
  }

  public setChatInsuranceType(type: ChatInsuranceType) {
    this.store.dispatch(InsuranceActions.setChatInsuranceType({ chatInsuranceType: type }));
  }

  public setInsuranceVin(vin: string) {
    this.store.dispatch(InsuranceActions.setInsuranceVin({ payload: vin }));
  }

  public setPolicyNumber(policyNumber: string) {
    this.store.dispatch(InsuranceActions.setPolicyNumber({ payload: policyNumber }));
  }

  public setDamageNumber(damageNumber: string) {
    this.store.dispatch(InsuranceActions.setDamageNumber({ payload: damageNumber }));
  }

  public setSelectedInsurance(value: string): void {
    if (value.length <= 3) {
      this.getInsuranceNameByNumber(value)
        .pipe(take(1))
        .subscribe((name: string) => {
          this.store.dispatch(InsuranceActions.setSelectedInsurance({ payload: { name, number: value } }));
        });
      return;
    }

    this.getInsuranceById(value)
      .pipe(take(1))
      .subscribe((insurance: Insurance) => {
        this.store.dispatch(InsuranceActions.setSelectedInsurance({ payload: insurance }));
      });
  }

  public setSelectedInsuranceById(id: string) {
    combineLatest([this.getInsuranceNameById(id), this.getInsuranceNumberById(id), this.getInsuranceIsPartnerById(id)])
      .pipe(take(1))
      .subscribe(([name, number, partner]: [string, string, boolean]) => {
        this.store.dispatch(InsuranceActions.setSelectedInsurance({ payload: { name, number, id, partner } }));
      });
  }

  public setUseCaseForInsuranceNumber(insuranceNumber: string) {
    return this.insuranceService.getUseCase(insuranceNumber).pipe(
      take(1),
      tap((insuranceUseCase: InsuranceUseCase) => this.setInsuranceUseCaseOfSelectedInsurance(insuranceUseCase))
    );
  }

  public setInsuranceUseCaseOfSelectedInsurance(insuranceUseCase: InsuranceUseCase) {
    this.store.dispatch(InsuranceActions.setInsuranceUseCaseOfSelectedInsurance({ payload: insuranceUseCase }));
  }

  public resetSelectedInsurance() {
    this.store.dispatch(InsuranceActions.resetSelectedInsurance());
  }

  public getInsuranceNameByNumber(number: string): Observable<string> {
    return this.store.pipe(select(InsuranceSelectors.getInsuranceNameByNumber(number)));
  }

  public getInsuranceIsPartnerById(id: string): Observable<boolean> {
    return this.store.pipe(select(InsuranceSelectors.getInsuranceIsPartnerById(id)));
  }

  public getInsuranceNameById(id: string): Observable<string> {
    return this.store.pipe(select(InsuranceSelectors.getInsuranceNameById(id)));
  }

  public getInsuranceNumberById(id: string): Observable<string> {
    return this.store.pipe(select(InsuranceSelectors.getInsuranceNumberById(id)));
  }

  public getInsuranceById(id: string): Observable<Insurance> {
    return this.store.pipe(select(InsuranceSelectors.getInsuranceById(id)));
  }

  public getInsuranceUseCaseById(id: string): Observable<InsuranceUseCase> {
    return this.store.pipe(select(InsuranceSelectors.getInsuranceUseCaseById(id)));
  }

  public createInsurancesOptions(): Observable<OptionSelectionItem[]> {
    const mapItem = (item: Insurance, idPrefix: string) => ({
      id: idPrefix.concat(item.name.replace(/\s/g, "-").toLowerCase()),
      value: item.id,
      text: item.name,
      disabled: false
    });

    return combineLatest([this.insurances$, this.topInsurances$]).pipe(
      filter(
        ([insurances, topInsurances]: [Insurance[], Insurance[]]) => insurances.length > 0 && topInsurances.length > 0
      ),
      map(
        (res: [Insurance[], Insurance[]]) =>
          [
            { value: "- 10 häufigste Versicherung -", text: "- 10 häufigste Versicherung -", disabled: true },
            ...res[1].map((item: Insurance) => mapItem(item, "top10")),
            { value: "- Alphabetisch sortiert -", text: "- Alphabetisch sortiert -", disabled: true },
            ...res[0].map((item: Insurance) => mapItem(item, "sorted"))
          ] as OptionSelectionItem[]
      )
    );
  }

  public createInsurancesOptionsWithTopInsurances(topInsuranceSize?: number): Observable<OptionSelectionItem[]> {
    const mapItem = (item: Insurance, top: boolean) => ({
      id: item.name.replace(/\s/g, "-").toLowerCase(),
      value: item.id,
      text: item.name,
      disabled: false,
      top
    });

    return combineLatest([this.insurances$, this.topInsurances$]).pipe(
      filter(
        ([insurances, topInsurances]: [Insurance[], Insurance[]]) => insurances.length > 0 && topInsurances.length > 0
      ),
      map(([insurances, topInsurances]: [Insurance[], Insurance[]]) => [
        ...insurances.map((insurance: Insurance) =>
          mapItem(
            insurance,
            !!topInsurances
              .slice(0, topInsuranceSize ? topInsuranceSize : topInsurances?.length)
              .find((topInsurance: Insurance) => topInsurance.number === insurance.number)
          )
        )
      ])
    );
  }

  public getInsurance(req: InsuranceRequest) {
    this.store.dispatch(InsuranceActions.getInsurance({ payload: req }));
  }

  public handleCarIsNotInsured() {
    this.store.dispatch(InsuranceActions.setInsuranceType({ payload: InsuranceType.NONE }));
    this.store.dispatch(
      InsuranceActions.setSelectedInsurance({ payload: { name: "Andere", number: InsuranceNumber.ANDERE } })
    );
  }

  public resetState() {
    this.store.dispatch(InsuranceActions.resetInsuranceState());
  }
}
