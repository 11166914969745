import { Inject, Injectable } from "@angular/core";
import { OLB_CONFIG, OlbConfiguration } from "@cg/olb/configuration";
import { LpnInputForm } from "@cg/lpn-input";
import { ChannelSwitchReason, LpnService, switchChannelBackendMapping } from "@cg/olb/shared";
import { ChannelSwitchDamage, EntryChannel, SwitchChannelPayload } from "@cg/shared";

@Injectable({
  providedIn: "root"
})
export class SwitchChannelPayloadService {
  public constructor(
    @Inject(OLB_CONFIG) private config: OlbConfiguration,
    private readonly lpnService: LpnService
  ) {}

  public buildSwitchChannelPayload(data: {
    customerCaseId: string;
    reason: ChannelSwitchReason;
    phoneNumber: string;
    damage?: ChannelSwitchDamage;
    lpnInputForm?: LpnInputForm;
  }): SwitchChannelPayload {
    const { customerCaseId, phoneNumber, reason, damage, lpnInputForm } = data;
    const backendSwitchChannelType = switchChannelBackendMapping[reason];
    const lpn = lpnInputForm ? this.lpnService.getRequestLpn(lpnInputForm) : null;

    let payload: SwitchChannelPayload = {
      id: customerCaseId,
      entryChannel: this.config.entryChannel,
      switchChannelReason: backendSwitchChannelType,
      switchChannelType: backendSwitchChannelType,
      customer: {},
      ...(lpn && { car: { lpn } })
    };

    /*
    For LFR, OLB_HDI_API and OLB_ALLIANZ_IOM customers we need to write the phone number
    into the contact phone field because we cannot update the customer
     */
    if ([EntryChannel.OLB_HDI_API, EntryChannel.LFR, EntryChannel.OLB_ALLIANZ_IOM].includes(this.config.entryChannel)) {
      payload.customer.contactPhone1 = phoneNumber;
    } else {
      payload.customer.customerPhone1 = phoneNumber;
    }

    if (damage?.id && damage?.compromisedPart && damage?.requiredService && !Number.isNaN(damage?.gmCount)) {
      const { id, compromisedPart, requiredService, gmCount } = damage;
      payload = {
        ...payload,
        damages: [
          {
            id,
            compromisedPart,
            requiredService,
            gmCount,
            occurrenceDate: damage.occurrenceDate ?? null
          }
        ]
      };
    }

    return payload;
  }
}
