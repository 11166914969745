import { createFeatureSelector, createSelector } from "@ngrx/store";
import { DAMAGE_FEATURE_KEY, type DamageState } from "./damage.reducer";

// Lookup the 'Damage' feature state managed by NgRx
export const getDamageState = createFeatureSelector<DamageState>(DAMAGE_FEATURE_KEY);

export const getDamageId = createSelector(getDamageState, (state: DamageState) => state.id);

export const getDamagedWindow = createSelector(getDamageState, (state: DamageState) => state.damageWindow);

export const getDamageType = createSelector(getDamageState, (state: DamageState) => state.damageType);

export const getDamageChipCount = createSelector(getDamageState, (state: DamageState) => state.damageChipCount);

export const getLocateDamage = createSelector(getDamageState, (state: DamageState) => state.damageLocation);

export const getRequiredService = createSelector(getDamageState, (state: DamageState) => state.requiredService);

export const getDamageSize = createSelector(getDamageState, (state: DamageState) => state.damageSize);

export const getDamageDate = createSelector(getDamageState, (state: DamageState) => state.damageDate);

export const getLpn = createSelector(getDamageState, (state: DamageState) => state.lpn);

export const getRawLpn = createSelector(getDamageState, (state: DamageState) => {
  const lpn = state.lpn;

  if (!lpn) {
    return null;
  }

  return `${lpn.region}${lpn.letters ? "-" + lpn.letters : ""}${lpn.numbers ? "-" + lpn.numbers : ""}${lpn.additionalNumbers ? "-" + lpn.additionalNumbers : ""}`;
});

export const getDamage = createSelector(getDamageState, (state: DamageState) => ({
  id: state.id,
  compromisedPart: state.damageWindow,
  requiredService: state.requiredService,
  damageChipCount: state.damageChipCount,
  damageDate: state.damageDate
}));

export const getDamagedSideWindowCount = createSelector(
  getDamageState,
  (state: DamageState) => state.damagedSideWindowCount
);

export const getDamagedRearWindowCount = createSelector(
  getDamageState,
  (state: DamageState) => state.damagedRearWindowCount
);
