import { Injectable } from "@angular/core";
import { Action, select, Store } from "@ngrx/store";
import { combineLatest, Observable } from "rxjs";
import { filter, first, map } from "rxjs/operators";
import { GetBuildDatesRequest, GetProductsRequest, GetTypesRequest } from "@cg/olb/shared";
import { ChosenProduct, OptionSelectionItem } from "@cg/shared";
import * as ProductActions from "./product.actions";
import { ProductPartialState } from "./product.reducer";
import * as ProductSelectors from "./product.selectors";

@Injectable({
  providedIn: "root"
})
export class ProductFacade {
  public loaded$ = this.store.pipe(select(ProductSelectors.hasLoaded));
  public otherBrands$ = this.store.pipe(select(ProductSelectors.getOtherBrands));
  public topBrands$ = this.store.pipe(select(ProductSelectors.getTopBrands));
  public models$ = this.store.pipe(select(ProductSelectors.getModels));
  public types$ = this.store.pipe(select(ProductSelectors.getTypes));
  public buildDates$ = this.store.pipe(select(ProductSelectors.getBuildDates));
  public products$ = this.store.pipe(select(ProductSelectors.getAllProducts));
  public productsCount$ = this.store.pipe(select(ProductSelectors.getAllProductsCount));
  public selectedProduct$ = this.store.pipe(select(ProductSelectors.getSelectedProduct));
  public error$ = this.store.pipe(select(ProductSelectors.hasError));
  public productId$ = this.store.pipe(select(ProductSelectors.getProductId));

  public selectedManufacturer$ = this.store.pipe(select(ProductSelectors.getSelectedManufacturer));
  public selectedModel$ = this.store.pipe(select(ProductSelectors.getSelectedModel));
  public selectedModelType$ = this.store.pipe(select(ProductSelectors.getSelectedType));
  public selectedBuildDate$ = this.store.pipe(select(ProductSelectors.getSelectedBuildDate));
  public selectCarModelAndManufacturer$ = this.store.pipe(select(ProductSelectors.getSelectCarModelAndManufacturer));
  public selectCarData$ = this.store.pipe(select(ProductSelectors.getSelectedCarData));
  public replacedScreensStatistic$ = this.store.pipe(select(ProductSelectors.getReplacedScreensStatistic));

  public constructor(private store: Store<ProductPartialState>) {}

  public loadAllManufacturers() {
    this.store.dispatch(ProductActions.fetchAllManufacturers());
  }

  public loadAllModels(brand: string) {
    this.store.dispatch(ProductActions.fetchAllModels({ payload: { brand } }));
  }

  public loadAllProducts(payload: GetProductsRequest) {
    this.store.dispatch(ProductActions.fetchAllProducts({ payload }));
  }

  public loadAllTypes(payload: GetTypesRequest) {
    this.store.dispatch(ProductActions.fetchAllTypes({ payload }));
  }

  public loadAllBuildDates(payload: GetBuildDatesRequest) {
    this.store.dispatch(ProductActions.fetchAllBuildDates({ payload }));
  }

  public loadReplacedScreensStatistic(payload: string) {
    this.store.dispatch(ProductActions.fetchAllReplacedScreensStatistic({ payload }));
  }

  public setSelectedManufacturer(payload: string) {
    this.store.dispatch(ProductActions.setSelectedManufacturer({ payload }));
  }

  public setSelectedModel(payload: string) {
    this.store.dispatch(ProductActions.setSelectedModel({ payload }));
  }

  public setSelectedModelType(payload: string) {
    this.store.dispatch(ProductActions.setSelectedType({ payload }));
  }

  public setSelectedBuildDate(payload: string) {
    this.store.dispatch(ProductActions.setSelectedBuildDate({ payload }));
  }

  public setSelectedProduct(payload: ChosenProduct[]) {
    this.store.dispatch(ProductActions.setChosenProductSuccess({ payload }));
  }

  private reset = (action: Action) => {
    this.store.dispatch(action);
  };

  public resetModels() {
    this.reset(ProductActions.resetAllModels());
  }

  public resetTypes() {
    this.reset(ProductActions.resetAllTypes());
  }

  public resetBuildDates() {
    this.reset(ProductActions.resetAllBuildDates());
  }

  public resetState() {
    this.store.dispatch(ProductActions.resetProductState());
  }

  public createManufacturerOptions(): Observable<OptionSelectionItem[]> {
    return combineLatest([this.otherBrands$, this.topBrands$]).pipe(
      filter(
        ([brands, topBrands]: [string[] | null, string[] | null]) =>
          brands && brands.length > 0 && topBrands && topBrands.length > 0
      ),
      first(),
      map(([brands, topBrands]: [string[] | null, string[] | null]) => [
        { id: "most-wanted-brands", value: "most-wanted-brands", text: "- 10 häufigsten Marken -", disabled: true },
        ...topBrands.map(this.mapItem),
        {
          id: "brands-sorted-alphabetically",
          value: "alphabetically sorted",
          text: "- Alphabetisch sortiert -",
          disabled: true
        },
        ...brands.map(this.mapItem)
      ])
    );
  }

  public createModelOptions(): Observable<OptionSelectionItem[]> {
    return this.models$.pipe(
      map((models: string[]) => (models && models.length > 0 ? [...models.map(this.mapItem)] : []))
    );
  }

  public createTypeOptions(): Observable<OptionSelectionItem[]> {
    return this.types$.pipe(map((types: string[]) => (types && types.length > 0 ? [...types.map(this.mapItem)] : [])));
  }

  public createBuildDateOptions(): Observable<OptionSelectionItem[]> {
    return this.buildDates$.pipe(
      map((buildDates: string[]) => (buildDates && buildDates.length > 0 ? [...buildDates.map(this.mapItem)] : []))
    );
  }

  private mapItem = (type: string) => ({
    id: type.replace(/\s/g, "-").toLowerCase(),
    value: type,
    text: type,
    disabled: false
  });
}
