import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { UpdateCustomerRequest } from "@cg/shared";
import * as ContactDataActions from "./contact-data.actions";
import { ContactDataPartialState, ContactDataState } from "./contact-data.reducer";
import * as ContactDataSelectors from "./contact-data.selectors";

@Injectable({
  providedIn: "root"
})
export class ContactDataFacade {
  public email$ = this.store.pipe(select(ContactDataSelectors.getEmail));
  public mobile$ = this.store.pipe(select(ContactDataSelectors.getMobile));
  public isPolicyHolder$ = this.store.pipe(select(ContactDataSelectors.getIsPolicyHolder));
  public customer$ = this.store.pipe(select(ContactDataSelectors.getCustomer));

  public driver$ = this.store.pipe(select(ContactDataSelectors.getDriver));

  public driverTitle$ = this.store.pipe(select(ContactDataSelectors.getDriverTitle));
  public driverFirstname$ = this.store.pipe(select(ContactDataSelectors.getDriverFirstname));
  public driverLastname$ = this.store.pipe(select(ContactDataSelectors.getDriverLastname));
  public driverStreet$ = this.store.pipe(select(ContactDataSelectors.getDriverStreet));
  public driverZip$ = this.store.pipe(select(ContactDataSelectors.getDriverZip));
  public driverCity$ = this.store.pipe(select(ContactDataSelectors.getDriverCity));
  public driverCountry$ = this.store.pipe(select(ContactDataSelectors.getDriverCountry));

  public insuranceHolder$ = this.store.pipe(select(ContactDataSelectors.getInsuranceHolder));

  public insuranceHolderCompany$ = this.store.pipe(select(ContactDataSelectors.getInsuranceHolderCompany));
  public insuranceHolderTitle$ = this.store.pipe(select(ContactDataSelectors.getInsuranceHolderTitle));
  public insuranceHolderFirstname$ = this.store.pipe(select(ContactDataSelectors.getInsuranceHolderFirstname));
  public insuranceHolderLastname$ = this.store.pipe(select(ContactDataSelectors.getInsuranceHolderLastname));
  public insuranceHolderStreet$ = this.store.pipe(select(ContactDataSelectors.getInsuranceHolderStreet));
  public insuranceHolderZip$ = this.store.pipe(select(ContactDataSelectors.getInsuranceHolderZip));
  public insuranceHolderCity$ = this.store.pipe(select(ContactDataSelectors.getInsuranceHolderCity));
  public insuranceHolderCountry$ = this.store.pipe(select(ContactDataSelectors.getInsuranceHolderCountry));
  public insuranceHolderPhone$ = this.store.pipe(select(ContactDataSelectors.getInsuranceHolderPhone));

  public constructor(private store: Store<ContactDataPartialState>) {}

  public setEmail(email: string): void {
    this.store.dispatch(ContactDataActions.setEmail({ payload: email }));
  }

  public setMobile(mobile: string): void {
    this.store.dispatch(ContactDataActions.setMobile({ payload: mobile }));
  }

  public setIsPolicyHolder(isPolicyHolder: boolean): void {
    this.store.dispatch(ContactDataActions.setIsPolicyHolder({ payload: isPolicyHolder }));
  }

  public setDriverTitle(title: string): void {
    this.store.dispatch(ContactDataActions.setDriverTitle({ payload: title }));
  }

  public setDriverFirstname(firstname: string): void {
    this.store.dispatch(ContactDataActions.setDriverFirstname({ payload: firstname }));
  }

  public setDriverLastname(lastname: string): void {
    this.store.dispatch(ContactDataActions.setDriverLastname({ payload: lastname }));
  }

  public setDriverStreet(street: string): void {
    this.store.dispatch(ContactDataActions.setDriverStreet({ payload: street }));
  }

  public setDriverZip(zip: string): void {
    this.store.dispatch(ContactDataActions.setDriverZip({ payload: zip }));
  }

  public setDriverCity(city: string): void {
    this.store.dispatch(ContactDataActions.setDriverCity({ payload: city }));
  }

  public setDriverCountry(country: string): void {
    this.store.dispatch(ContactDataActions.setDriverCountry({ payload: country }));
  }

  public setDriverPhone(phone: string): void {
    this.store.dispatch(ContactDataActions.setDriverPhone({ payload: phone }));
  }

  public setDriverAlternativePhone(phone: string): void {
    this.store.dispatch(ContactDataActions.setDriverAlternativePhone({ payload: phone }));
  }

  public setDriverEmail(email: string): void {
    this.store.dispatch(ContactDataActions.setDriverEmail({ payload: email }));
  }

  public setInsuranceHolderIsOrganization(isOrganization: boolean): void {
    this.store.dispatch(ContactDataActions.setInsuranceHolderIsOrganization({ payload: isOrganization }));
  }

  public setInsuranceHolderCompany(company: string): void {
    this.store.dispatch(ContactDataActions.setInsuranceHolderCompany({ payload: company }));
  }

  public setInsuranceHolderTitle(title: string): void {
    this.store.dispatch(ContactDataActions.setInsuranceHolderTitle({ payload: title }));
  }

  public setInsuranceHolderFirstname(firstname: string): void {
    this.store.dispatch(ContactDataActions.setInsuranceHolderFirstname({ payload: firstname }));
  }

  public setInsuranceHolderLastname(lastname: string): void {
    this.store.dispatch(ContactDataActions.setInsuranceHolderLastname({ payload: lastname }));
  }

  public setInsuranceHolderStreet(street: string): void {
    this.store.dispatch(ContactDataActions.setInsuranceHolderStreet({ payload: street }));
  }

  public setInsuranceHolderZip(zip: string): void {
    this.store.dispatch(ContactDataActions.setInsuranceHolderZip({ payload: zip }));
  }

  public setInsuranceHolderCity(city: string): void {
    this.store.dispatch(ContactDataActions.setInsuranceHolderCity({ payload: city }));
  }

  public setInsuranceHolderCountry(country: string): void {
    this.store.dispatch(ContactDataActions.setInsuranceHolderCountry({ payload: country }));
  }

  public setInsuranceHolderPhone(phone: string): void {
    this.store.dispatch(ContactDataActions.setInsuranceHolderPhone({ payload: phone }));
  }

  public setInsuranceHolderAlternativePhone(phone: string): void {
    this.store.dispatch(ContactDataActions.setInsuranceHolderAlternativePhone({ payload: phone }));
  }

  public setInsuranceHolderEmail(email: string): void {
    this.store.dispatch(ContactDataActions.setInsuranceHolderEmail({ payload: email }));
  }

  public updateCustomerContact(payload: UpdateCustomerRequest): void {
    this.store.dispatch(ContactDataActions.updateCustomerContact({ payload }));
  }

  public updateCustomerContactOpportunityFunnel(): void {
    this.store.dispatch(ContactDataActions.updateCustomerContactOpportunityFunnel());
  }

  public resetState(): void {
    this.store.dispatch(ContactDataActions.resetContactDataState());
  }

  public patchStateForResume(state: Partial<ContactDataState>) {
    this.store.dispatch(ContactDataActions.patchStateForResume({ payload: state }));
  }
}
