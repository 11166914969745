import { type Action, createReducer, on } from "@ngrx/store";
import type { Driver, InsuranceHolder } from "@cg/olb/shared";
import type { CustomerObjects } from "@cg/shared";
import * as ContactDataActions from "./contact-data.actions";

export const CONTACTDATA_FEATURE_KEY = "contactData";

export interface ContactDataState {
  email: string | null;
  mobile: string;
  isPolicyHolder: boolean;
  customer: CustomerObjects;
  driver: Driver;
  insuranceHolder: InsuranceHolder;
}

export interface ContactDataPartialState {
  readonly [CONTACTDATA_FEATURE_KEY]: ContactDataState;
}

export const initialState: ContactDataState = {
  email: null,
  mobile: null,
  isPolicyHolder: null,
  customer: null,
  driver: null,
  insuranceHolder: null
};

const contactDataReducer = createReducer(
  initialState,
  on(ContactDataActions.setEmail, (state: ContactDataState, { payload }: { payload: string }) => ({
    ...state,
    email: payload
  })),
  on(ContactDataActions.setMobile, (state: ContactDataState, { payload }: { payload: string }) => ({
    ...state,
    mobile: payload
  })),
  on(ContactDataActions.setIsPolicyHolder, (state: ContactDataState, { payload }: { payload: boolean }) => ({
    ...state,
    isPolicyHolder: payload
  })),

  on(ContactDataActions.setDriverTitle, (state: ContactDataState, { payload }: { payload: string }) => ({
    ...state,
    driver: { ...state.driver, title: payload }
  })),
  on(ContactDataActions.setDriverFirstname, (state: ContactDataState, { payload }: { payload: string }) => ({
    ...state,
    driver: { ...state.driver, firstname: payload }
  })),
  on(ContactDataActions.setDriverLastname, (state: ContactDataState, { payload }: { payload: string }) => ({
    ...state,
    driver: { ...state.driver, lastname: payload }
  })),
  on(ContactDataActions.setDriverStreet, (state: ContactDataState, { payload }: { payload: string }) => ({
    ...state,
    driver: { ...state.driver, street: payload }
  })),
  on(ContactDataActions.setDriverZip, (state: ContactDataState, { payload }: { payload: string }) => ({
    ...state,
    driver: { ...state.driver, zip: payload }
  })),
  on(ContactDataActions.setDriverCity, (state: ContactDataState, { payload }: { payload: string }) => ({
    ...state,
    driver: { ...state.driver, city: payload }
  })),
  on(ContactDataActions.setDriverCountry, (state: ContactDataState, { payload }: { payload: string }) => ({
    ...state,
    driver: { ...state.driver, country: payload }
  })),
  on(ContactDataActions.setDriverPhone, (state: ContactDataState, { payload }: { payload: string }) => ({
    ...state,
    driver: { ...state.driver, phone: payload }
  })),
  on(ContactDataActions.setDriverAlternativePhone, (state: ContactDataState, { payload }: { payload: string }) => ({
    ...state,
    driver: { ...state.driver, alternativePhone: payload }
  })),
  on(ContactDataActions.setDriverEmail, (state: ContactDataState, { payload }: { payload: string }) => ({
    ...state,
    driver: { ...state.driver, email: payload }
  })),

  on(
    ContactDataActions.setInsuranceHolderIsOrganization,
    (state: ContactDataState, { payload }: { payload: boolean }) => ({
      ...state,
      insuranceHolder: { ...state.insuranceHolder, isOrganization: payload }
    })
  ),
  on(ContactDataActions.setInsuranceHolderCompany, (state: ContactDataState, { payload }: { payload: string }) => ({
    ...state,
    insuranceHolder: { ...state.insuranceHolder, company: payload }
  })),
  on(ContactDataActions.setInsuranceHolderTitle, (state: ContactDataState, { payload }: { payload: string }) => ({
    ...state,
    insuranceHolder: { ...state.insuranceHolder, title: payload }
  })),
  on(ContactDataActions.setInsuranceHolderFirstname, (state: ContactDataState, { payload }: { payload: string }) => ({
    ...state,
    insuranceHolder: { ...state.insuranceHolder, firstname: payload }
  })),
  on(ContactDataActions.setInsuranceHolderLastname, (state: ContactDataState, { payload }: { payload: string }) => ({
    ...state,
    insuranceHolder: { ...state.insuranceHolder, lastname: payload }
  })),
  on(ContactDataActions.setInsuranceHolderStreet, (state: ContactDataState, { payload }: { payload: string }) => ({
    ...state,
    insuranceHolder: { ...state.insuranceHolder, street: payload }
  })),
  on(ContactDataActions.setInsuranceHolderZip, (state: ContactDataState, { payload }: { payload: string }) => ({
    ...state,
    insuranceHolder: { ...state.insuranceHolder, zip: payload }
  })),
  on(ContactDataActions.setInsuranceHolderCity, (state: ContactDataState, { payload }: { payload: string }) => ({
    ...state,
    insuranceHolder: { ...state.insuranceHolder, city: payload }
  })),
  on(ContactDataActions.setInsuranceHolderCountry, (state: ContactDataState, { payload }: { payload: string }) => ({
    ...state,
    insuranceHolder: { ...state.insuranceHolder, country: payload }
  })),
  on(ContactDataActions.setInsuranceHolderPhone, (state: ContactDataState, { payload }: { payload: string }) => ({
    ...state,
    insuranceHolder: { ...state.insuranceHolder, phone: payload }
  })),
  on(
    ContactDataActions.setInsuranceHolderAlternativePhone,
    (state: ContactDataState, { payload }: { payload: string }) => ({
      ...state,
      insuranceHolder: { ...state.insuranceHolder, alternativePhone: payload }
    })
  ),
  on(ContactDataActions.setInsuranceHolderEmail, (state: ContactDataState, { payload }: { payload: string }) => ({
    ...state,
    insuranceHolder: { ...state.insuranceHolder, email: payload }
  })),
  on(ContactDataActions.updateCustomerContactFailure, (state: ContactDataState, { error }: { error: string }) => ({
    ...state,
    error
  })),
  on(
    ContactDataActions.updateCustomerContactOpportunityFunnelFailure,
    (state: ContactDataState, { error }: { error: string }) => ({
      ...state,
      error
    })
  ),
  on(ContactDataActions.resetContactDataState, () => ({ ...initialState })),
  on(
    ContactDataActions.patchStateForResume,
    (state: ContactDataState, { payload }: { payload: Partial<ContactDataState> }) => ({ ...state, ...payload })
  )
);

export function reducer(state: ContactDataState | undefined, action: Action) {
  return contactDataReducer(state, action);
}
