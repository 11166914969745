import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { SharedFacade } from "@cg/carglass-shared-state";
import { Lpn, Resume } from "@cg/shared";
import { ResumeService } from "../../services/resume.service";
import * as ResumeActions from "./resume.actions";

@Injectable()
export class ResumeEffects {
  public load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResumeActions.loadResume),
      switchMap((action: { resumeId: string; lpn?: Lpn } & Action) =>
        this.resumeService.loadResume(action.resumeId, action.lpn).pipe(
          map((response: Resume) => {
            this.sharedFacade.setResumeResponse(response);
            return ResumeActions.loadResumeSuccess({ response });
          }),
          catchError((error: HttpErrorResponse) =>
            of(
              ResumeActions.loadResumeFailure({
                error: {
                  status: error.status,
                  statusText: error.statusText,
                  type: typeof error.error === "string" ? error.type : error.error.error,
                  url: error.url,
                  ok: error.ok,
                  body: error.error
                }
              })
            )
          )
        )
      )
    )
  );

  public constructor(
    private readonly actions$: Actions,
    private readonly resumeService: ResumeService,
    private readonly sharedFacade: SharedFacade
  ) {}
}
