import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import * as OpportunityFunnelActions from "./opportunity-funnel.actions";
import * as OpportunityFunnelSelectors from "./opportunity-funnel.selectors";

@Injectable({
  providedIn: "root"
})
export class OpportunityFunnelFacade {
  public callNow$ = this.store.pipe(select(OpportunityFunnelSelectors.getCallNow));

  public constructor(private store: Store) {}

  public setCallNow(callNow: boolean): void {
    this.store.dispatch(OpportunityFunnelActions.setCallNow({ payload: callNow }));
  }
}
