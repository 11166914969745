import { createAction, props } from "@ngrx/store";
import type { ResumeApiRequest } from "@cg/carglass-shared-state";
import type { UnifiedError } from "@cg/core/types";
import type { ChannelSwitchReason, PrefixComponentIds } from "@cg/olb/shared";
import type { ProcessId, ProcessMetadata } from "@cg/shared";

export const setCurrentProcess = createAction("[Process] Set Current Process", props<{ payload: ProcessId }>());

export const rewindProcess = createAction("[Process] Rewind Step", props<{ payload: ProcessId }>());

export const setProcessMetaData = createAction(
  "[Process] Set Process Meta Data",
  props<{ payload: ProcessMetadata }>()
);

export const setProcessPercentage = createAction("[Process] Set Process Percentage", props<{ payload: number }>());

export const setTechnicalError = createAction("[Process] Set Technical Error", props<{ error: string }>());

export const funnelCompleted = createAction(
  "[Process] Clear Process Meta Data",
  props<{ payload: ProcessMetadata[] }>()
);

export const channelSwitchEntered = createAction("[Process] Clear Process Meta Data On Channel Switch");

export const enterOlb = createAction("[Process] Enter Funnel");
export const exitOlb = createAction("[Process] Exit Funnel");

export const exitOlbDone = createAction("[Process] Exit Funnel Done");
export const navigate = createAction("[Process] Route After Exit");

export const setUrlToNavigate = createAction("[Process] Set Url To Navigate After Exit", props<{ payload: string }>());

export const setChannelSwitch = createAction("[Process] Set Channel Switch", props<{ payload: ChannelSwitchReason }>());

export const goBack = createAction("[Process] Go Back", props<{ payload: ProcessId }>());
export const goForwardSuccess = createAction(
  "[Process] Go Forward Success",
  props<{ payload: { processId: ProcessId; eventAction: string } }>()
);
export const goForwardFailure = createAction(
  "[Process] Go Forward Failure",
  props<{ payload: { processId: ProcessId; invalidFormFields: string[] } }>()
);

export const resetProcessState = createAction("[Process] Reset State");

export const scrollToTile = createAction("[Process] Scroll To Tile", props<{ payload: ProcessId }>());

export const updateProcessMetaDataFormData = createAction(
  "[Process] Update ProcessMetaData FormData",
  props<{ payload: Record<string, unknown> }>()
);

export const updateProcessMetaDataPassthroughId = createAction(
  "[Process] Update ProcessMetaData PassthroughExitId",
  props<{ payload: ProcessId }>()
);

export const restoreHasBegun = createAction("[Process/SaveRestore] Restore Hase Begun");

export const restoreIsDone = createAction("[Process/SaveRestore] Restore Is Done");

export const saveRestoreState = createAction(
  "[Process/SaveRestore] Save for Restore B2C",
  props<{
    correlationId?: string;
    optIn: boolean;
  }>()
);

// @todo: this should be a NoAppointment Action but cannot be because of circdeps
export const saveRestoreStateB2b = createAction(
  "[Process/SaveRestore] Save For Restore B2B",
  props<{
    correlationId?: string;
    overwriteRequest: Partial<ResumeApiRequest>;
  }>()
);

export const saveRestoreStateSuccess = createAction(
  "[Process/SaveRestore] Save For Restore Success",
  props<{ resumeId: string; correlationId?: string }>()
);
export const saveRestoreStateFailure = createAction(
  "[Process/SaveRestore] Save For Restore Failure",
  props<{ error: UnifiedError; correlationId?: string }>()
);

export const closeEditOverlay = createAction("[Process] Close edit overlay", props<{ processId: ProcessId }>());

export const removeLastTile = createAction("[Process] Remove last tile");

export const addPrefixComponent = createAction(
  "[Process] Add Prefix Component",
  props<{ processId: ProcessId; prefixComponent: PrefixComponentIds }>()
);

export const setEnterWithStateData = createAction(
  "[Process] Set Enter With State Data",
  props<{ enterWithStateData: boolean }>()
);

export const addRestoredIdFromState = createAction(
  "[Process] Add Restored Id From State",
  props<{ restoredId: ProcessId }>()
);

export const setPassthroughNextTileId = createAction(
  "[Process] Set Passthrough Next Tile Id",
  props<{ payload: ProcessId }>()
);
