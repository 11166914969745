import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import {
  Appointment,
  AppointmentLocation,
  AppointmentPayload,
  AppointmentServiceCenterPayload,
  AptModel,
  JobStatus,
  OpportunityFunnelAppointmentPeriod,
  SetAppointmentPayload
} from "@cg/shared";
import * as AppointmentActions from "./appointment.actions";
import { AppointmentPartialState } from "./appointment.reducer";
import * as AppointmentSelectors from "./appointment.selectors";

@Injectable({
  providedIn: "root"
})
export class AppointmentFacade {
  public availableAppointments$ = this.store.pipe(select(AppointmentSelectors.getAvailableAppointments));
  public availableServiceCenters$ = this.store.pipe(select(AppointmentSelectors.getAvailableServiceCenters));
  public searchedAppointment$ = this.store.pipe(select(AppointmentSelectors.getSearchedAppointment));
  public currentAppointment$ = this.store.pipe(select(AppointmentSelectors.getCurrentAppointment));
  public confirmed$ = this.store.pipe(select(AppointmentSelectors.isConfirmed));
  public formattedAddress$ = this.store.pipe(select(AppointmentSelectors.getFormattedAddress));
  public serviceCenterLatLng$ = this.store.pipe(select(AppointmentSelectors.getServiceCenterLatLng));
  public appointmentId$ = this.store.pipe(select(AppointmentSelectors.getAppointmentId));
  public selectedServiceCenterIds$ = this.store.pipe(select(AppointmentSelectors.getSelectedServiceCenterIds));
  public selectedServiceCenter$ = this.store.pipe(select(AppointmentSelectors.getSelectedServiceCenter));
  public status$ = this.store.pipe(select(AppointmentSelectors.getStatus));
  public position$ = this.store.pipe(select(AppointmentSelectors.getPosition));
  public mobileServiceAvailable$ = this.store.pipe(select(AppointmentSelectors.getMobileServiceAvailable));
  public appointmentNextLoading$ = this.store.pipe(select(AppointmentSelectors.getAppointmentNextLoading));
  public nextLoadingLimitReached$ = this.store.pipe(select(AppointmentSelectors.getNextLoadingLimitReached));
  public isLoading$ = this.store.pipe(select(AppointmentSelectors.isLoading));
  public isCalibration$ = this.store.pipe(select(AppointmentSelectors.isCalibration));
  public hasAdverseBuyAppointments$ = this.store.select(AppointmentSelectors.hasAdverseBuyAppointments);
  public hasAppointmentLoaded$ = this.store.select(AppointmentSelectors.hasAppointmentLoaded);
  public startDate$ = this.store.select(AppointmentSelectors.getStartDate);
  public isConfirmed$ = this.store.select(AppointmentSelectors.isConfirmed);
  public desiredAppointmentDate$ = this.store.select(AppointmentSelectors.getDesiredAppointmentDate);
  public desiredPeriod$ = this.store.select(AppointmentSelectors.getDesiredPeriod);
  public desiredAppointmentId$ = this.store.select(AppointmentSelectors.getDesiredAppointmentId);
  public earliestPossibleAppointmentDate$ = this.store.select(AppointmentSelectors.getEarliestPossibleAppointmentDate);
  public aptModel$ = this.store.select(AppointmentSelectors.getAptModel);
  public locality$ = this.store.select(AppointmentSelectors.getLocality);
  public searchClicked$ = this.store.select(AppointmentSelectors.getSearchClicked);
  public firstSearch$ = this.store.select(AppointmentSelectors.getFirstSearch);

  public constructor(private store: Store<AppointmentPartialState>) {}

  public clearAndRefetchAppointments(selectedServiceCenterId?: string, timerangeBegin?: string) {
    this.store.dispatch(
      AppointmentActions.clearAndRefetchAppointments({
        payload: { serviceCenterId: selectedServiceCenterId, timerangeBegin }
      })
    );
  }

  public fetchTeslaAppointments(selectedServiceCenterId?: string, timerangeBegin?: string) {
    this.store.dispatch(
      AppointmentActions.fetchTeslaAppointments({
        payload: { serviceCenterId: selectedServiceCenterId, timerangeBegin }
      })
    );
  }

  public confirmAppointment(appointment: SetAppointmentPayload) {
    this.store.dispatch(AppointmentActions.confirmAppointment({ payload: appointment }));
  }

  public getAppointments(appointmentPayload: AppointmentPayload) {
    this.store.dispatch(AppointmentActions.getAppointments({ payload: appointmentPayload }));
  }

  public getNextAppointments(date: Date | string, aptModel?: AptModel) {
    this.store.dispatch(AppointmentActions.getNextAppointments({ payload: { date: date.toString(), aptModel } }));
  }

  public getServiceCenters(payload: AppointmentServiceCenterPayload) {
    this.store.dispatch(AppointmentActions.getServiceCenters({ payload }));
  }

  public validateDesiredDate(appointmentPayload: AppointmentPayload) {
    this.store.dispatch(AppointmentActions.validateAppointmentDate({ payload: appointmentPayload }));
  }

  public setCurrentAppointment(appointment: Appointment) {
    this.store.dispatch(AppointmentActions.setCurrentAppointment({ payload: appointment }));
  }

  public setFormattedAddress(formattedAddress: string) {
    this.store.dispatch(AppointmentActions.setFormattedAddress({ payload: formattedAddress }));
  }

  public setLatLng(lat: number, lng: number) {
    this.store.dispatch(AppointmentActions.setLatLng({ payload: { lat, lng } }));
  }

  public setAppointmentId(appointmentId: string) {
    this.store.dispatch(AppointmentActions.setAppointmentId({ payload: appointmentId }));
  }

  public setSelectedServiceCenterIds(serviceCenterIds: string[]) {
    this.store.dispatch(AppointmentActions.setSelectedServiceCenterIds({ payload: serviceCenterIds }));
  }

  public setAppointmentLocation(serviceCenterLocation: AppointmentLocation) {
    this.store.dispatch(AppointmentActions.setAppointmentLocation({ payload: serviceCenterLocation }));
  }

  public setMobileServiceAvailable(mobileServiceAvailable: boolean) {
    this.store.dispatch(AppointmentActions.setMobileServiceAvailable({ payload: mobileServiceAvailable }));
  }

  public saveAutocompleteResult({
    lat,
    lng,
    address,
    locality,
    timerangeBegin
  }: {
    lat: number;
    lng: number;
    address: string;
    locality: string;
    timerangeBegin?: string;
  }) {
    this.store.dispatch(
      AppointmentActions.saveAutoCompleteResult({
        payload: {
          lat,
          lng,
          formattedAddress: address,
          timerangeBegin,
          locality
        }
      })
    );
  }

  public setStatus(status: JobStatus) {
    this.store.dispatch(AppointmentActions.setStatus({ payload: status }));
  }

  public setNextLoadingLimitReached(limitReached: boolean) {
    this.store.dispatch(AppointmentActions.setNextLoadingLimitReached({ payload: limitReached }));
  }

  public resetStateForForm() {
    this.store.dispatch(AppointmentActions.resetStateForForm());
  }

  public resetState() {
    this.store.dispatch(AppointmentActions.resetAppointmentState());
  }

  public reloadAppointments() {
    this.store.dispatch(AppointmentActions.reloadAppointments());
  }

  public setDesiredAppointmentDate(date: Date) {
    this.store.dispatch(AppointmentActions.setDesiredAppointmentDate({ payload: date }));
  }

  public setDesiredPeriod(period: OpportunityFunnelAppointmentPeriod) {
    this.store.dispatch(AppointmentActions.setDesiredPeriod({ payload: period }));
  }

  public fetchEarliestPossibleAppointmentDate() {
    this.store.dispatch(AppointmentActions.fetchEarliestPossibleAppointmentDate());
  }

  // was added for OptimizelyExperiment.NEW_APPOINTMENT_TILE, remove this comment if test is successfull
  public setSearchClicked(searchClicked: boolean) {
    this.store.dispatch(AppointmentActions.setSearchClicked({ payload: searchClicked }));
  }

  // was added for OptimizelyExperiment.NEW_APPOINTMENT_TILE, remove this comment if test is successfull
  public setFirstSearch(firstSearch: boolean) {
    this.store.dispatch(AppointmentActions.setFirstSearch({ payload: firstSearch }));
  }
}
