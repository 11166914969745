import { type Action, createReducer, on } from "@ngrx/store";
import { AdditionalProduct, type CheckDuplicateReponse, type ChosenProduct, type CustomerCase } from "@cg/shared";
import type { VAPsFailureEventData, VAPsSuccessEventData } from "@cg/olb/shared";
import * as CustomerCaseActions from "./customer-case.actions";

export const CUSTOMERCASE_FEATURE_KEY = "customerCase";

// TODO customer case interface weiter aufbauen es fehlt hier
export interface CustomerCaseState {
  loaded: boolean;
  customerCase: CustomerCase;
  duplicate: boolean;
  protectProduct: boolean;
  wiperProduct: boolean;
  isOrganization: boolean;
  error?: string | null; // last none error (if any)
}

export interface CustomerCasePartialState {
  readonly [CUSTOMERCASE_FEATURE_KEY]: CustomerCaseState;
}

export const initialState: CustomerCaseState = {
  customerCase: null,
  loaded: false,
  duplicate: null,
  protectProduct: null,
  wiperProduct: null,
  isOrganization: false
};

// TODO Siehe Actions...Es fehlen noch actions, effects und co!! Das ist eine Baustelle
export const customerCaseReducer = createReducer(
  initialState,
  on(
    CustomerCaseActions.fetchCustomerCaseSuccess,
    CustomerCaseActions.confirmCustomerCaseSuccess,
    CustomerCaseActions.updateCustomerCaseSuccess,
    (state: CustomerCaseState, { payload }: { payload: CustomerCase }) => ({
      ...state,
      customerCase: payload,
      loaded: true,
      error: null,
      isOrganization: payload?.customer?.customerIsOrganization === "Y"
    })
  ),
  on(CustomerCaseActions.fetchCustomerCaseFailure, (state: CustomerCaseState, { error }: { error: string }) => ({
    ...state,
    error
  })),
  on(CustomerCaseActions.setLoaded, (state: CustomerCaseState, { payload }: { payload: boolean }) => ({
    ...state,
    loaded: payload
  })),
  on(CustomerCaseActions.setCustomerCaseId, (state: CustomerCaseState, { payload }: { payload: string }) => ({
    ...state,
    customerCase: { ...state.customerCase, id: payload }
  })),
  on(CustomerCaseActions.setContextId, (state: CustomerCaseState, { payload }: { payload: string }) => ({
    ...state,
    customerCase: { ...state.customerCase, contextId: payload }
  })),
  on(CustomerCaseActions.confirmCustomerCaseFailure, (state: CustomerCaseState, { error }: { error: string }) => ({
    ...state,
    error
  })),
  on(CustomerCaseActions.updateCustomerCaseFailure, (state: CustomerCaseState, { error }: { error: string }) => ({
    ...state,
    error
  })),
  on(
    CustomerCaseActions.checkDuplicateSuccess,
    (state: CustomerCaseState, { payload }: { payload: CheckDuplicateReponse }) => ({
      ...state,
      duplicate: payload ? payload.duplicate : null,
      error: null
    })
  ),
  on(CustomerCaseActions.resetCustomerCaseState, () => ({ ...initialState })),
  on(CustomerCaseActions.checkDuplicateFailure, (state: CustomerCaseState, { error }: { error: string }) => ({
    ...state,
    error
  })),
  // TODO
  on(CustomerCaseActions.switchChannelSuccess, (state: CustomerCaseState, { payload }: { payload: CustomerCase }) => ({
    ...state,
    ...payload
  })),
  // TODO
  on(CustomerCaseActions.switchChannelFailure, (state: CustomerCaseState, { error }: { error: string }) => ({
    ...state,
    error
  })),
  on(
    CustomerCaseActions.patchStateForResume,
    (state: CustomerCaseState, { payload }: { payload: Partial<CustomerCaseState> }) => ({ ...state, ...payload })
  ),
  on(CustomerCaseActions.addVAPSuccess, (state: CustomerCaseState, { payload }: { payload: VAPsSuccessEventData }) => {
    if (payload.product === AdditionalProduct.PROTECT) return { ...state, protectProduct: payload.value };
    if (payload.product === AdditionalProduct.WIPER) return { ...state, wiperProduct: payload.value };
    else return state;
  }),
  on(
    CustomerCaseActions.removeVAPSuccess,
    (state: CustomerCaseState, { payload }: { payload: VAPsSuccessEventData }) => {
      if (payload.product === AdditionalProduct.PROTECT) return { ...state, protectProduct: false };
      if (payload.product === AdditionalProduct.WIPER) return { ...state, wiperProduct: false };
      else return state;
    }
  ),
  on(CustomerCaseActions.addVAPFailure, (state: CustomerCaseState, { payload }: { payload: VAPsFailureEventData }) => ({
    ...state,
    error: payload.errorMessage
  })),
  on(
    CustomerCaseActions.removeVAPFailure,
    (state: CustomerCaseState, { payload }: { payload: VAPsFailureEventData }) => ({
      ...state,
      error: payload.errorMessage
    })
  ),
  on(
    CustomerCaseActions.setShoppingCartEntries,
    (state: CustomerCaseState, { payload }: { payload: ChosenProduct[] }) => ({
      ...state,
      customerCase: { ...state.customerCase, shoppingCartEntries: payload }
    })
  )
);

export function reducer(state: CustomerCaseState | undefined, action: Action) {
  return customerCaseReducer(state, action);
}
