import { createFeatureSelector, createSelector } from "@ngrx/store";
import type { GetManufacturesResponse, Product } from "@cg/olb/shared";
import { PRODUCT_FEATURE_KEY, type ProductState } from "./product.reducer";

// Lookup the 'Product' feature state managed by NgRx
export const getProductState = createFeatureSelector<ProductState>(PRODUCT_FEATURE_KEY);

export const hasLoaded = createSelector(getProductState, (state: ProductState) => state?.loaded ?? false);

export const hasError = createSelector(getProductState, (state: ProductState) => state.error);

export const getAllProducts = createSelector(getProductState, (state: ProductState) => state?.products ?? null);

export const getAllProductsCount = createSelector(
  getAllProducts,
  hasLoaded,
  (state: Product[], hasLoadedTopBrands: boolean) => (hasLoadedTopBrands && state ? state.length : null)
);

export const getSelectedProduct = createSelector(getProductState, (state: ProductState) => state.selectedProduct);

export const getManufactures = createSelector(getProductState, (state: ProductState) => state.manufacturers);

export const getTopBrands = createSelector(
  getManufactures,
  hasLoaded,
  (state: GetManufacturesResponse, hasLoadedTopBrands: boolean) =>
    hasLoadedTopBrands && state ? state.topBrands : null
);

export const getOtherBrands = createSelector(
  getManufactures,
  hasLoaded,
  (state: GetManufacturesResponse, hasLoadedBrands: boolean) => (hasLoadedBrands && state ? state.otherBrands : null)
);

export const getModels = createSelector(getProductState, (state: ProductState) => state.models);

export const getTypes = createSelector(getProductState, (state: ProductState) => state.types);

export const getBuildDates = createSelector(getProductState, (state: ProductState) => state.buildDates);

export const getSelectedManufacturer = createSelector(
  getProductState,
  (state: ProductState) => state.selectedManufacturer
);

export const getSelectedModel = createSelector(getProductState, (state: ProductState) => state.selectedModel);

export const getSelectedType = createSelector(getProductState, (state: ProductState) => state.selectedType);

export const getSelectedBuildDate = createSelector(getProductState, (state: ProductState) => state.selectedBuildDate);

export const getReplacedScreensStatistic = createSelector(
  getProductState,
  (state: ProductState) => state.replacedScreensStatistic
);

export const getProductId = createSelector(getAllProducts, hasLoaded, (state: Product[], loaded: boolean) => {
  if (!loaded || !state || state.length === 0) {
    return null;
  }

  return state[0].carglassNr;
});

export const getSelectCarModelAndManufacturer = createSelector(getProductState, (state: ProductState) =>
  state.products?.length > 0 ? `${state.products[0].brand}, ${state.products[0].model}` : null
);

export const getSelectedCarData = createSelector(
  getSelectedManufacturer,
  getSelectedModel,
  getSelectedBuildDate,
  getSelectedType,
  (manufacturer: string, model: string, buildDate: string, selectedType: string) => ({
    manufacturer,
    model,
    buildDate,
    selectedType
  })
);
