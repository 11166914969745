import { Inject, Injectable } from "@angular/core";
import { combineLatest, Observable, of, switchMap, tap } from "rxjs";
import { distinctUntilChanged, filter, first, map, mergeMap, take } from "rxjs/operators";
import { OLB_CONFIG, OlbConfiguration } from "@cg/olb/configuration";
import { GdvExitIdService } from "@cg/olb/services";
import { ResumeFacade } from "@cg/resume-core";
import { SecurityFacade } from "@cg/core/api";
import {
  Context,
  Driver,
  GetGdvExitIds,
  GetProductsRequest,
  Insurance,
  InsuranceHolder,
  InsuranceRequest,
  InsuranceResponse,
  InsuranceType,
  isHuk,
  LpnService
} from "@cg/olb/shared";
import {
  ConfigFacade,
  CustomerCase,
  DamageWindow,
  GetGdv,
  LicensePlateType,
  Lpn,
  ProcessMetadata,
  RequiredService,
  Resume,
  ResumeType,
  UpdateCustomerRequest
} from "@cg/shared";
import { AppointmentFacade } from "../appointment/appointment.facade";
import { ChannelSwitchFacade } from "../channel-switch/channel-switch.facade";
import { ContactDataFacade } from "../contact-data/contact-data.facade";
import { ContextFacade } from "../context/context.facade";
import { CustomerCaseFacade } from "../customer-case/customer-case.facade";
import { CustomerCaseState } from "../customer-case/customer-case.reducer";
import { DamageFacade } from "../damage/damage.facade";
import { GdvFacade } from "../gdv/gdv.facade";
import { InsuranceFacade } from "../insurance/insurance.facade";
import { ProcessFacade } from "../process/process.facade";
import { ProductFacade } from "../product/product.facade";
import { ToastFacade } from "../toast/toast.facade";

@Injectable({ providedIn: "root" })
export class OlbFacade {
  // eslint-disable-next-line max-params
  public constructor(
    private channelSwitchFacade: ChannelSwitchFacade,
    private processFacade: ProcessFacade,
    private contextFacade: ContextFacade,
    private customerCaseFacade: CustomerCaseFacade,
    private securityFacade: SecurityFacade,
    private damageFacade: DamageFacade,
    private gdvFacade: GdvFacade,
    private insuranceFacade: InsuranceFacade,
    private productFacade: ProductFacade,
    private contactDataFacade: ContactDataFacade,
    private appointmentFacade: AppointmentFacade,
    private toastFacade: ToastFacade,
    private lpnService: LpnService,
    private readonly resumeFacade: ResumeFacade,
    private readonly exitIdService: GdvExitIdService,
    private readonly configFacade: ConfigFacade,
    @Inject(OLB_CONFIG) private _config: OlbConfiguration
  ) {}

  public get config() {
    return this._config;
  }

  /**
   * @Todo is it correct to call this method on server?
   * CP-18278
   */
  public initOLB(fromError: boolean = false): void {
    const accessToken$ = this.securityFacade.accessToken$;

    if (!this.config?.context?.shouldCreate) {
      this.configFacade.getConfigFor(this._config?.externalConfiguration?.groupNames);
      return;
    }

    const context$ = this.contextFacade.context$;
    const customerCaseId$ = this.contextFacade.customerCaseId$;
    const resumeResponse$ = this.resumeFacade.resumeResponse$;

    combineLatest([accessToken$, this.insuranceFacade.selectedInsurance$])
      .pipe(
        filter(
          ([accessToken, insurance]: [string, Insurance]) =>
            !!accessToken && !!insurance && this._config.entryChannel.toLowerCase().includes("cobranded")
        ),
        first(),
        switchMap(([_accessToken, insurance]: [string, Insurance]) =>
          this.insuranceFacade.setUseCaseForInsuranceNumber(insurance.number)
        )
      )
      .subscribe();

    /**
     * @Todo on server are all the observables null
     */
    combineLatest([accessToken$, context$, resumeResponse$])
      .pipe(
        filter(([accessToken]: [string, Context, Resume]) => !!accessToken),
        first()
      )
      .subscribe(([_, context, resumeResponse]: [string, Context, Resume]) => {
        if (resumeResponse && !fromError) {
          this.resumeOLB();
        } else if (!context?.customerCaseId) {
          this.contextFacade.createContext();
        }

        if (fromError) {
          this.processFacade.initOlbAfterError$.next(true);
        }
      });

    customerCaseId$.pipe(filter((customerCaseId: string) => !!customerCaseId)).subscribe((customerCaseId: string) => {
      this.customerCaseFacade.getCustomerCase(customerCaseId);
    });
  }

  public resetOLB(): void {
    this.appointmentFacade.resetState();
    this.channelSwitchFacade.resetState();
    this.contactDataFacade.resetState();
    this.contextFacade.resetState();
    this.customerCaseFacade.resetState();
    this.gdvFacade.resetState();
    this.insuranceFacade.resetState();
    this.processFacade.resetState();
    this.productFacade.resetState();
    this.toastFacade.resetState();
    this.damageFacade.resetState();
  }

  public async resumeOLB(): Promise<boolean> {
    // eslint-disable-next-line sonarjs/cognitive-complexity,@typescript-eslint/typedef
    return new Promise((resolve) => {
      this.processFacade.restoreHasBegun();

      this.resumeFacade.resumeResponse$.pipe(first()).subscribe((resumeResponse: Resume) => {
        const customerCaseState: Partial<CustomerCaseState> = {
          loaded: true,
          duplicate: false,
          customerCase: {
            id: resumeResponse.customerCaseId
          }
        };

        this.customerCaseFacade.patchStateForResume(customerCaseState);
        this.customerCaseFacade.getCustomerCase(resumeResponse.customerCaseId);

        const appointment = resumeResponse.state?.appointment;
        if (appointment) {
          // we restore the selected appointment into state for later usage
          this.appointmentFacade.setAppointmentId(appointment.appointmentId);
          this.appointmentFacade.setCurrentAppointment(appointment);
          this.appointmentFacade.setSelectedServiceCenterIds([appointment.serviceCenter]);
        }

        if (resumeResponse.state?.processMetaData) {
          const statesToRestore = this.getResumeStatesTillAppointment(resumeResponse.state.processMetaData);

          this.applyResumeStates(statesToRestore);
        }

        this.processFacade.restoreIsDone();
        resolve(true);
      });
    });
  }

  public resumeAfterAppointment() {
    this.appointmentFacade.isConfirmed$
      .pipe(
        filter((v: boolean) => v),
        take(1)
      )
      .subscribe(() => {
        this.resumeFacade.resumeResponse$.pipe(first()).subscribe((resumeResponse: Resume) => {
          if (resumeResponse?.state?.processMetaData) {
            const statesToRestore = this.getResumeStatesAfterAppointment(resumeResponse.state.processMetaData);

            if (statesToRestore.length > 0) {
              this.applyResumeStates(statesToRestore);
            }
          }
        });
      });
  }

  public checkDuplicate(): void {
    const lpn$ = this.damageFacade.lpn$;
    const customerCase$ = this.customerCaseFacade.customerCase$;

    combineLatest([lpn$, customerCase$])
      .pipe(first())
      .subscribe(([lpn, customerCase]: [Lpn, CustomerCase]) => {
        this.customerCaseFacade.checkDuplicate(
          { ...lpn, type: lpn.type ?? LicensePlateType.NORMAL, country: "DE" },
          customerCase.id
        );
      });
  }

  public getInsurance(callbackFn?: () => void): void {
    const lpn$ = this.damageFacade.lpn$;
    const damageDate$ = this.damageFacade.damageDate$;
    const customerCaseId$ = this.customerCaseFacade.customerCaseId$;
    const insuranceType$ = this.insuranceFacade.type$;
    const selectedInsurance$ = this.insuranceFacade.selectedInsurance$;
    const fetchGdv$ = this.gdvFacade.fetch$;
    const firstname$ = this.contactDataFacade.insuranceHolderFirstname$;
    const lastname$ = this.contactDataFacade.insuranceHolderLastname$;

    combineLatest<[Lpn, string, string, InsuranceType, Insurance, GetGdv, string, string]>([
      lpn$,
      damageDate$,
      customerCaseId$,
      insuranceType$,
      selectedInsurance$,
      fetchGdv$,
      firstname$,
      lastname$
    ])
      .pipe(
        filter(
          ([lpn, damageDate, _customerCaseId, _insuranceType, _selectedInsurance, _fetchGdv, _firstname, _lastname]: [
            Lpn,
            string,
            string,
            InsuranceType,
            Insurance,
            GetGdv,
            string,
            string
          ]) => !!damageDate && !!_selectedInsurance?.number && !!lpn
        ),
        first(),
        tap(
          ([lpn, damageDate, customerCaseId, insuranceType, selectedInsurance, fetchGdv, firstname, lastname]: [
            Lpn,
            string,
            string,
            InsuranceType,
            Insurance,
            GetGdv,
            string,
            string
          ]) => {
            const reqLpn = this.lpnService.getRequestLpn(lpn);

            let req: InsuranceRequest = {
              lpn: reqLpn,
              occurrenceDate: damageDate,
              customerCaseId,
              insuranceType,
              insuranceNumber: selectedInsurance.number,
              requestInsuranceData: fetchGdv === GetGdv.TRANSMISSION
            };

            if (isHuk(selectedInsurance.number)) {
              req = { ...req, firstName: firstname, lastName: lastname };
            }

            this.insuranceFacade.getInsurance(req);
          }
        ),
        tap(() => {
          if (callbackFn) {
            callbackFn();
          }
        })
      )
      .subscribe();
  }

  public getInsuranceNoDateNoGdv(callbackFn?: () => void): void {
    this.damageFacade.setDamageDate(null);
    const lpn$ = this.damageFacade.lpn$;
    const customerCaseId$ = this.customerCaseFacade.customerCaseId$;
    const insuranceType$ = this.insuranceFacade.type$;
    const selectedInsurance$ = this.insuranceFacade.selectedInsurance$;

    combineLatest([lpn$, customerCaseId$, insuranceType$, selectedInsurance$])
      .pipe(
        first(),
        map(([lpn, customerCaseId, insuranceType, selectedInsurance]: [Lpn, string, InsuranceType, Insurance]) => {
          const reqLpn = this.lpnService.getRequestLpn(lpn);

          const req: InsuranceRequest = {
            lpn: reqLpn,
            customerCaseId,
            insuranceType,
            insuranceNumber: selectedInsurance.number,
            requestInsuranceData: false
          };

          return req;
        }),
        tap((req: InsuranceRequest) => {
          this.insuranceFacade.getInsurance(req);
          if (callbackFn) {
            callbackFn();
          }
        })
      )
      .subscribe();
  }

  public getInfoAboutCoverageAndCar(): Observable<[DamageWindow, RequiredService, string, string]> {
    const selectedDamage$ = this.damageFacade.selectedDamage$;
    const requiredService$ = this.damageFacade.requiredService$;
    const determinedCarByInsurance$ = this.insuranceFacade.carModelFromInsuranceResponse$;
    const vin$ = this.insuranceFacade.vin$;

    return combineLatest([selectedDamage$, requiredService$, determinedCarByInsurance$, vin$]);
  }

  public getAllProducts(): void {
    const customerCaseId$ = this.customerCaseFacade.customerCaseId$;
    const vin$ = this.insuranceFacade.vin$;
    const productType$ = this.damageFacade.requiredService$;
    const part$ = this.damageFacade.selectedDamage$;
    const brand$ = this.productFacade.selectedManufacturer$;
    const model$ = this.productFacade.selectedModel$;
    const carType$ = this.productFacade.selectedModelType$;
    const buildDate$ = this.productFacade.selectedBuildDate$;

    combineLatest([customerCaseId$, vin$, productType$, part$, brand$, model$, carType$, buildDate$])
      .pipe(
        /* Fix for https://int.carglass.de/jira/browse/CP-18942. If the customer changed the licence plate number, so a new VIN is requested.
                                For the new product request, we have to check that the vin is changed.*/
        distinctUntilChanged(
          (
            [
              _prevCustomerCaseId,
              prevVin,
              _prevProductType,
              _prevPart,
              prevBrand,
              prevModel,
              prevCarType,
              prevBuildDate
            ]: [string, string, RequiredService, DamageWindow, string, string, string, string],
            [
              _currCustomerCaseId,
              currVin,
              _currProductType,
              _currPart,
              currBrand,
              currModel,
              currCarType,
              currBuildDate
            ]: [string, string, RequiredService, DamageWindow, string, string, string, string]
          ) =>
            prevVin === currVin &&
            prevBrand === currBrand &&
            prevModel === currModel &&
            prevCarType === currCarType &&
            prevBuildDate === currBuildDate
        ),
        filter(
          ([_customerCaseId, vin, productType, _part, brand, model, carType, buildDate]: [
            string,
            string,
            RequiredService,
            DamageWindow,
            string,
            string,
            string,
            string
          ]) => !!vin || (!!brand && !!model && !!carType && !!buildDate) || productType === RequiredService.REPAIR
        ),
        take(1)
      )
      .subscribe(
        ([customerCaseId, vin, productType, part, brand, model, carType, buildDate]: [
          string,
          string,
          RequiredService,
          DamageWindow,
          string,
          string,
          string,
          string
        ]) => {
          const payload: GetProductsRequest = {
            customerCaseId,
            entryChannel: this.config.entryChannel,
            vin,
            productType,
            part,
            brand,
            model,
            carType,
            buildDate
          };

          this.productFacade.loadAllProducts(payload);
        }
      );
  }

  public updateCustomerContact(): void {
    const customerCaseId$ = this.customerCaseFacade.customerCaseId$;
    const driver$ = this.contactDataFacade.driver$;
    const customer$ = this.contactDataFacade.insuranceHolder$;
    const phone$ = this.contactDataFacade.mobile$;
    const email$ = this.contactDataFacade.email$;
    const isPolicyHolder$ = this.contactDataFacade.isPolicyHolder$;
    const resumeResponse$ = this.resumeFacade.resumeResponse$;

    combineLatest([customerCaseId$, driver$, customer$, phone$, email$, isPolicyHolder$, resumeResponse$])
      .pipe(
        filter(
          ([_, _driver, customer, phone, _email, isPolicyHolder, _resume]: [
            string,
            Driver,
            InsuranceHolder,
            string,
            string,
            boolean,
            Resume
          ]) => {
            if (isPolicyHolder) {
              return !!phone;
            }

            return !!customer;
          }
        ),
        first()
      )
      .subscribe(
        ([customerCaseId, driver, customer, phone, email, isPolicyHolder, resume]: [
          string,
          Driver,
          InsuranceHolder,
          string,
          string,
          boolean,
          Resume
        ]) => {
          let payload: UpdateCustomerRequest = {
            customerCaseId,
            driver: {
              address: {
                addressLine1: driver.street,
                addressLine2: driver.street,
                zipCode: driver.zip,
                city: driver.city,
                country: driver.country,
                street: driver.street
              },
              contact: {
                titleDriver: driver.title,
                firstName: driver.firstname,
                lastName: driver.lastname,
                title: driver.title,
                email,
                phone
              },
              optInEmail: false,
              optInSms: false
            }
          };

          const isIom = resume?.resumeType === ResumeType.B2B_IOM;
          if (customer && (!isPolicyHolder || isIom)) {
            const updatedCustomer = {
              address: {
                addressLine1: customer.street,
                addressLine2: customer.street,
                zipCode: customer.zip,
                city: customer.city,
                country: customer.country,
                street: customer.street
              },
              contact: {
                titleCustomer: customer.title,
                firstName: customer.firstname,
                lastName: customer.lastname,
                title: customer.title,
                phone: customer.phone,
                companyName: customer.company,
                organization: customer.company,
                ...(isIom && !customer.email && { email })
              }
            };

            payload = {
              ...payload,
              customer: updatedCustomer
            };
          }

          this.contactDataFacade.updateCustomerContact(payload);
        }
      );
  }

  public getGdvExitIdForCurrentState(): Observable<GetGdvExitIds> {
    const duplicate$ = this.customerCaseFacade.duplicate$;
    const insuranceResponse$ = this.insuranceFacade.insuranceResponse$;
    const insuranceType$ = this.insuranceFacade.type$;
    const selectedInsurance$ = this.insuranceFacade.selectedInsurance$;
    const fetchGdv$ = this.gdvFacade.fetch$;

    return combineLatest([duplicate$, insuranceResponse$, insuranceType$, selectedInsurance$, fetchGdv$]).pipe(
      filter(
        ([duplicate, insuranceResponse, insuranceType, selectedInsurance, fetchGdv]: [
          boolean,
          InsuranceResponse,
          InsuranceType,
          Insurance,
          GetGdv
        ]) => {
          const rejectedGdvFilter = duplicate !== null && insuranceType !== null && selectedInsurance !== null;
          if (fetchGdv === GetGdv.NO_TRANSMISSION) {
            return rejectedGdvFilter;
          }
          return rejectedGdvFilter && insuranceResponse !== null;
        }
      ),
      mergeMap(
        ([duplicate, insuranceResponse, insuranceType, selectedInsurance, fetchGdv]: [
          boolean,
          InsuranceResponse,
          InsuranceType,
          Insurance,
          GetGdv
        ]) => {
          this.getAllProducts();
          if (fetchGdv === GetGdv.NO_TRANSMISSION) {
            return of(this.exitIdService.getExitIdForRejectedGdvTransmission(insuranceType, selectedInsurance));
          }
          return of(
            this.exitIdService.getExitIdForAcceptedGdvTransmission(
              duplicate,
              insuranceResponse,
              insuranceType,
              selectedInsurance
            )
          );
        }
      )
    );
  }

  public isRestoring(): Observable<boolean> {
    return this.resumeFacade.restoring$;
  }

  private getResumeStatesTillAppointment(states: ProcessMetadata[]) {
    const appointmentIndex = states?.findIndex((state: ProcessMetadata) =>
      /^tesla-appointment$|^appointment$/.test(state.id)
    );

    if (appointmentIndex > -1) {
      return [...states.slice(0, appointmentIndex + 1)]; // +1 because we also want to include appointment
    }

    return states;
  }

  private getResumeStatesAfterAppointment(states: ProcessMetadata[]) {
    const appointmentIndex = states?.findIndex((state: ProcessMetadata) =>
      /^tesla-appointment$|^appointment$/.test(state.id)
    );

    if (appointmentIndex > -1 && appointmentIndex + 1 < states.length) {
      return [...states.slice(appointmentIndex + 1)]; // +1 because we want to exclude appointment
    }

    return [];
  }

  private applyResumeStates(statesToRestore: ProcessMetadata[]) {
    statesToRestore.forEach((state: ProcessMetadata, index: number) => {
      this.processFacade.setProcessMetaData({
        ...state,
        valid: statesToRestore.length - 1 !== index
      });
    });

    const lastState = [...statesToRestore].pop();
    this.processFacade.setCurrentProcessId(lastState.id);
  }

  public mustResumeAfterAppointment(): Observable<boolean> {
    return this.resumeFacade.resumeResponse$.pipe(
      map((resume: Resume) => this.getResumeStatesAfterAppointment(resume?.state?.processMetaData).length > 0)
    );
  }

  public track() {}
}
