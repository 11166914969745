import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "@cg/core/api";
import { DamageRequest, DamageResponse } from "@cg/shared";

@Injectable({
  providedIn: "root"
})
export class DamageService {
  public constructor(private apiService: ApiService) {}

  public updateDamage(payload: DamageRequest, customerCaseId: string): Observable<DamageResponse> {
    return this.apiService.post("frontendProxy", `/customerCase/${customerCaseId}/damage`, payload);
  }
}
