import { type Action, createReducer, on } from "@ngrx/store";
import { uniqBy } from "@cg/utils";
import { PLACEHOLDER } from "@cg/core/ui";
import type { DesiredDateResponse } from "@cg/olb/shared";
import type {
  Appointment,
  AppointmentLocation,
  AppointmentPayload,
  AppointmentResponse,
  AptModel,
  AvailableServiceCenters,
  JobStatus,
  OpportunityFunnelAppointmentPeriod,
  SetAppointmentPayload
} from "@cg/shared";
import * as AppointmentActions from "./appointment.actions";

export const APPOINTMENT_FEATURE_KEY = "appointment";

function areValidScIds(scIds: string[]) {
  return (
    !!scIds && scIds.length > 0 && scIds.every((scId: string) => !!scId && scId !== "null" && scId !== PLACEHOLDER)
  );
}

function getAvailableAppointmentsBySelectedServiceCenters(
  payload: AppointmentResponse,
  selectedServiceCenterIds?: string[]
): Appointment[] {
  return areValidScIds(selectedServiceCenterIds)
    ? payload.availableAppointments.filter((availableAppointment: Appointment) =>
        selectedServiceCenterIds.includes(availableAppointment.serviceCenter)
      )
    : payload.availableAppointments;
}

export interface AppointmentState {
  searchAppointment: AppointmentPayload;
  code: string | "SUCCESS";
  status: JobStatus;
  availableAppointments: Appointment[];
  availableServiceCenters: AvailableServiceCenters[];
  start: string;
  end: string;
  loaded: boolean;
  loading: boolean;
  error?: string | null;
  confirmed: boolean;
  calibration: boolean;
  currentAppointment: Appointment;
  desiredDate: DesiredDateResponse;
  formattedAddress: string;
  locality: string;
  lat: number;
  lng: number;
  appointmentId: string;
  selectedServiceCenterIds: string[];
  customerCaseId: string;
  availabilityPeriodStart: string;
  availabilityPeriodEnd: string;
  serviceCenter: string;
  street: string;
  city: string;
  postalCode: string;
  state: string;
  country: string;
  fitterId: number;
  overallTaskTime: number;
  jobStart: string;
  jobEnd: string;
  locationType: string | "INHOUSE";
  mobileServiceAvailable: boolean;
  appointmentNextLoading: boolean;
  nextLoadingLimitReached: boolean;
  adverseBuyAppointments: boolean;
  desiredAppointmentDate: Date;
  desiredPeriod: OpportunityFunnelAppointmentPeriod;
  desiredAppointmentId: string;
  earliestPossibleAppointmentDate: string;
  aptModel: AptModel;
  // was added for OptimizelyExperiment.NEW_APPOINTMENT_TILE, remove this comment if test is successfull
  searchClicked: boolean;
  // was added for OptimizelyExperiment.NEW_APPOINTMENT_TILE, remove this comment if test is successfull
  firstSearch: boolean;
}

export interface AppointmentPartialState {
  readonly [APPOINTMENT_FEATURE_KEY]: AppointmentState;
}

export const initialState: AppointmentState = {
  searchAppointment: null,
  code: null,
  status: null,
  availableAppointments: [],
  availableServiceCenters: [],
  start: null,
  end: null,
  loaded: false,
  loading: false,
  error: null,
  confirmed: false,
  calibration: false,
  currentAppointment: null,
  desiredDate: null,
  formattedAddress: null,
  locality: null,
  lat: null,
  lng: null,
  appointmentId: null,
  selectedServiceCenterIds: [],
  customerCaseId: null,
  availabilityPeriodStart: null,
  availabilityPeriodEnd: null,
  serviceCenter: null,
  street: null,
  city: null,
  postalCode: null,
  state: null,
  country: null,
  fitterId: null,
  overallTaskTime: null,
  jobStart: null,
  jobEnd: null,
  locationType: null,
  mobileServiceAvailable: false,
  appointmentNextLoading: false,
  nextLoadingLimitReached: false,
  adverseBuyAppointments: false,
  desiredAppointmentDate: null,
  desiredPeriod: null,
  desiredAppointmentId: null,
  earliestPossibleAppointmentDate: null,
  aptModel: null,
  // was added for OptimizelyExperiment.NEW_APPOINTMENT_TILE, remove this comment if test is successfull
  searchClicked: false,
  // was added for OptimizelyExperiment.NEW_APPOINTMENT_TILE, remove this comment if test is successfull
  firstSearch: true
};

const appointmentReducer = createReducer(
  initialState,
  on(AppointmentActions.getAppointments, (state: AppointmentState, { payload }: { payload: AppointmentPayload }) => ({
    ...state,
    loading: true,
    searchAppointment: payload,
    aptModel: payload.aptModel
  })),
  on(
    AppointmentActions.getAppointmentsSuccess,
    (state: AppointmentState, { payload }: { payload: AppointmentResponse }) => ({
      ...state,
      error: null,
      loaded: true,
      loading: false,
      ...payload,
      availableAppointments: getAvailableAppointmentsBySelectedServiceCenters(payload, state.selectedServiceCenterIds)
    })
  ),
  on(AppointmentActions.getAppointmentsFailure, (state: AppointmentState, { error }: { error: string }) => ({
    ...state,
    loaded: false,
    loading: false,
    error
  })),
  on(
    AppointmentActions.getServiceCentersSuccess,
    (state: AppointmentState, { payload }: { payload: AppointmentResponse }) => ({
      ...state,
      error: null,
      loaded: true,
      loading: false,
      ...payload,
      availableAppointments: null
    })
  ),
  on(AppointmentActions.getServiceCentersFailure, (state: AppointmentState, { error }: { error: string }) => ({
    ...state,
    loaded: false,
    loading: false,
    error
  })),
  on(AppointmentActions.fetchNextAppointments, (state: AppointmentState) => ({
    ...state,
    loading: true,
    appointmentNextLoading: true
  })),
  on(
    AppointmentActions.fetchNextAppointmentsSuccess,
    (state: AppointmentState, { payload }: { payload: AppointmentResponse }) => {
      const { availableAppointments, availableServiceCenters, end } = payload;

      return {
        ...state,
        error: null,
        loaded: true,
        loading: false,
        appointmentNextLoading: false,
        end,
        availableAppointments: [...state.availableAppointments, ...availableAppointments],
        availableServiceCenters: uniqBy([...state.availableServiceCenters, ...availableServiceCenters], "serviceCenter")
      };
    }
  ),
  on(AppointmentActions.fetchNextAppointmentsFailure, (state: AppointmentState, { error }: { error: string }) => ({
    ...state,
    loaded: false,
    loading: false,
    appointmentNextLoading: false,
    error
  })),
  on(
    AppointmentActions.confirmAppointmentSuccess,
    (state: AppointmentState, { payload }: { payload: SetAppointmentPayload }) => ({
      ...state,
      confirmed: true,
      loaded: true,
      loading: false,
      error: null,
      ...payload
    })
  ),
  on(AppointmentActions.confirmAppointmentFailure, (state: AppointmentState, { error }: { error: string }) => ({
    ...state,
    error,
    confirmed: false,
    loaded: false,
    loading: false
  })),
  on(AppointmentActions.setCurrentAppointment, (state: AppointmentState, { payload }: { payload: Appointment }) => ({
    ...state,
    currentAppointment: payload
  })),
  on(
    AppointmentActions.clearAndRefetchAppointments,
    (state: AppointmentState, { payload }: { payload?: { serviceCenterId: string; timerangeBegin?: string } }) => ({
      ...initialState,
      availableAppointments: null,
      availableServiceCenters: payload ? state.availableServiceCenters : null,
      formattedAddress: state.formattedAddress,
      locality: state.locality,
      lat: state.lat,
      lng: state.lng,
      status: state.status,
      selectedServiceCenterIds: payload?.serviceCenterId ? [payload?.serviceCenterId] : []
    })
  ),
  on(AppointmentActions.setFormattedAddress, (state: AppointmentState, { payload }: { payload: string }) => ({
    ...state,
    formattedAddress: payload
  })),
  on(
    AppointmentActions.setLatLng,
    (state: AppointmentState, { payload }: { payload: { lat: number; lng: number } }) => ({
      ...state,
      lat: payload.lat,
      lng: payload.lng
    })
  ),
  on(AppointmentActions.setAppointmentId, (state: AppointmentState, { payload }: { payload: string }) => ({
    ...state,
    appointmentId: payload
  })),
  on(AppointmentActions.setSelectedServiceCenterIds, (state: AppointmentState, { payload }: { payload: string[] }) => ({
    ...state,
    selectedServiceCenterIds: payload
  })),
  on(AppointmentActions.setMobileServiceAvailable, (state: AppointmentState, { payload }: { payload: boolean }) => ({
    ...state,
    mobileServiceAvailable: payload
  })),
  on(
    AppointmentActions.saveAutoCompleteResult,
    (
      state: AppointmentState,
      {
        payload
      }: { payload: { lat: number; lng: number; formattedAddress: string; locality: string; timerangeBegin?: string } }
    ) => ({
      ...state,
      lat: payload.lat,
      lng: payload.lng,
      formattedAddress: payload.formattedAddress,
      locality: payload.locality,
      selectedServiceCenterIds:
        state.formattedAddress === payload.formattedAddress ? state.selectedServiceCenterIds : []
    })
  ),
  on(AppointmentActions.setStatus, (state: AppointmentState, { payload }: { payload: JobStatus }) => ({
    ...state,
    status: payload
  })),
  on(AppointmentActions.setNextLoadingLimitReached, (state: AppointmentState, { payload }: { payload: boolean }) => ({
    ...state,
    nextLoadingLimitReached: payload
  })),
  on(AppointmentActions.resetStateForForm, (state: AppointmentState) => ({
    ...initialState,
    formattedAddress: state.formattedAddress,
    lat: state.lat,
    lng: state.lng,
    status: state.status,
    locality: state.locality,
    firstSearch: state.firstSearch
  })),
  on(AppointmentActions.setDesiredAppointmentDate, (state: AppointmentState, { payload }: { payload: Date }) => ({
    ...state,
    desiredAppointmentDate: payload
  })),
  on(
    AppointmentActions.setDesiredPeriod,
    (state: AppointmentState, { payload }: { payload: OpportunityFunnelAppointmentPeriod }) => ({
      ...state,
      desiredPeriod: payload
    })
  ),
  on(AppointmentActions.resetAppointmentState, (state: AppointmentState) => ({
    ...initialState,
    // do not reset this three attributes
    // only used in opportunity funnel don't need a reset
    desiredPeriod: state.desiredPeriod,
    desiredAppointmentDate: state.desiredAppointmentDate,
    // firstSearch is only set onced in the process of the new appointment tile and should not be reset
    firstSearch: state.firstSearch
  })),
  on(
    AppointmentActions.fetchEarliestPossibleAppointmentDateSuccess,
    (
      state: AppointmentState,
      {
        payload: { desiredAppointmentDate, desiredAppointmentId }
      }: { payload: { desiredAppointmentDate: string; desiredAppointmentId: string } }
    ) => ({
      ...state,
      earliestPossibleAppointmentDate: desiredAppointmentDate,
      desiredAppointmentId
    })
  ),
  on(
    AppointmentActions.fetchEarliestPossibleAppointmentDateFailure,
    (state: AppointmentState, { error }: { error: string }) => ({
      ...state,
      error
    })
  ),
  on(
    AppointmentActions.setAppointmentLocation,
    (state: AppointmentState, { payload }: { payload: AppointmentLocation }) => ({
      ...state,
      currentAppointment: {
        ...state.currentAppointment,
        ...payload
      }
    })
  ),
  on(AppointmentActions.setSearchClicked, (state: AppointmentState, { payload }: { payload: boolean }) => ({
    ...state,
    searchClicked: payload
  })),
  on(AppointmentActions.setFirstSearch, (state: AppointmentState, { payload }: { payload: boolean }) => ({
    ...state,
    firstSearch: payload
  }))
);

export function reducer(state: AppointmentState | undefined, action: Action) {
  return appointmentReducer(state, action);
}
