import { Inject, Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { OLB_CONFIG, OlbConfiguration } from "@cg/olb/configuration";
import * as ContextActions from "./context.actions";
import { ContextPartialState } from "./context.reducer";
import * as ContextSelectors from "./context.selectors";

@Injectable({
  providedIn: "root"
})
export class ContextFacade {
  public context$ = this.store.pipe(select(ContextSelectors.getContext));
  public customerCaseId$ = this.store.pipe(select(ContextSelectors.getCustomerCaseIdFromContext));

  public constructor(
    private store: Store<ContextPartialState>,
    @Inject(OLB_CONFIG) private config: OlbConfiguration
  ) {}

  public createContext() {
    this.store.dispatch(ContextActions.createContext({ payload: { entryChannel: this.config.entryChannel } }));
  }

  public setContextCustomerCaseId(customerCaseId: string) {
    this.store.dispatch(ContextActions.setCustomerCaseId({ payload: { customerCaseId } }));
  }

  public resetState() {
    this.store.dispatch(ContextActions.resetContextState());
  }

  public clearAeNumber() {
    this.store.dispatch(ContextActions.clearAeNumber());
  }
}
