import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { combineLatest, Observable } from "rxjs";
import { filter, map, shareReplay } from "rxjs/operators";
import { PurecloudEwtService } from "@cg/olb/services";
import { ChannelSwitchReason } from "@cg/olb/shared";
import { OpportunityFunnelCallTime } from "@cg/shared";
import * as ChannelSwitchActions from "./channel-switch.actions";
import { ChannelSwitchPartialState } from "./channel-switch.reducer";
import * as ChannelSwitchSelectors from "./channel-switch.selectors";

@Injectable({
  providedIn: "root"
})
export class ChannelSwitchFacade {
  public phoneNumber$ = this.store.pipe(select(ChannelSwitchSelectors.getPhoneNumber));
  public channelSwitchCheckbox$ = this.store.pipe(select(ChannelSwitchSelectors.getChannelSwitchCheckbox));
  public estimatedTimeToCallback$ = this.store.pipe(select(ChannelSwitchSelectors.getEstimatedTimeToCallback));
  public callFailure$ = this.store.pipe(select(ChannelSwitchSelectors.getCallFailure), shareReplay());
  public callSuccess$ = this.store.pipe(select(ChannelSwitchSelectors.getCallSuccess), shareReplay());
  public hasTimerStarted$ = this.store.pipe(select(ChannelSwitchSelectors.getHasTimerStarted), shareReplay());
  public isTimerDone$ = this.store.pipe(select(ChannelSwitchSelectors.getIsTimerDone), shareReplay());
  public isConnectedToAgent$ = this.store.pipe(select(ChannelSwitchSelectors.getIsConnectedToAgent), shareReplay());
  public countDown$ = this.store.pipe(select(ChannelSwitchSelectors.getCountdown));
  public isPureCloudAvailable$ = this.store.pipe(select(ChannelSwitchSelectors.getIsPureCloudAvailable));
  public conversationId$ = this.store.pipe(select(ChannelSwitchSelectors.getConversationId));
  public initialView$ = this.store.pipe(select(ChannelSwitchSelectors.getIsInitialView));
  public showFallback$ = this.store.pipe(select(ChannelSwitchSelectors.getShowFallback));
  public fallbackSubmitted$ = this.store.pipe(select(ChannelSwitchSelectors.getFallbackSubmitted));
  public isCCCOpen$ = this.store.pipe(select(ChannelSwitchSelectors.isCCCOpen));
  public isCallbackLate$ = this.store.pipe(select(ChannelSwitchSelectors.isCallbackLate));
  public opportunityFunnelCallTime$ = this.store.pipe(select(ChannelSwitchSelectors.getOpportunityFunnelCallTime));

  public constructor(
    private store: Store<ChannelSwitchPartialState>,
    private pureCloudEwtService: PurecloudEwtService
  ) {}

  public get needFallback$(): Observable<boolean> {
    return combineLatest([this.estimatedTimeToCallback$, this.isPureCloudAvailable$, this.isCCCOpen$]).pipe(
      filter(
        ([estimatedTimeToCallback, isPureCloudAvailable, isCCCOpen]: [number, boolean, boolean]) =>
          estimatedTimeToCallback !== null && isPureCloudAvailable !== null && isCCCOpen !== null
      ),
      map(
        ([estimatedTimeToCallback, isPureCloudAvailable, isCCCOpen]: [number, boolean, boolean]) =>
          !isCCCOpen ||
          isPureCloudAvailable === false ||
          estimatedTimeToCallback > this.pureCloudEwtService.MAX_EWT ||
          estimatedTimeToCallback < 0
      )
    );
  }

  public setPhoneNumber(phoneNumber: string) {
    this.store.dispatch(ChannelSwitchActions.setPhoneNumber({ payload: phoneNumber }));
  }

  public setChannelSwitchCheckbox(channelSwitchCheckbox: boolean) {
    this.store.dispatch(ChannelSwitchActions.setChannelSwitchCheckbox({ payload: channelSwitchCheckbox }));
  }

  public setEstimatedTimeToCallback(estimatedTimeToCallback: number) {
    this.store.dispatch(ChannelSwitchActions.setEstimatedTimeToCallBack({ payload: estimatedTimeToCallback }));
  }

  public setCallFailure(failure: boolean) {
    this.store.dispatch(ChannelSwitchActions.setCallFailure({ payload: failure }));
  }

  public setCallSuccess(success: boolean) {
    this.store.dispatch(ChannelSwitchActions.setCallSuccess({ payload: success }));
  }

  public setTimerDone() {
    this.store.dispatch(ChannelSwitchActions.setTimerDone());
  }

  public setShowFallback(showFallback: boolean) {
    this.store.dispatch(ChannelSwitchActions.setShowFallback({ payload: showFallback }));
  }

  public setFallbackSubmitted(fallbackSubmitted: boolean) {
    this.store.dispatch(ChannelSwitchActions.setFallbackSubmitted({ payload: fallbackSubmitted }));
  }

  public setIsConnectedToAgent() {
    this.store.dispatch(ChannelSwitchActions.isConnectedToAgent());
  }

  public setIsCallbackLate(isLate: boolean) {
    this.store.dispatch(ChannelSwitchActions.setIsCallbackLate({ payload: isLate }));
  }

  public setOpportunityFunnelCallTime(callTime: OpportunityFunnelCallTime) {
    this.store.dispatch(ChannelSwitchActions.setOpportunityFunnelCallTime({ payload: callTime }));
  }

  public getEstimatedWaitingTime() {
    this.store.dispatch(ChannelSwitchActions.fetchEstimatedTimeToCallback());
  }

  public isCCCOpen() {
    this.store.dispatch(ChannelSwitchActions.isCCCOpen());
  }

  public isCallbackLate() {
    this.store.dispatch(ChannelSwitchActions.isCallbackLate());
  }

  public resetState() {
    this.store.dispatch(ChannelSwitchActions.resetChannelSwitchState());
  }

  public getConversationId(payload: { id: string; reason: ChannelSwitchReason; phoneNumber: string }) {
    this.store.dispatch(
      ChannelSwitchActions.fetchConversationId({
        payload: {
          ccId: payload.id,
          reason: payload.reason,
          phoneNumber: payload.phoneNumber
        }
      })
    );
  }
}
