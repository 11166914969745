import { type Action, createReducer, on } from "@ngrx/store";
import type { ApiError } from "../../interfaces/api-error.interface";
import type { Resume } from "@cg/shared";
import * as ResumeActions from "./resume.actions";

export const RESUME_FEATURE_KEY = "resume";

export interface ResumeState {
  resumeId?: string;
  restoring: boolean;
  resumeResponse: Resume | null;
  resume: Resume | null;
  apiError: ApiError | null;
}

export interface ResumePartialState {
  readonly [RESUME_FEATURE_KEY]: ResumeState;
}

export const initialResumeState: ResumeState = {
  resumeId: undefined,
  restoring: false,
  resumeResponse: undefined,
  resume: null,
  apiError: null
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const ResumeReducer = createReducer(
  initialResumeState,
  on(ResumeActions.setResumeId, (state: ResumeState, { payload }: { payload: string }) => ({
    ...state,
    resumeId: payload,
    restoring: true
  })),
  on(ResumeActions.setRestoring, (state: ResumeState, { payload }: { payload: boolean }) => ({
    ...state,
    restoring: payload
  })),
  on(ResumeActions.setResumeResponse, (state: ResumeState, { payload }: { payload: Resume }) => ({
    ...state,
    resumeResponse: payload
  })),
  on(ResumeActions.loadResumeSuccess, (state: ResumeState, action: { response: Resume } & Action) => ({
    ...state,
    resume: action.response,
    apiError: null
  })),
  on(ResumeActions.loadResumeFailure, (state: ResumeState, action: { error: ApiError } & Action) => ({
    ...state,
    resume: null,
    apiError: action.error
  })),
  on(ResumeActions.resetResumeState, () => ({ ...initialResumeState }))
);

export function reducer(state: ResumeState | undefined, action: Action) {
  return ResumeReducer(state, action);
}
