import { type Action, createReducer, on } from "@ngrx/store";
import { isOpportunityFunnel } from "@cg/core/utils";
import type { ChannelSwitchReason, PrefixComponentIds } from "@cg/olb/shared";
import type { ProcessId, ProcessMetadata } from "@cg/shared";
import * as ProcessActions from "./process.actions";

export const PROCESS_FEATURE_KEY = "process";

export interface ProcessState {
  processMetaData: ProcessMetadata[];
  resumeId?: string;
  restoreInProgress: boolean;
  currentProcessId: ProcessId;
  progressPercentage: number;
  funnelActive: boolean;
  funnelCompleted: boolean;
  channelSwitchReason: ChannelSwitchReason;
  error?: string | null; // last none error (if any)
  exitOlb: boolean;
  technicalError?: string;
  urlToVisitAfterExit?: string | null;
  enterWithStateData: boolean; // state was set before entry (not save adn restore)
  restoredIdsFromState: ProcessId[];
  passthroughNextTileId?: ProcessId;
}

export interface ProcessPartialState {
  readonly [PROCESS_FEATURE_KEY]: ProcessState;
}

export const initialState: ProcessState = {
  restoreInProgress: false,
  processMetaData: [{ id: "damage-window", valid: false, formData: null, prefixComponentId: "easiness-of-process" }],
  currentProcessId: "damage-window",
  progressPercentage: 0,
  funnelActive: false,
  funnelCompleted: false,
  error: null,
  technicalError: null,
  channelSwitchReason: null,
  exitOlb: false,
  urlToVisitAfterExit: null,
  enterWithStateData: false,
  restoredIdsFromState: []
};

const processReducer = createReducer(
  initialState,
  on(ProcessActions.setCurrentProcess, (state: ProcessState, { payload }: { payload: ProcessId }) => ({
    ...state,
    currentProcessId: payload
  })),
  on(ProcessActions.rewindProcess, (state: ProcessState, { payload }: { payload: ProcessId }) => ({
    ...state,
    processMetaData: state.processMetaData.slice(
      0,
      state.processMetaData.findIndex((item: ProcessMetadata) => item.id === payload) + 1
    )
  })),
  on(
    ProcessActions.updateProcessMetaDataFormData,
    (state: ProcessState, { payload }: { payload: Record<string, unknown> }) => ({
      ...state,
      processMetaData: state.processMetaData.map((data: ProcessMetadata) =>
        data.id === state.currentProcessId
          ? {
              ...data,
              formData: {
                ...(data.formData !== null ? data.formData : {}),
                ...payload
              }
            }
          : data
      )
    })
  ),
  on(ProcessActions.updateProcessMetaDataPassthroughId, (state: ProcessState, { payload }: { payload: ProcessId }) => ({
    ...state,
    processMetaData: state.processMetaData.map((data: ProcessMetadata) =>
      data.id === state.currentProcessId
        ? {
            ...data,
            passthroughExitId: payload
          }
        : data
    )
  })),
  on(ProcessActions.setProcessMetaData, (state: ProcessState, { payload }: { payload: ProcessMetadata }) => {
    if (!payload.id) {
      return state;
    }

    const indexInMetaData = state.processMetaData.findIndex((value: ProcessMetadata) => value.id === payload.id);

    if (indexInMetaData === -1) {
      // process metadata with index doesn't exist, create object
      if (
        ["channel-switch", "opportunity-funnel-channel-switch"].includes(
          state.processMetaData[state.processMetaData.length - 1].id
        )
      ) {
        // if last process meta data entry is channel-switch, we need to set other meta datas before it, because channel-switch must be the last
        const processMetaData = [...state.processMetaData];
        processMetaData.splice(state.processMetaData.length - 1, 0, payload);
        return {
          ...state,
          processMetaData
        };
      }

      return {
        ...state,
        processMetaData: [...state.processMetaData, payload]
      };
    }

    return {
      ...state,
      processMetaData: state.processMetaData.map((item: ProcessMetadata, index: number) => {
        if (index === indexInMetaData) {
          // merge item data
          return { ...item, ...payload };
        }

        return item;
      })
    };
  }),
  on(ProcessActions.setProcessPercentage, (state: ProcessState, { payload }: { payload: number }) => ({
    ...state,
    progressPercentage: payload
  })),
  on(ProcessActions.funnelCompleted, (state: ProcessState, { payload }: { payload: ProcessMetadata[] }) => ({
    ...state,
    processMetaData: payload,
    funnelCompleted: true
  })),
  on(ProcessActions.channelSwitchEntered, (state: ProcessState) => ({
    ...state,
    processMetaData: [
      {
        id: isOpportunityFunnel(state.processMetaData) ? "opportunity-funnel-channel-switch" : "channel-switch",
        valid: true,
        progressNumber: 1
      } as ProcessMetadata
    ],
    funnelCompleted: true
  })),
  on(ProcessActions.setTechnicalError, (state: ProcessState, { error }: { error: string }) => ({
    ...state,
    technicalError: error
  })),
  on(ProcessActions.enterOlb, (state: ProcessState) => ({ ...state, funnelActive: true })),
  on(ProcessActions.exitOlb, (state: ProcessState) => ({ ...state, exitOlb: true })),
  on(ProcessActions.exitOlbDone, (state: ProcessState) => ({ ...state, exitOlb: false, funnelActive: false })),
  on(ProcessActions.setUrlToNavigate, (state: ProcessState, { payload }: { payload: string }) => ({
    ...state,
    urlToVisitAfterExit: payload
  })),
  on(ProcessActions.setChannelSwitch, (state: ProcessState, { payload }: { payload: ChannelSwitchReason }) => ({
    ...state,
    channelSwitchReason: payload
  })),
  on(ProcessActions.restoreHasBegun, (state: ProcessState) => ({ ...state, restoreInProgress: true })),
  on(ProcessActions.restoreIsDone, (state: ProcessState) => ({ ...state, restoreInProgress: false })),
  on(ProcessActions.resetProcessState, () => ({ ...initialState })),
  // this action will be dispatched from effect {loadState$}
  on(ProcessActions.saveRestoreStateSuccess, (state: ProcessState, { resumeId }: { resumeId: string }) => ({
    ...state,
    resumeId
  })),
  on(ProcessActions.removeLastTile, (state: ProcessState) => ({
    ...state,
    processMetaData: state.processMetaData.slice(0, -1)
  })),
  on(
    ProcessActions.addPrefixComponent,
    (
      state: ProcessState,
      { processId, prefixComponent }: { processId: ProcessId; prefixComponent: PrefixComponentIds }
    ) => {
      const processMetaData: ProcessMetadata[] = [...state.processMetaData];

      const index = processMetaData.findIndex((item: ProcessMetadata) => item.id === processId);
      if (index > -1) {
        processMetaData[index] = Object.assign({ prefixComponentId: prefixComponent }, processMetaData[index]);
      }

      return {
        ...state,
        processMetaData
      };
    }
  ),
  on(
    ProcessActions.setEnterWithStateData,
    (state: ProcessState, { enterWithStateData }: { enterWithStateData: boolean }) => ({
      ...state,
      enterWithStateData
    })
  ),
  on(ProcessActions.addRestoredIdFromState, (state: ProcessState, { restoredId }: { restoredId: ProcessId }) => ({
    ...state,
    restoredIdsFromState: [...state.restoredIdsFromState, restoredId]
  })),
  on(ProcessActions.setPassthroughNextTileId, (state: ProcessState, { payload }: { payload: ProcessId }) => ({
    ...state,
    passthroughNextTileId: payload
  }))
);

export function reducer(state: ProcessState | undefined, action: Action) {
  return processReducer(state, action);
}
