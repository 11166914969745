import { createReducer, on } from "@ngrx/store";
import { InsuranceNumber, InsuranceType } from "@cg/olb/shared";
import type { ChatInsuranceType, Insurance, InsuranceResponse, InsuranceUseCase } from "@cg/olb/shared";
import type { Action } from "@ngrx/store";
import * as InsuranceActions from "./insurance.actions";

export const INSURANCE_FEATURE_KEY = "insurance";

export interface InsuranceState {
  insurances: Insurance[];
  topInsurances: Insurance[];
  selectedInsurance: Insurance | null;
  chatInsuranceType: ChatInsuranceType | null;
  loaded: boolean;
  error?: string | null;
  type: InsuranceType;
  insuranceResponse: InsuranceResponse;
  gdvVin?: string | null;
  policyNumber: string;
  damageNumber: string;
}

export interface InsurancePartialState {
  readonly [INSURANCE_FEATURE_KEY]: InsuranceState;
}

export const initialState: InsuranceState = {
  insurances: [],
  topInsurances: [],
  selectedInsurance: null,
  chatInsuranceType: null,
  loaded: false,
  error: null,
  type: null,
  insuranceResponse: null,
  gdvVin: null,
  policyNumber: null,
  damageNumber: null
};

const insuranceReducer = createReducer(
  initialState,
  on(InsuranceActions.loadInsurances, (state: InsuranceState) => ({ ...state, loaded: false, error: null })),
  on(
    InsuranceActions.loadInsurancesSuccess,
    (state: InsuranceState, { payload }: { payload: { insurances: Insurance[]; topInsurances: Insurance[] } }) => ({
      ...state,
      loaded: true,
      error: null,
      insurances: payload.insurances,
      topInsurances: payload.topInsurances
    })
  ),
  on(InsuranceActions.loadInsurancesFailure, (state: InsuranceState, { error }: { error: string }) => ({
    ...state,
    error
  })),
  on(InsuranceActions.setInsuranceType, (state: InsuranceState, { payload }: { payload: InsuranceType }) => {
    const newState = { ...state, type: payload };

    switch (payload) {
      case InsuranceType.FULLY:
        return newState;
      case InsuranceType.LIABILITY:
        return { ...newState, selectedInsurance: { number: InsuranceNumber.ANDERE, name: "Andere", code: "9999" } };
      case InsuranceType.UNKNOWN:
        return { ...newState, selectedInsurance: { number: InsuranceNumber.ANDERE, name: "Andere", code: "0000" } };
      case InsuranceType.NONE:
        return { ...newState, selectedInsurance: { number: InsuranceNumber.ANDERE, name: "Andere", code: "0000" } };
      case InsuranceType.PAID_BY_PERPETRATOR:
        return { ...newState, selectedInsurance: { number: InsuranceNumber.ANDERE, name: "Andere", code: "0000" } };
    }
  }),
  on(
    InsuranceActions.setChatInsuranceType,
    (state: InsuranceState, { chatInsuranceType }: { chatInsuranceType: ChatInsuranceType }) => ({
      ...state,
      chatInsuranceType
    })
  ),
  on(InsuranceActions.setInsuranceVin, (state: InsuranceState, { payload }: { payload: string }) => ({
    ...state,
    insuranceResponse: { vin: payload }
  })),
  on(InsuranceActions.setSelectedInsurance, (state: InsuranceState, { payload }: { payload: Insurance }) => ({
    ...state,
    selectedInsurance: payload
  })),
  on(
    InsuranceActions.setInsuranceUseCaseOfSelectedInsurance,
    (state: InsuranceState, { payload }: { payload: InsuranceUseCase }) => ({
      ...state,
      selectedInsurance: { ...state.selectedInsurance, insuranceUseCase: payload }
    })
  ),

  on(InsuranceActions.resetSelectedInsurance, (state: InsuranceState) => ({ ...state, selectedInsurance: null })),
  on(InsuranceActions.getInsurance, (state: InsuranceState) => ({
    ...state,
    insuranceResponse: null
  })),
  on(InsuranceActions.getInsuranceSuccess, (state: InsuranceState, { payload }: { payload: InsuranceResponse }) => ({
    ...state,
    insuranceResponse: payload,
    gdvVin: payload.vin
  })),
  on(InsuranceActions.getInsuranceFailure, (state: InsuranceState) => ({
    ...state,
    insuranceResponse: { coverNote: false }
  })),
  on(InsuranceActions.setInsurances, (state: InsuranceState, { payload }: { payload: Insurance[] }) => ({
    ...state,
    loaded: true,
    error: null,
    insurances: payload
  })),
  on(InsuranceActions.resetInsuranceState, () => ({ ...initialState })),
  on(InsuranceActions.setGdvVin, (state: InsuranceState, { payload }: { payload: string }) => ({
    ...state,
    gdvVin: payload
  })),
  on(InsuranceActions.setPolicyNumber, (state: InsuranceState, { payload }: { payload: string }) => ({
    ...state,
    policyNumber: payload
  })),
  on(InsuranceActions.setDamageNumber, (state: InsuranceState, { payload }: { payload: string }) => ({
    ...state,
    damageNumber: payload
  }))
);

export function reducer(state: InsuranceState | undefined, action: Action) {
  return insuranceReducer(state, action);
}
