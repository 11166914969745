import { Injectable } from "@angular/core";
import { from, Observable } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
import { environment } from "@cg/environments";
import { IConnectionStatus } from "@cg/olb/shared";

@Injectable({
  providedIn: "root"
})
export class PurecloudCallBackService {
  private rxStomp;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public async initRxStomp(): Promise<void> {
    if (this.rxStomp) {
      return;
    }

    const stompConfig = {
      // Broker URL, should start with ws:// or wss:// - adjust for your broker setup
      brokerURL: `${environment.api.wss}/callback/websocket`,
      // Keep it off for production, it can be quit verbose
      // Skip this key to disable
      debug: function (str: string) {
        // eslint-disable-next-line no-console
        console.log("STOMP: " + str);
      }
    };

    // delete debug key from obj when running in production mode
    if (environment.production) {
      delete stompConfig.debug;
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { RxStomp } = await import("@stomp/rx-stomp");

    this.rxStomp = new RxStomp();
    this.rxStomp.configure(stompConfig);
  }

  public watchForCallbackTopic(conversationId: string): Observable<IConnectionStatus> {
    return from(this.initRxStomp()).pipe(
      tap(() => this.rxStomp.activate()),
      switchMap(() => this.rxStomp.watch(`/channel/callback/${conversationId}`)),
      map((message: Record<string, string>) => JSON.parse(message["body"]))
    );
  }

  public disconnect() {
    void this.rxStomp.deactivate();
  }
}
