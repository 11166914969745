import { createAction, props } from "@ngrx/store";
import type { DamageState } from "./damage.reducer";
import type { DamagedRearWindowCount, DamagedSideWindowCount } from "@cg/olb/shared";
import type {
  DamageChipCount,
  DamageLocation,
  DamageResponse,
  DamageSize,
  DamageType,
  DamageWindow,
  Lpn,
  RequiredService
} from "@cg/shared";

export const setId = createAction("[Damage] Set Id", props<{ payload: string }>());

export const setDamageType = createAction("[Damage] Set Crack Amount", props<{ payload: DamageType }>());

export const setDamageWindow = createAction("[Damage] Set Damaged Window", props<{ payload: DamageWindow }>());

export const setLocateDamage = createAction("[Damage] Set Locate Damage", props<{ payload: DamageLocation }>());

export const setRequiredService = createAction("[Damage] Set Required Service", props<{ payload: RequiredService }>());

export const setDamageSize = createAction("[Damage] Set Size", props<{ payload: DamageSize }>());

export const setDamageChipCount = createAction("[Damage] Set Damage Chip Count", props<{ payload: DamageChipCount }>());

export const setLpn = createAction("[Damage] Set License Plate", props<{ payload: Lpn }>());

export const setDamageDate = createAction("[Damage] Set Date", props<{ payload: string | null }>());

export const updateDamage = createAction("[Damage] Update Damage", props<{ payload: DamageState }>());

export const updateDamageSuccess = createAction("[Damage] Update Damage Success", props<{ payload: DamageResponse }>());

export const updateDamageFailure = createAction("[Damage] Update Damage Failure", props<{ error: string }>());

export const resetDamageState = createAction("[Damage] Reset State");

export const setDamagedSideWindowCount = createAction(
  "[Damage] Set Damaged Side Window Count",
  props<{ payload: DamagedSideWindowCount }>()
);

export const setDamagedRearWindowCount = createAction(
  "[Damage] Set Damaged Rear Window Count",
  props<{ payload: DamagedRearWindowCount }>()
);
