import { type Action, createReducer, on } from "@ngrx/store";
import type { OpportunityFunnelCallTime } from "@cg/shared";
import * as ChannelSwitchActions from "./channel-switch.actions";

export const CHANNELSWITCH_FEATURE_KEY = "channelSwitch";

export interface ChannelSwitchState {
  phoneNumber: string;
  channelSwitchCheckbox: boolean;
  estimatedTimeToCallback: number;
  callFailure: boolean;
  callSuccess: boolean;
  timerStarted: boolean;
  timerDone: boolean;
  countDown: number;
  isConnectedToAgent: boolean;
  available: boolean;
  conversationId: string;
  showFallback: boolean;
  fallbackSubmitted: boolean;
  isCCCOpen: boolean;
  isCallbackLate: boolean;
  opportunityFunnelCallTime: OpportunityFunnelCallTime;
}

export interface ChannelSwitchPartialState {
  readonly [CHANNELSWITCH_FEATURE_KEY]: ChannelSwitchState;
}

export const initialState: ChannelSwitchState = {
  // set initial required properties
  phoneNumber: null,
  channelSwitchCheckbox: false,
  callFailure: false,
  callSuccess: false,
  estimatedTimeToCallback: null,
  timerStarted: false,
  timerDone: false,
  countDown: null,
  isConnectedToAgent: false,
  available: null,
  conversationId: null,
  showFallback: false,
  fallbackSubmitted: false,
  isCCCOpen: null,
  isCallbackLate: false,
  opportunityFunnelCallTime: null
};

const channelSwitchReducer = createReducer(
  initialState,
  on(ChannelSwitchActions.setPhoneNumber, (state: ChannelSwitchState, { payload }: { payload: string }) => ({
    ...state,
    phoneNumber: payload.replace(/^(0)(.*)/g, "+49$2")
  })),
  on(ChannelSwitchActions.setChannelSwitchCheckbox, (state: ChannelSwitchState, { payload }: { payload: boolean }) => ({
    ...state,
    channelSwitchCheckbox: payload
  })),
  on(
    ChannelSwitchActions.setEstimatedTimeToCallBack,
    (state: ChannelSwitchState, { payload }: { payload: number }) => ({
      ...state,
      estimatedTimeToCallback: payload,
      countDown: payload
    })
  ),
  on(ChannelSwitchActions.setCallFailure, (state: ChannelSwitchState, { payload }: { payload: boolean }) => ({
    ...state,
    callFailure: payload,
    isConnectedToAgent: false
  })),
  on(ChannelSwitchActions.setCallSuccess, (state: ChannelSwitchState, { payload }: { payload: boolean }) => ({
    ...state,
    callSuccess: payload,
    isConnectedToAgent: false
  })),
  on(ChannelSwitchActions.setTimerStarted, (state: ChannelSwitchState) => ({
    ...state,
    timerStarted: true,
    timerDone: false,
    isConnectedToAgent: false,
    callFailure: false,
    callSuccess: false
  })),
  on(ChannelSwitchActions.setTimerDone, (state: ChannelSwitchState) => ({
    ...state,
    timerDone: true,
    timerStarted: false
  })),
  on(ChannelSwitchActions.setCountdown, (state: ChannelSwitchState, { payload }: { payload: number }) => ({
    ...state,
    countDown: payload
  })),
  on(ChannelSwitchActions.setShowFallback, (state: ChannelSwitchState, { payload }: { payload: boolean }) => ({
    ...state,
    showFallback: payload
  })),
  on(ChannelSwitchActions.setFallbackSubmitted, (state: ChannelSwitchState, { payload }: { payload: boolean }) => ({
    ...state,
    fallbackSubmitted: payload
  })),
  on(
    ChannelSwitchActions.setOpportunityFunnelCallTime,
    (state: ChannelSwitchState, { payload }: { payload: OpportunityFunnelCallTime }) => ({
      ...state,
      opportunityFunnelCallTime: payload
    })
  ),
  on(ChannelSwitchActions.isConnectedToAgent, (state: ChannelSwitchState) => ({ ...state, isConnectedToAgent: true })),
  on(
    ChannelSwitchActions.fetchEstimatedTimeToCallbackSuccess,
    (state: ChannelSwitchState, { payload }: { payload: number }) => ({
      ...state,
      available: true,
      callSuccess: false,
      callFailure: false,
      timerStarted: false,
      isConnectedToAgent: false,
      estimatedTimeToCallback: payload,
      countDown: payload
    })
  ),
  on(ChannelSwitchActions.fetchEstimatedTimeToCallbackFailure, (state: ChannelSwitchState) => ({
    ...state,
    available: false,
    callFailure: false,
    callSuccess: false,
    timerDone: false,
    timerStarted: false,
    isConnectedToAgent: false,
    countDown: -1,
    estimatedTimeToCallback: -1
  })),
  on(
    ChannelSwitchActions.fetchConversationIdSuccess,
    (
      state: ChannelSwitchState,
      {
        payload
      }: {
        payload: {
          id: string;
        };
      }
    ) => ({
      ...state,
      conversationId: payload.id
    })
  ),
  on(ChannelSwitchActions.fetchConversationIdFailure, (state: ChannelSwitchState) => ({
    ...state,
    callFailure: true
  })),
  on(ChannelSwitchActions.resetChannelSwitchState, () => ({ ...initialState })),
  on(
    ChannelSwitchActions.isCCCOpenSuccess,
    (
      state: ChannelSwitchState,
      {
        payload
      }: {
        payload: {
          isOpen: boolean;
        };
      }
    ) => ({
      ...state,
      isCCCOpen: payload.isOpen
    })
  ),
  on(ChannelSwitchActions.setIsCallbackLate, (state: ChannelSwitchState, { payload }: { payload: boolean }) => ({
    ...state,
    isCallbackLate: payload
  })),
  on(ChannelSwitchActions.isCCCOpenFailure, (state: ChannelSwitchState) => ({
    ...state,
    available: false,
    callFailure: false,
    callSuccess: false,
    timerDone: false,
    timerStarted: false,
    isConnectedToAgent: false,
    countDown: -1,
    estimatedTimeToCallback: -1,
    isCCCOpen: false
  }))
);

export function reducer(state: ChannelSwitchState | undefined, action: Action) {
  return channelSwitchReducer(state, action);
}
