import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { Lpn, Resume } from "@cg/shared";
import * as ResumeActions from "./resume.actions";
import { ResumePartialState } from "./resume.reducer";
import * as ResumeSelectors from "./resume.selectors";

@Injectable({
  providedIn: "root"
})
export class ResumeFacade {
  public resumeId$ = this.store.pipe(select(ResumeSelectors.getResumeId));
  public restoring$ = this.store.pipe(select(ResumeSelectors.getRestoring));
  public resumeResponse$ = this.store.pipe(select(ResumeSelectors.getResumeResponse));
  public loadSuccess$ = this.store.pipe(select(ResumeSelectors.getResumeSuccess));
  public loadFailure$ = this.store.pipe(select(ResumeSelectors.getResumeFailure));

  public constructor(private readonly store: Store<ResumePartialState>) {}

  public setResumeId(resumeId: string): void {
    this.store.dispatch(ResumeActions.setResumeId({ payload: resumeId }));
  }

  public setRestoring(restoring: boolean): void {
    this.store.dispatch(ResumeActions.setRestoring({ payload: restoring }));
  }

  public setResumeResponse(resumeResponse: Resume): void {
    this.store.dispatch(ResumeActions.setResumeResponse({ payload: resumeResponse }));
  }

  public loadResume(resumeId: string, lpn?: Lpn) {
    this.store.dispatch(ResumeActions.loadResume({ resumeId, lpn }));
  }

  public resetState() {
    this.store.dispatch(ResumeActions.resetResumeState());
  }
}
