import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import * as YextActions from "./yext.actions";
import { YextPartialState } from "./yext.reducer";
import * as YextSelectors from "./yext.selectors";

@Injectable({
  providedIn: "root"
})
export class YextFacade {
  public infos$ = this.store.pipe(select(YextSelectors.getInfos));

  public constructor(private store: Store<YextPartialState>) {}

  public setInfos(infos: string) {
    this.store.dispatch(
      YextActions.setInfos({
        payload: infos
      })
    );
  }
}
