import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CUSTOMERCASE_FEATURE_KEY, type CustomerCaseState } from "./customer-case.reducer";

// Lookup the 'CustomerCase' feature state managed by NgRx
export const selectCustomerCaseState = createFeatureSelector<CustomerCaseState>(CUSTOMERCASE_FEATURE_KEY);

export const getHasLoadedCustomerCase = createSelector(
  selectCustomerCaseState,
  (state: CustomerCaseState) => state.loaded
);

export const getCustomerCaseError = createSelector(selectCustomerCaseState, (state: CustomerCaseState) => state.error);

export const getCustomerCase = createSelector(
  selectCustomerCaseState,
  getHasLoadedCustomerCase,
  (state: CustomerCaseState, hasLoaded: boolean) => (hasLoaded ? state.customerCase : null)
);

export const getDuplicate = createSelector(
  selectCustomerCaseState,
  getHasLoadedCustomerCase,
  (state: CustomerCaseState, hasLoaded: boolean) => (hasLoaded ? state.duplicate : null)
);

export const getCustomerCaseId = createSelector(
  selectCustomerCaseState,
  getHasLoadedCustomerCase,
  (state: CustomerCaseState, hasLoaded: boolean) => (hasLoaded ? state.customerCase.id : null)
);

export const getProtectProduct = createSelector(
  selectCustomerCaseState,
  getHasLoadedCustomerCase,
  (state: CustomerCaseState, hasLoaded: boolean) => (hasLoaded ? state.protectProduct : null)
);

export const getWiperProduct = createSelector(
  selectCustomerCaseState,
  getHasLoadedCustomerCase,
  (state: CustomerCaseState, hasLoaded: boolean) => (hasLoaded ? state.wiperProduct : null)
);

export const getIsOrganization = createSelector(
  selectCustomerCaseState,
  getHasLoadedCustomerCase,
  (state: CustomerCaseState, hasLoaded: boolean) => (hasLoaded ? state.isOrganization : null)
);
