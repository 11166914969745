import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { SpinnerMode } from "@cg/spinner";
import { ApiService } from "@cg/core/api";
import { EnvironmentConfiguration } from "@cg/core/interfaces";
import { environment } from "@cg/environments";
import {
  GetBuildDatesRequest,
  GetManufacturesResponse,
  GetProductsRequest,
  GetTypesRequest,
  Product,
  ReplacedScreensStatistic,
  SetChosenProductRequest
} from "@cg/olb/shared";
import { ChosenProduct } from "@cg/shared";

@Injectable({
  providedIn: "root"
})
export class ProductService {
  public config: EnvironmentConfiguration = environment;

  public constructor(private apiService: ApiService) {}

  public getAllManufacturers(): Observable<GetManufacturesResponse> {
    return this.apiService.get("product", "/brands");
  }

  public getAllModelsOfManufacturer(payload: { brand: string }): Observable<string[]> {
    const params = new URLSearchParams();

    params.append("brand", payload.brand);

    return this.apiService.get("product", "/models" + "?" + params);
  }

  public getAllTypesOfModel(payload: GetTypesRequest): Observable<string[]> {
    const params = new URLSearchParams();

    params.append("brand", payload.brand);
    params.append("model", payload.model);

    return this.apiService.get("product", "/types" + "?" + params);
  }

  public getAllBuildDatesOf(payload: GetBuildDatesRequest): Observable<string[]> {
    const params = new URLSearchParams();

    params.append("brand", payload.brand);
    params.append("model", payload.model);
    params.append("type", payload.type);

    return this.apiService.get("product", "/builddates" + "?" + params);
  }

  public getAllProductsOf(payload: GetProductsRequest): Observable<Product[]> {
    return this.apiService.post("product", "/identify/filter", payload, { spinnerMode: SpinnerMode.ALWAYS });
  }

  public setChosenProduct(payload: SetChosenProductRequest): Observable<ChosenProduct[]> {
    return this.apiService.put("product", "/" + payload.id, payload.product);
  }

  public getReplacedWindscreensStatistic(payload: string): Observable<ReplacedScreensStatistic> {
    return this.apiService.get("config", "/manufacturer/replacement/" + payload);
  }
}
