import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CHANNELSWITCH_FEATURE_KEY, type ChannelSwitchState } from "./channel-switch.reducer";

export const selectChannelSwitchState = createFeatureSelector<ChannelSwitchState>(CHANNELSWITCH_FEATURE_KEY);

export const getPhoneNumber = createSelector(
  selectChannelSwitchState,
  (state: ChannelSwitchState) => state.phoneNumber
);

export const getChannelSwitchCheckbox = createSelector(
  selectChannelSwitchState,
  (state: ChannelSwitchState) => state.channelSwitchCheckbox
);

export const getEstimatedTimeToCallback = createSelector(
  selectChannelSwitchState,
  (state: ChannelSwitchState) => state.estimatedTimeToCallback
);

export const getCallFailure = createSelector(
  selectChannelSwitchState,
  (state: ChannelSwitchState) => state.callFailure
);

export const getCallSuccess = createSelector(
  selectChannelSwitchState,
  (state: ChannelSwitchState) => state.callSuccess
);

export const getHasTimerStarted = createSelector(
  selectChannelSwitchState,
  (state: ChannelSwitchState) => state.timerStarted
);

export const getIsTimerDone = createSelector(selectChannelSwitchState, (state: ChannelSwitchState) => state.timerDone);

export const getCountdown = createSelector(selectChannelSwitchState, (state: ChannelSwitchState) => state.countDown);

export const getShowFallback = createSelector(
  selectChannelSwitchState,
  (state: ChannelSwitchState) => state.showFallback
);

export const getFallbackSubmitted = createSelector(
  selectChannelSwitchState,
  (state: ChannelSwitchState) => state.fallbackSubmitted
);

export const getIsConnectedToAgent = createSelector(
  selectChannelSwitchState,
  (state: ChannelSwitchState) => state.isConnectedToAgent
);

export const getIsPureCloudAvailable = createSelector(
  selectChannelSwitchState,
  (state: ChannelSwitchState) => state.available
);

export const getConversationId = createSelector(
  selectChannelSwitchState,
  (state: ChannelSwitchState) => state.conversationId
);

export const isCCCOpen = createSelector(selectChannelSwitchState, (state: ChannelSwitchState) => state.isCCCOpen);

export const isCallbackLate = createSelector(
  selectChannelSwitchState,
  (state: ChannelSwitchState) => state.isCallbackLate
);

export const getIsInitialView = createSelector(
  selectChannelSwitchState,
  (state: ChannelSwitchState) =>
    state.callSuccess === false &&
    state.callFailure === false &&
    state.timerStarted === false &&
    state.isConnectedToAgent === false &&
    state.timerDone === false
);

export const getOpportunityFunnelCallTime = createSelector(
  selectChannelSwitchState,
  (state: ChannelSwitchState) => state.opportunityFunnelCallTime
);
