import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { SpinnerMode } from "@cg/spinner";
import { ApiService } from "@cg/core/api";
import { EnvironmentConfiguration } from "@cg/core/interfaces";
import { environment } from "@cg/environments";
import { InsuranceRequest, InsuranceResponse, InsurancesResponse, InsuranceUseCase } from "@cg/olb/shared";

@Injectable({
  providedIn: "root"
})
export class InsuranceService {
  public config: EnvironmentConfiguration = environment;

  public constructor(private apiService: ApiService) {}

  public getInsurance(payload: InsuranceRequest): Observable<InsuranceResponse> {
    payload = { ...payload, requestType: this.config?.api?.insurance?.requestType ?? "OLB" };

    return this.apiService.post("insurance", "", payload, { spinnerMode: SpinnerMode.ALWAYS });
  }

  public getInsuranceNames(): Observable<InsurancesResponse> {
    return this.apiService.get("insurance", "/insurances", { spinnerMode: SpinnerMode.ALWAYS });
  }

  public getUseCase(insuranceNumber: string): Observable<InsuranceUseCase> {
    return this.apiService.get("insurance", `/b2b/usecase/${insuranceNumber}`, { spinnerMode: SpinnerMode.ALWAYS });
  }
}
