import { createAction, props } from "@ngrx/store";
import type {
  GetBuildDatesRequest,
  GetManufacturesResponse,
  GetProductsRequest,
  GetTypesRequest,
  Product,
  ReplacedScreensStatistic,
  SetChosenProductRequest
} from "@cg/olb/shared";
import type { ChosenProduct } from "@cg/shared";

export const fetchAllManufacturers = createAction("[Product] Fetch Manufacturers");
export const fetchAllManufacturersSuccess = createAction(
  "[Product] Fetch Manufacturers Success",
  props<{ payload: GetManufacturesResponse }>()
);
export const fetchAllManufacturersFailure = createAction(
  "[Product] Fetch Manufacturers Failure",
  props<{ error: string }>()
);
export const setSelectedManufacturer = createAction(
  "[Product] Set Selected Manufacturer",
  props<{ payload: string }>()
);

export const fetchAllModels = createAction("[Product] Fetch Models", props<{ payload: { brand: string } }>());
export const fetchAllModelsSuccess = createAction("[Product] Fetch Models Success", props<{ payload: string[] }>());
export const fetchAllModelsFailure = createAction("[Product] Fetch Models Failure", props<{ error: string }>());
export const setSelectedModel = createAction("[Product] Set Selected Model", props<{ payload: string }>());

export const fetchAllTypes = createAction("[Product] Fetch Types", props<{ payload: GetTypesRequest }>());
export const fetchAllTypesSuccess = createAction("[Product] Fetch Types Success", props<{ payload: string[] }>());
export const fetchAllTypesFailure = createAction("[Product] Fetch Types Failure", props<{ error: string }>());
export const setSelectedType = createAction("[Product] Set Selected Type", props<{ payload: string }>());

export const fetchAllBuildDates = createAction(
  "[Product] Fetch Build Dates",
  props<{ payload: GetBuildDatesRequest }>()
);
export const fetchAllBuildDatesSuccess = createAction(
  "[Product] Fetch Build Dates Success",
  props<{ payload: string[] }>()
);
export const fetchAllBuildDatesFailure = createAction("[Product] Get Build Dates Failure", props<{ error: string }>());
export const setSelectedBuildDate = createAction("[Product] Set Selected Build Date", props<{ payload: string }>());
export const fetchAllReplacedScreensStatistic = createAction(
  "[Product] Fetch replaced windscreens",
  props<{ payload: string }>()
);
export const fetchAllReplacedScreensStatisticSuccess = createAction(
  "[Product] Fetch replaced windscreens Success",
  props<{ payload: ReplacedScreensStatistic }>()
);
export const fetchAllReplacedScreensStatisticFailure = createAction(
  "[Product] Fetch replaced windscreens",
  props<{ error: string }>()
);
export const fetchAllProducts = createAction("[Product] Fetch Products", props<{ payload: GetProductsRequest }>());
export const fetchAllProductsSuccess = createAction(
  "[Product] Fetch Products Success",
  props<{ payload: Product[] }>()
);
export const fetchAllProductsFailure = createAction("[Product] Fetch Products Failure", props<{ error: string }>());
export const resetAllProducts = createAction("[Product] Reset Products");

export const setChosenProduct = createAction(
  "[Product] Set Chosen Product",
  props<{ payload: SetChosenProductRequest }>()
);
export const setChosenProductSuccess = createAction(
  "[Product] Set Chosen Product Success",
  props<{ payload: ChosenProduct[] }>()
);
export const setChosenProductFailure = createAction("[Product] Set Chosen Product Failure", props<{ error: string }>());

export const resetAllManufacturers = createAction("[Product] Reset Manufacturers");
export const resetAllModels = createAction("[Product] Reset Models");
export const resetAllTypes = createAction("[Product] Reset Types");
export const resetAllBuildDates = createAction("[Product] Reset Build Dates");

export const resetProductState = createAction("[Product] Reset State");
