import { Injectable } from "@angular/core";
import { AptModel } from "@cg/shared";

@Injectable({
  providedIn: "root"
})
export class CarIdentificationService {
  public getCarIdentifiedProcessId(data: { brand?: string } | string): "tesla-restrictions" | "car-identified" {
    const brand = typeof data === "string" ? data : data.brand;
    const isCarManufacturedByTesla = brand === AptModel.TESLA;

    return isCarManufacturedByTesla ? "tesla-restrictions" : "car-identified";
  }
}
