import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable, of } from "rxjs";
import { SpinnerMode } from "@cg/spinner";
import { ApiService } from "@cg/core/api";
import { ChannelSwitchConversationReason } from "@cg/olb/shared";

@Injectable({
  providedIn: "root"
})
export class PurecloudEwtService {
  public readonly MAX_EWT = 600; // 10 min

  public constructor(
    private readonly apiService: ApiService,
    private readonly route: ActivatedRoute
  ) {}

  public getEstimatedWaitingTime() {
    return this.apiService.get<number>("callback", "/callbacks/ewt");
  }

  public getConversationId(payload: {
    ccId: string;
    switchChannelType: ChannelSwitchConversationReason;
    phoneNumber: string;
  }) {
    return this.apiService.post("callback", "/callbacks/", payload, { spinnerMode: SpinnerMode.ALWAYS });
  }

  public isCCCOpen(): Observable<{ isOpen: boolean }> {
    const cccOpenParam = this.route.snapshot.queryParams["ccc_open"];

    if (cccOpenParam) {
      return of({ isOpen: cccOpenParam === "true" });
    }

    return this.apiService.get("callback", "/callcenter/open");
  }
}
