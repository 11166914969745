import { createAction, props } from "@ngrx/store";
import type { ChannelSwitchReason } from "@cg/olb/shared";
import type { OpportunityFunnelCallTime } from "@cg/shared";

export const setPhoneNumber = createAction("[ChannelSwitch] Set Phone Number", props<{ payload: string }>());
export const setChannelSwitchCheckbox = createAction(
  "[ChannelSwitch] Set Channel Switch Checkbox",
  props<{ payload: boolean }>()
);
export const setEstimatedTimeToCallBack = createAction(
  "[ChannelSwitch] Set Estimated Time To CallBack",
  props<{ payload: number }>()
);
export const setCallFailure = createAction("[ChannelSwitch] Set Call Failure", props<{ payload: boolean }>());
export const setCallSuccess = createAction("[ChannelSwitch] Set Call Success", props<{ payload: boolean }>());

export const setTimerStarted = createAction("[ChannelSwitch] Set Timer Started");
export const setTimerDone = createAction("[ChannelSwitch] Set Timer Done");
export const setCountdown = createAction("[ChannelSwitch] Set Countdown", props<{ payload: number }>());
export const setShowFallback = createAction("[ChannelSwitch] Show Fallback", props<{ payload: boolean }>());
export const setFallbackSubmitted = createAction("[ChannelSwitch] Fallback submitted", props<{ payload: boolean }>());
export const isConnectedToAgent = createAction("[ChannelSwitch] Is Connected To Agent");
export const setIsCallbackLate = createAction("[ChannelSwitch] Set Is Callback Late", props<{ payload: boolean }>());
export const setOpportunityFunnelCallTime = createAction(
  "[ChannelSwitch] Set Opportunity Funnel Call Time",
  props<{ payload: OpportunityFunnelCallTime }>()
);

export const fetchEstimatedTimeToCallback = createAction("[ChannelSwitch] Fetch Estimated Time To CallBack");
export const fetchEstimatedTimeToCallbackFailure = createAction(
  "[ChannelSwitch] Fetch Estimated Time To CallBack Failure"
);
export const fetchEstimatedTimeToCallbackSuccess = createAction(
  "[ChannelSwitch] Fetch Estimated Time To CallBack Success",
  props<{ payload: number }>()
);

export const fetchConversationId = createAction(
  "[ChannelSwitch] Fetch Conversation Id",
  props<{ payload: { ccId: string; reason: ChannelSwitchReason; phoneNumber: string } }>()
);

export const fetchConversationIdSuccess = createAction(
  "[ChannelSwitch] Fetch Conversation Id Success",
  props<{ payload: { id: string } }>()
);

export const fetchConversationIdFailure = createAction("[ChannelSwitch] Fetch Conversation Id Failure");

export const resetChannelSwitchState = createAction("[ChannelSwitch] Reset State");

export const isCCCOpen = createAction("[ChannelSwitch] Is CCC Open");
export const isCallbackLate = createAction("[ChannelSwitch] Is Callback Late");
export const isCCCOpenSuccess = createAction(
  "[ChannelSwitch] Is CCC Open Success",
  props<{ payload: { isOpen: boolean } }>()
);

export const isCCCOpenFailure = createAction("[ChannelSwitch] Is CCC Open Failure");

export const noOperation = createAction("[ChannelSwitch] No Operation");
