import { createAction, props } from "@ngrx/store";
import type { ApiError } from "../../interfaces/api-error.interface";
import type { Lpn, Resume } from "@cg/shared";

export const loadResume = createAction("[Resume] Load", props<{ resumeId: string; lpn?: Lpn }>());
export const loadResumeSuccess = createAction("[Resume] Load Resume Success", props<{ response: Resume }>());
export const loadResumeFailure = createAction("[Resume] Load Resume Failure", props<{ error: ApiError }>());
export const setResumeId = createAction("[Resume] Set Resume Id", props<{ payload: string }>());
export const setRestoring = createAction("[Resume] Set Restoring", props<{ payload: boolean }>());
export const setResumeResponse = createAction("[Resume] Set Resume Response", props<{ payload: Resume }>());
export const resetResumeState = createAction("[Resume] Reset Resume State");
