import { Injectable } from "@angular/core";
import { ofType } from "@ngrx/effects";
import { ActionsSubject, select, Store } from "@ngrx/store";
import { BehaviorSubject, Observable } from "rxjs";
import { take } from "rxjs/operators";
import { ChannelSwitchReason, PrefixComponentIds } from "@cg/olb/shared";
import { ProcessId, ProcessMetadata } from "@cg/shared";
import * as ProcessActions from "./process.actions";
import * as ProcessSelectors from "./process.selectors";
import { getProgressPercentage } from "./process.selectors";

@Injectable({
  providedIn: "root"
})
export class ProcessFacade {
  public initOlbAfterError$ = new BehaviorSubject(false);

  public currentProcessId$ = this.store.pipe(select(ProcessSelectors.getCurrentProcessId));
  public processMetaData$ = this.store.pipe(select(ProcessSelectors.getProcessMetaData));
  public channelSwitchReason$ = this.store.pipe(select(ProcessSelectors.getChannelSwitchReason));
  public technicalError$: Observable<string | null> = this.store.pipe(select(ProcessSelectors.getTechnicalError));
  public exitOlb$ = this.store.pipe(select(ProcessSelectors.getExitOlb));
  public funnelActive$ = this.store.pipe(select(ProcessSelectors.getFunnelActive));
  public getUrlToNavigateAfterExit$ = this.store.pipe(select(ProcessSelectors.getUrlToNavigateAfterExit));
  public restoreInProgress$ = this.store.select(ProcessSelectors.restoreInProgress);
  public enterWithStateData$ = this.store.pipe(select(ProcessSelectors.enterWithStateData));
  public restoredIdsFromState$ = this.store.pipe(select(ProcessSelectors.getRestoredIdsFromState));
  public editOverlayClosed$ = this.actionsSubject.pipe(ofType(ProcessActions.closeEditOverlay));
  public hasFunnelCompleted$ = this.store.select(ProcessSelectors.hasFunnelCompleted);
  public passthroughNextTileId$ = this.store.pipe(select(ProcessSelectors.getPassthroughNextTileId));

  public constructor(
    private store: Store,
    private readonly actionsSubject: ActionsSubject
  ) {}

  public goForward(processId: ProcessId): void {
    this.hasFunnelCompleted$.pipe(take(1)).subscribe((funnelCompleted: boolean) => {
      if (funnelCompleted) {
        console.error("goForward was triggered after funnel has been completed, which is forbidden! ");
        return;
      }

      this.store.dispatch(ProcessActions.setProcessMetaData({ payload: { id: processId, valid: false } }));
      this.setCurrentProcessId(processId);
    });
  }

  public addRestoredId(currentProcessId: ProcessId): void {
    this.store.dispatch(ProcessActions.addRestoredIdFromState({ restoredId: currentProcessId }));
  }

  public goBackwardWithGivenNodeId(currentProcessId: ProcessId): void {
    this.processMetaData$.pipe(take(1)).subscribe((processMetaData: ProcessMetadata[]) => {
      const idOfProcessMetaData = processMetaData.findIndex(
        (metaData: ProcessMetadata) => metaData.id === currentProcessId
      );

      if (idOfProcessMetaData > 0) {
        const previousId = processMetaData[idOfProcessMetaData - 1].id;

        this.setCurrentProcessId(previousId);

        this.store.dispatch(ProcessActions.rewindProcess({ payload: previousId }));
        this.store.dispatch(ProcessActions.setProcessMetaData({ payload: { id: previousId, valid: false } }));
      } else {
        console.error(`Previous ProcessId for ${currentProcessId} not found!`);
      }
    });
  }

  public rewindToExistingProcessId(processId: ProcessId): void {
    this.processMetaData$.pipe(take(1)).subscribe((processMetaData: ProcessMetadata[]) => {
      const idOfProcessMetaData = processMetaData.findIndex((metaData: ProcessMetadata) => metaData.id === processId);

      if (idOfProcessMetaData >= 0) {
        const previousId = processMetaData[idOfProcessMetaData].id;

        this.store.dispatch(ProcessActions.rewindProcess({ payload: previousId }));
        this.store.dispatch(ProcessActions.setProcessMetaData({ payload: { id: previousId, valid: false } }));
        this.store.dispatch(ProcessActions.setCurrentProcess({ payload: processId }));
      } else {
        console.error(`Previous ProcessId for ${processId} not found!`);
      }
    });
  }

  public changeCar() {
    const processId = "vehicle-identification-number";
    this.store.dispatch(ProcessActions.removeLastTile());
    this.store.dispatch(ProcessActions.setProcessMetaData({ payload: { id: processId, valid: false } }));
    this.store.dispatch(ProcessActions.setCurrentProcess({ payload: processId }));
  }

  public getProgressPercentage(): Observable<number> {
    return this.store.select(getProgressPercentage);
  }

  public setProgressPercentage(percentage: number) {
    return this.store.dispatch(ProcessActions.setProcessPercentage({ payload: percentage }));
  }

  public setCurrentProcessId(processId: ProcessId): void {
    this.store.dispatch(ProcessActions.setCurrentProcess({ payload: processId }));
  }

  public getProcessMetaDataForProcessId(processId: ProcessId): Observable<ProcessMetadata> {
    return this.store.pipe(select(ProcessSelectors.getProcessMetaDataForProcessId(processId)));
  }

  public setProcessMetaData(metaData: ProcessMetadata): void {
    this.store.dispatch(ProcessActions.setProcessMetaData({ payload: metaData }));
  }

  public updateCurrentProcessMetaDataFormData(form: Record<string, unknown>) {
    this.store.dispatch(ProcessActions.updateProcessMetaDataFormData({ payload: form }));
  }

  public setUrlToNavigateAfterExit(url: string | null) {
    this.store.dispatch(ProcessActions.setUrlToNavigate({ payload: url }));
  }

  public enterOlb(): void {
    this.store.dispatch(ProcessActions.enterOlb());
  }

  public exitOlb(): void {
    this.store.dispatch(ProcessActions.exitOlb());
  }

  public exitOlbDone(): void {
    this.store.dispatch(ProcessActions.exitOlbDone());
  }

  public setChannelSwitchReason(reason: ChannelSwitchReason): void {
    this.store.dispatch(ProcessActions.setChannelSwitch({ payload: reason }));
  }

  public goBack(processId: ProcessId) {
    this.store.dispatch(ProcessActions.goBack({ payload: processId }));
  }

  public goForwardSuccess(processId: ProcessId, eventAction: string) {
    this.hasFunnelCompleted$.pipe(take(1)).subscribe((funnelCompleted: boolean) => {
      if (funnelCompleted) {
        console.error("goForwardSuccess was triggered after funnel has been completed, which is forbidden! ");
        return;
      }
      this.store.dispatch(ProcessActions.goForwardSuccess({ payload: { processId, eventAction } }));
    });
  }

  public goForwardFailure(processId: ProcessId, invalidFormFields: string[]) {
    this.store.dispatch(ProcessActions.goForwardFailure({ payload: { processId, invalidFormFields } }));
  }

  public resetState() {
    this.store.dispatch(ProcessActions.resetProcessState());
  }

  public scrollToTile(id: ProcessId) {
    this.store.dispatch(ProcessActions.scrollToTile({ payload: id }));
  }

  public enterChannelSwitch(): void {
    this.store.dispatch(ProcessActions.channelSwitchEntered());
  }

  public saveRestoreState(optIn: boolean) {
    this.store.dispatch(ProcessActions.saveRestoreState({ optIn }));
  }

  public closeEditOverlay(processId: ProcessId) {
    this.store.dispatch(ProcessActions.closeEditOverlay({ processId }));
  }

  public restoreHasBegun() {
    this.store.dispatch(ProcessActions.restoreHasBegun());
  }

  public restoreIsDone() {
    this.store.dispatch(ProcessActions.restoreIsDone());
  }

  public addPrefixComponent(processId: ProcessId, prefixComponent: PrefixComponentIds) {
    this.store.dispatch(ProcessActions.addPrefixComponent({ processId, prefixComponent }));
  }

  public setEnterWithStateData(enterWithStateData: boolean) {
    this.store.dispatch(ProcessActions.setEnterWithStateData({ enterWithStateData }));
  }

  public setPassthroughNextTileId(exitId: ProcessId) {
    this.store.dispatch(ProcessActions.setPassthroughNextTileId({ payload: exitId }));
    this.store.dispatch(ProcessActions.updateProcessMetaDataPassthroughId({ payload: exitId }));
  }
}
