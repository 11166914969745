import { createAction, props } from "@ngrx/store";
import type {
  ChatInsuranceType,
  Insurance,
  InsuranceRequest,
  InsuranceResponse,
  InsuranceType,
  InsuranceUseCase
} from "@cg/olb/shared";

export const loadInsurances = createAction("[Insurance] Load Insurances");
export const loadInsurancesSuccess = createAction(
  "[Insurance] Load Insurance Success",
  props<{ payload: { insurances: Insurance[]; topInsurances: Insurance[] } }>()
);
export const loadInsurancesFailure = createAction("[Insurance] Load Insurances Failure", props<{ error: string }>());

export const setInsuranceType = createAction("[Insurance] Set Type", props<{ payload: InsuranceType }>());

export const setChatInsuranceType = createAction(
  "[Insurance] Set Chat Type",
  props<{ chatInsuranceType: ChatInsuranceType }>()
);

export const setInsuranceVin = createAction("[Insurance] Set Insurance Vin", props<{ payload: string }>());

export const setInsuranceUseCaseOfSelectedInsurance = createAction(
  "[Insurance] Set Insurance Use Case Of Selected Insurance",
  props<{ payload: InsuranceUseCase }>()
);

export const setSelectedInsurance = createAction("[Insurance] Set Selected", props<{ payload: Insurance }>());

export const getInsurance = createAction("[Insurance] Get Insurance", props<{ payload: InsuranceRequest }>());
export const getInsuranceSuccess = createAction(
  "[Insurance] Get Insurance Success",
  props<{ payload: InsuranceResponse }>()
);
export const getInsuranceFailure = createAction("[Insurance] Get Insurance Failure");

export const resetInsuranceState = createAction("[Insurance] Reset State");

export const setInsurances = createAction("[Insurance] Set Insurances", props<{ payload: Insurance[] }>());

export const setGdvVin = createAction("[Insurance] Set gdv result vin", props<{ payload: string }>());

export const setPolicyNumber = createAction("[Insurance] Set Insurance Number", props<{ payload: string }>());

export const setDamageNumber = createAction("[Insurance] Set Damage Number", props<{ payload: string }>());

export const resetSelectedInsurance = createAction("[Insurance] Reset selecteed insurances");
