import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CONTEXT_FEATURE_KEY, type ContextState } from "./context.reducer";

// Lookup the 'Context' feature state managed by NgRx
export const getContextState = createFeatureSelector<ContextState>(CONTEXT_FEATURE_KEY);

export const getContext = createSelector(getContextState, (state: ContextState) => state.context);

export const hasLoadedContext = createSelector(getContextState, (state: ContextState) => state.loaded);

export const getContextError = createSelector(getContextState, (state: ContextState) => state.error);

export const getCustomerCaseIdFromContext = createSelector(
  getContextState,
  hasLoadedContext,
  (state: ContextState, hasLoaded: boolean) => (hasLoaded ? state.context.customerCaseId : null)
);
