import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { GetGdv } from "@cg/shared";
import * as GdvActions from "./gdv.actions";
import { GdvPartialState } from "./gdv.reducer";
import * as GdvSelectors from "./gdv.selectors";

@Injectable({
  providedIn: "root"
})
export class GdvFacade {
  public fetch$ = this.store.pipe(select(GdvSelectors.getFetch));
  public useNoDate$ = this.store.pipe(select(GdvSelectors.getUseNoDate));

  public constructor(private store: Store<GdvPartialState>) {}

  public setFetch(fetch: GetGdv) {
    this.store.dispatch(GdvActions.setFetch({ payload: fetch }));
  }

  public setUseNoDate(fake: boolean) {
    this.store.dispatch(GdvActions.setUseNoDate({ payload: fake }));
  }

  public resetState() {
    this.store.dispatch(GdvActions.resetGdvState());
  }
}
