import { createAction, props } from "@ngrx/store";
import type { CustomerCaseState } from "./customer-case.reducer";
import type { VAPsFailureEventData, VAPsSuccessEventData, VAPsTriggerEventData } from "@cg/olb/shared";
import type { CheckDuplicateReponse, ChosenProduct, CustomerCase, Lpn, SwitchChannelPayload } from "@cg/shared";

export const setLoaded = createAction("[Customer Case] Set Loaded", props<{ payload: boolean }>());

export const setCustomerCaseId = createAction("[Customer Case] Set Customer Case Id", props<{ payload: string }>());

export const setContextId = createAction("[Customer Case] Set Context Id", props<{ payload: string }>());

// GET /id
export const fetchCustomerCase = createAction("[Customer Case] Fetch Customer Case", props<{ payload: string }>());
export const fetchCustomerCaseSuccess = createAction(
  "[Customer Case] Fetch Customer Case Success",
  props<{ payload: CustomerCase }>()
);
export const fetchCustomerCaseFailure = createAction(
  "[Customer Case] Fetch Customer Case Failure",
  props<{ error: string }>()
);

// PUT /id
export const updateCustomerCase = createAction(
  "[Customer Case] Update Customer Case",
  props<{ payload: CustomerCase }>()
);
export const updateCustomerCaseSuccess = createAction(
  "[Customer Case] Update Customer Case Success",
  props<{ payload: CustomerCase }>()
);
export const updateCustomerCaseFailure = createAction(
  "[Customer Case] Update Customer Case Failure",
  props<{ error: string }>()
);

// PUT /contract/id
export const confirmCustomerCase = createAction("[Customer Case] Confirm Customer Case", props<{ payload: string }>());
export const confirmCustomerCaseSuccess = createAction(
  "[Customer Case] Confirm Customer Case Success",
  props<{ payload: CustomerCase }>()
);
export const confirmCustomerCaseFailure = createAction(
  "[Customer Case] Confirm Customer Case Failure",
  props<{ error: string }>()
);
// POST /checkDuplicate
export const checkDuplicate = createAction(
  "[Customer Case] Check Duplicate",
  props<{ payload: { newCustomerCaseId: string; lpn: Lpn } }>()
);
export const checkDuplicateSuccess = createAction(
  "[Customer Case] Check Duplicate Success",
  props<{ payload: CheckDuplicateReponse }>()
);
export const checkDuplicateFailure = createAction(
  "[Customer Case] Check Duplicate Failure",
  props<{ error: string }>()
);

// PUT /switch-channel/id
export const switchChannel = createAction(
  "[Customer Case] Switch Channel",
  props<{
    payload: SwitchChannelPayload;
  }>()
);

export const resetCustomerCaseState = createAction("[Customer Case] Reset State");

export const switchChannelSuccess = createAction(
  "[Customer Case] Switch Channel Success",
  props<{ payload: CustomerCase }>()
);
export const switchChannelFailure = createAction("[Customer Case] Switch Channel Failure", props<{ error: string }>());

export const patchStateForResume = createAction(
  "[Customer Case] Patch State For Resume",
  props<{ payload: Partial<CustomerCaseState> }>()
);

export const setOrderCommitment = createAction("[Customer Case] Set Order Commitment", props<{ payload: boolean }>());
export const setOrderCommitmentSuccsess = createAction("[Customer Case] Set Order Commitment Succsess");
export const setOrderCommitmentFailure = createAction(
  "[Customer Case] Set Order Commitment Failure",
  props<{ error: string }>()
);
export const offerVAP = createAction("[Customer Case] Offer VAP", props<{ payload: VAPsTriggerEventData }>());
export const addVAP = createAction("[Customer Case] Add VAP", props<{ payload: VAPsTriggerEventData }>());
export const requestAddVAP = createAction(
  "[Customer Case] Request Add VAP",
  props<{ payload: VAPsTriggerEventData }>()
);
export const addVAPSuccess = createAction(
  "[Customer Case] Add VAP Success",
  props<{ payload: VAPsSuccessEventData }>()
);
export const addVAPFailure = createAction(
  "[Customer Case] Add VAP Failure",
  props<{ payload: VAPsFailureEventData }>()
);
export const removeVAP = createAction("[Customer Case] Remove VAP", props<{ payload: VAPsTriggerEventData }>());
export const removeVAPSuccess = createAction(
  "[Customer Case] Remove VAP Success",
  props<{ payload: VAPsSuccessEventData }>()
);
export const removeVAPFailure = createAction(
  "[Customer Case] Remove VAP Failure",
  props<{ payload: VAPsFailureEventData }>()
);
export const bookVAPSuccess = createAction(
  "[Customer Case] Book VAP Success",
  props<{ payload: VAPsTriggerEventData }>()
);

export const showVAPWipersGdvDialog = createAction("[Customer Case] Show VAP Wipers GDV Dialog");

export const setShoppingCartEntries = createAction(
  "[Customer Case] Set Shopping Cart Entries",
  props<{ payload: ChosenProduct[] }>()
);
