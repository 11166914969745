import { createAction, props } from "@ngrx/store";
import type { ContactDataState } from "./contact-data.reducer";
import type { UpdateCustomerRequest } from "@cg/shared";

export const setMobile = createAction("[ContactData] Set Phone", props<{ payload: string }>());
export const setEmail = createAction("[ContactData] Set Email", props<{ payload: string }>());

export const setDriverTitle = createAction("[ContactData] Set Driver Title", props<{ payload: string }>());
export const setDriverFirstname = createAction("[ContactData] Set Driver Firstname", props<{ payload: string }>());
export const setDriverLastname = createAction("[ContactData] Set Driver Lastname", props<{ payload: string }>());
export const setDriverStreet = createAction("[ContactData] Set Driver Street", props<{ payload: string }>());
export const setDriverZip = createAction("[ContactData] Set Driver Zip", props<{ payload: string }>());
export const setDriverCity = createAction("[ContactData] Set Driver City", props<{ payload: string }>());
export const setDriverCountry = createAction("[ContactData] Set Driver Country", props<{ payload: string }>());
export const setDriverPhone = createAction("[ContactData] Set Driver Phone", props<{ payload: string }>());
export const setDriverAlternativePhone = createAction(
  "[ContactData] Set Driver Alternative Phone",
  props<{ payload: string }>()
);
export const setDriverEmail = createAction("[ContactData] Set Driver Email", props<{ payload: string }>());

export const setInsuranceHolderIsOrganization = createAction(
  "[ContactData] Set Insurance Holder Is Organization",
  props<{ payload: boolean }>()
);
export const setInsuranceHolderCompany = createAction(
  "[ContactData] Set Insurance Holder Company",
  props<{ payload: string }>()
);
export const setInsuranceHolderTitle = createAction(
  "[ContactData] Set Insurance Holder Title",
  props<{ payload: string }>()
);
export const setInsuranceHolderFirstname = createAction(
  "[ContactData] Set Insurance Holder Firstname",
  props<{ payload: string }>()
);
export const setInsuranceHolderLastname = createAction(
  "[ContactData] Set Insurance Holder Lastname",
  props<{ payload: string }>()
);
export const setInsuranceHolderStreet = createAction(
  "[ContactData] Set Insurance Holder Street",
  props<{ payload: string }>()
);
export const setInsuranceHolderZip = createAction(
  "[ContactData] Set Insurance Holder Zip",
  props<{ payload: string }>()
);
export const setInsuranceHolderCity = createAction(
  "[ContactData] Set Insurance Holder City",
  props<{ payload: string }>()
);
export const setInsuranceHolderCountry = createAction(
  "[ContactData] Set Insurance Holder Country",
  props<{ payload: string }>()
);
export const setInsuranceHolderPhone = createAction(
  "[ContactData] Set Insurance Holder Phone",
  props<{ payload: string }>()
);
export const setInsuranceHolderAlternativePhone = createAction(
  "[ContactData] Set Insurance Holder Alternative Phone",
  props<{ payload: string }>()
);
export const setInsuranceHolderEmail = createAction(
  "[ContactData] Set Insurance Holder Email",
  props<{ payload: string }>()
);
export const setIsPolicyHolder = createAction("[ContactData] Set Is Policy Holder", props<{ payload: boolean }>());

export const updateCustomerContact = createAction(
  "[ContactData] Update Customer Contact",
  props<{ payload: UpdateCustomerRequest }>()
);
export const createOrUpdateCustomerContactSuccess = createAction("[ContactData] Update Customer Contact Success");
export const updateCustomerContactFailure = createAction(
  "[Customer Case] Update Customer Contact Failure",
  props<{ error: string }>()
);

export const updateCustomerContactOpportunityFunnel = createAction(
  "[ContactData] Update Customer Contact For Opportunity Funnel"
);
export const updateCustomerContactOpportunityFunnelSuccess = createAction(
  "[ContactData] Update Customer Contact For Opportunity Funnel Success"
);
export const updateCustomerContactOpportunityFunnelFailure = createAction(
  "[ContactData] Update Customer Contact For Opportunity Funnel Failure",
  props<{ error: string }>()
);

export const resetContactDataState = createAction("[ContactData] Reset State");

export const patchStateForResume = createAction(
  "[ContactData] Patch State For Resume",
  props<{ payload: Partial<ContactDataState> }>()
);
