import { createFeatureSelector, createSelector } from "@ngrx/store";
import { RESUME_FEATURE_KEY, type ResumePartialState, type ResumeState } from "./resume.reducer";

export const getResumeState = createFeatureSelector<ResumePartialState, ResumeState>(RESUME_FEATURE_KEY);

export const getResumeId = createSelector(getResumeState, (state: ResumeState) => state.resumeId);

export const getRestoring = createSelector(getResumeState, (state: ResumeState) => state.restoring);

export const getResumeResponse = createSelector(getResumeState, (state: ResumeState) => state.resumeResponse);

export const getResumeFailure = createSelector(getResumeState, (state: ResumeState) =>
  state ? state?.apiError : null
);

export const getResumeSuccess = createSelector(getResumeState, (state: ResumeState) => (state ? state.resume : null));

export const resumeTrackingProperties = createSelector(
  getResumeState,
  (state: ResumeState) => state?.resume?.state?.trackingProperties
);

export const resumeIsPossible = createSelector(getResumeState, (state: ResumeState) => !!state?.resume);
