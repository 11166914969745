import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  inject,
  Inject,
  Input,
  OnInit
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import {
  ControlContainer,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  ReactiveFormsModule,
  UntypedFormControl,
  Validators
} from "@angular/forms";
import { distinctUntilChanged, filter, map, Subscription, take, tap, withLatestFrom } from "rxjs";
import { Feature, FeatureToggleFacade } from "@cg/feature-toggle";
import { OLB_CONFIG, OlbConfiguration } from "@cg/olb/configuration";
import * as CgValidators from "@cg/validators";
import { AddFormControls } from "@cg/core/types";
import { isDirectResumeFn, SpecialLpn, specialLpnModel } from "@cg/olb/shared";
import { ComponentOverarchingChangeDetectionService, LicensePlateType } from "@cg/shared";
import { LpnInputForm, LpnInputFormcontrols, LpnInputPlaceholders } from "../interfaces/lpn-input-form.interface";

@Component({
  selector: "cg-lpn-input",
  templateUrl: "./lpn-input.component.html",
  styleUrls: ["./lpn-input.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
  standalone: true,
  imports: [ReactiveFormsModule]
})
export class LpnInputComponent implements OnInit, ControlValueAccessor {
  public destroyRef = inject(DestroyRef);

  @Input()
  public isEditable = true;

  public form: FormGroup;
  public inputPlaceholders: LpnInputPlaceholders = {
    region: "K",
    letters: "CG",
    numbers: "175 E",
    additionalNumbers: "123"
  };

  private lettersSubscription: Subscription;
  private numbersSubscription: Subscription;
  private lettersStatusSubscription: Subscription;
  private isAuthorityLpn: boolean;

  // eslint-disable-next-line max-params
  public constructor(
    private readonly formGroup: FormGroupDirective,
    private readonly fb: FormBuilder,
    private readonly changeDetectionService: ComponentOverarchingChangeDetectionService,
    private readonly cdr: ChangeDetectorRef,
    private readonly featureToggleFacade: FeatureToggleFacade,
    private readonly el: ElementRef,
    @Inject(OLB_CONFIG) private readonly _olbConfig: OlbConfiguration
  ) {}

  public onTouched = () => {};

  public onChanged = (_: Partial<LpnInputForm> | null) => {};

  public ngOnInit(): void {
    this.initializeForm();

    this.changeDetectionService.changeDetectionRequest$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.writeValue(this.form.value);
      this.form.updateValueAndValidity();
      this.cdr.markForCheck();
    });
  }

  public createLpnInputs(specialLpnData: SpecialLpn): LpnInputFormcontrols {
    const lpnInputControls: LpnInputFormcontrols = {};

    if (specialLpnData.fields.letters) {
      this.inputPlaceholders.letters = specialLpnData.fields.letters.placeholder;
      lpnInputControls.letters = this.lpnValidator(
        specialLpnData.fields.letters.required,
        specialLpnData.fields.letters.pattern
      );
    } else {
      lpnInputControls.letters = null;
    }

    if (specialLpnData.fields.numbers) {
      this.inputPlaceholders.numbers = specialLpnData.fields.numbers.placeholder;
      lpnInputControls.numbers = this.lpnValidator(
        specialLpnData.fields.numbers.required,
        specialLpnData.fields.numbers.pattern
      );
    } else {
      lpnInputControls.numbers = null;
    }

    if (specialLpnData.fields.additionalNumbers) {
      this.inputPlaceholders.additionalNumbers = specialLpnData.fields.additionalNumbers.placeholder;
      lpnInputControls.additionalNumbers = this.lpnValidator(
        specialLpnData.fields.additionalNumbers.required,
        specialLpnData.fields.additionalNumbers.pattern
      );
    } else {
      lpnInputControls.additionalNumbers = null;
    }

    return lpnInputControls;
  }

  private initializeForm(): void {
    const lpnForm = this.fb.group<AddFormControls<LpnInputForm>>({
      country: this.fb.control<string | null>(""),
      type: this.fb.control<LicensePlateType>(LicensePlateType.NORMAL),
      region: this.fb.control<string | null>(
        this.form?.controls?.region?.value ?? null,
        CgValidators.lpnRegionValidators()
      ),
      letters: this.fb.control<string | null>({ value: null, disabled: true }, CgValidators.lpnLettersValidators()),
      numbers: this.fb.control<string | null>({ value: null, disabled: true }, CgValidators.lpnNumbersValidators())
    });

    this.formGroup.form.setControl("lpn", lpnForm);
    this.form = this.formGroup.form.get("lpn") as FormGroup<AddFormControls<LpnInputForm>>;

    if (this.isEditable) {
      this.form.controls.region.statusChanges
        .pipe(
          tap((status: string) => {
            if (status === "INVALID") {
              this.form.controls.letters?.disable();
              this.form.controls.numbers?.disable();
              this.form.controls.additionalNumbers?.disable();
            } else {
              if (this.form.controls.letters) {
                this.form.controls.letters?.enable();
              } else {
                this.form.controls.numbers?.enable();
                this.form.controls.additionalNumbers?.enable();
              }
            }

            this.form.updateValueAndValidity({ emitEvent: false });
            this.cdr.detectChanges();
          }),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe();

      this.form.controls.region.valueChanges
        .pipe(
          filter((region: string) => !!region),
          map((region: string) => region.toUpperCase()),
          distinctUntilChanged(),
          withLatestFrom(this.featureToggleFacade.isFeatureActive$(Feature.SUPPORT_SPECIAL_LPN)),
          filter(([_region, isFeatureActive]: [string, boolean]) => isFeatureActive),
          tap(([region, _isFeatureActive]: [string, boolean]) => {
            const specialLpnData = specialLpnModel[region];
            if (specialLpnData) {
              this.form.controls.type.setValue(LicensePlateType.SPECIAL);
              const inputs = this.createLpnInputs(specialLpnData);
              this.updateForm(inputs);
            } else {
              this.resetForm();
            }
          }),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe();

      this.initAuthorityLpnObservable();
    }
  }

  public updateForm(formInputs: LpnInputFormcontrols): void {
    Object.keys(formInputs).forEach((formControlName: string) => {
      const controlValidator = formInputs[formControlName];
      if (!controlValidator) {
        this.form.removeControl(formControlName);
      } else {
        const control = this.form.get(formControlName);
        if (control) {
          this.form.get(formControlName).setValidators(controlValidator);
          this.form.get(formControlName).updateValueAndValidity({ emitEvent: false });
        } else {
          this.form.addControl(formControlName, new UntypedFormControl(null, controlValidator));
        }
      }
    });

    this.form.updateValueAndValidity({ emitEvent: false });
    this.cdr.detectChanges();
  }

  private resetForm(): void {
    this.isAuthorityLpn = false;
    this.form.controls.type.setValue(LicensePlateType.NORMAL);
    const formFields = {
      letters: CgValidators.lpnLettersValidators(),
      numbers: CgValidators.lpnNumbersValidators(),
      additionalNumbers: null
    };
    this.updateForm(formFields);
    this.inputPlaceholders = {
      region: "K",
      letters: "CG",
      numbers: "175 E",
      additionalNumbers: "123"
    };

    this.lettersSubscription.unsubscribe();
    this.numbersSubscription.unsubscribe();
    this.initAuthorityLpnObservable();
  }

  private initAuthorityLpnObservable(): void {
    if (!this.lettersSubscription || this.lettersSubscription.closed) {
      this.lettersSubscription = this.form.controls.letters.valueChanges
        .pipe(
          distinctUntilChanged(),
          withLatestFrom(this.featureToggleFacade.isFeatureActive$(Feature.SUPPORT_SPECIAL_LPN)),
          filter(([value, isFeatureActive]: [string, boolean]) => value && !isNaN(Number(value)) && isFeatureActive),
          take(1),
          tap(([value, _isFeatureActive]: [string, boolean]) => {
            // all of this should only run if its a authority lpn
            this.isAuthorityLpn = true;
            this.form.controls.type.setValue(LicensePlateType.SPECIAL);

            this.form.controls.numbers.setValue(value);
            this.form.controls.numbers.setValidators([
              Validators.required,
              Validators.pattern(/^[0-9E\s-]*$/i),
              (control: FormControl) => {
                const inputValue = (control.value || "").replace(/\D/g, "");
                return inputValue.length <= 5 ? null : { maxDigits: true };
              }
            ]);
            this.form.controls.numbers?.enable();

            const controlEl = this.el.nativeElement.querySelector("#license-plate-numbers");
            if (controlEl) {
              controlEl.focus();
            }

            this.form.removeControl("letters");
          }),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe();
    }

    // subscribe on numbers input value changes only if value is empty so we can reset the form because
    // we think its a normal lpn
    if (!this.numbersSubscription || this.numbersSubscription.closed) {
      this.numbersSubscription = this.form.controls.numbers.valueChanges
        .pipe(
          distinctUntilChanged(),
          withLatestFrom(this.featureToggleFacade.isFeatureActive$(Feature.SUPPORT_SPECIAL_LPN)),
          filter(([value, isFeatureActive]: [string, boolean]) => !value && isFeatureActive && this.isAuthorityLpn),
          tap(([_value, _isFeatureActive]: [string, boolean]) => {
            this.resetForm();
            const controlEl = this.el.nativeElement.querySelector("#license-plate-letters");
            controlEl.focus();
          }),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe();
    }

    if ((this.form.controls.letters && !this.lettersStatusSubscription) || this.lettersStatusSubscription.closed) {
      this.lettersStatusSubscription = this.form.controls.letters.statusChanges
        .pipe(
          filter(() => !!this.form.controls.letters?.value),
          distinctUntilChanged(),
          tap((status: string) => {
            if (status === "INVALID") {
              this.form.controls.numbers?.disable();
              this.form.controls.additionalNumbers?.disable();
            } else {
              this.form.controls.numbers?.enable();
              this.form.controls.additionalNumbers?.enable();
            }

            this.form.updateValueAndValidity({ emitEvent: false });
            this.cdr.detectChanges();
          }),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe();
    }
  }

  public lpnValidator(required: boolean, pattern: RegExp) {
    if (required) {
      return [Validators.required, Validators.pattern(pattern)];
    } else {
      return [Validators.pattern(pattern)];
    }
  }

  public writeValue(value: LpnInputForm | null) {
    if (value && Object.keys(value).length > 0) {
      this.form.patchValue(this.lpnToLowerCase(value), { emitEvent: false });
    }
  }

  public registerOnChange(onChanged: (value: LpnInputForm | null) => void): void {
    this.onChanged = onChanged;
    if (this.form.valid && isDirectResumeFn(this._olbConfig.entryChannel)) {
      this.isEditable = false;
      this.setDisabledState(true);
    } else if (!this.form.valid && isDirectResumeFn(this._olbConfig.entryChannel)) {
      this.isEditable = true;
    }
  }

  public registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  public setDisabledState(disabled: boolean) {
    if (disabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  private lpnToLowerCase(value: Partial<LpnInputForm>): LpnInputForm {
    const { type, letters, numbers, region } = value;

    return {
      type,
      numbers: numbers?.toLowerCase().trim(),
      letters: letters?.toLowerCase().trim(),
      region: region?.toLowerCase().trim()
    };
  }
}
