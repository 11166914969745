import { type Action, createReducer, on } from "@ngrx/store";
import type { ToastMessage } from "@cg/toast";
import * as ToastActions from "./toast.actions";

export const TOAST_FEATURE_KEY = "toast";

export interface ToastState {
  insuranceToast: ToastMessage;
}

export interface ToastPartialState {
  readonly [TOAST_FEATURE_KEY]: ToastState;
}

export const initialState: ToastState = {
  insuranceToast: null
};

const toastReducer = createReducer(
  initialState,
  on(ToastActions.setInsuranceToast, (state: ToastState, { payload }: { payload: ToastMessage }) => ({
    ...state,
    insuranceToast: payload
  })),
  on(ToastActions.resetToastState, () => ({ ...initialState }))
);

export function reducer(state: ToastState | undefined, action: Action) {
  return toastReducer(state, action);
}
