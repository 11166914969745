import { createFeatureSelector, createSelector } from "@ngrx/store";
import type { Driver, InsuranceHolder } from "@cg/olb/shared";
import { CONTACTDATA_FEATURE_KEY, type ContactDataState } from "./contact-data.reducer";

export const getContactDataState = createFeatureSelector<ContactDataState>(CONTACTDATA_FEATURE_KEY);

export const getEmail = createSelector(getContactDataState, (state: ContactDataState) => state.email);

export const getMobile = createSelector(getContactDataState, (state: ContactDataState) => state.mobile);

export const getIsPolicyHolder = createSelector(getContactDataState, (state: ContactDataState) => state.isPolicyHolder);

export const getDriver = createSelector(getContactDataState, (state: ContactDataState) => state.driver);

export const getDriverTitle = createSelector(
  getContactDataState,
  getDriver,
  (state: ContactDataState, driver: Driver) => (driver ? driver.title : null)
);

export const getDriverFirstname = createSelector(
  getContactDataState,
  getDriver,
  (state: ContactDataState, driver: Driver) => (driver ? driver.firstname : null)
);

export const getDriverLastname = createSelector(
  getContactDataState,
  getDriver,
  (state: ContactDataState, driver: Driver) => (driver ? driver.lastname : null)
);

export const getDriverStreet = createSelector(
  getContactDataState,
  getDriver,
  (state: ContactDataState, driver: Driver) => (driver ? driver.street : null)
);

export const getDriverZip = createSelector(getContactDataState, getDriver, (state: ContactDataState, driver: Driver) =>
  driver ? driver.zip : null
);

export const getDriverCity = createSelector(
  getContactDataState,
  getDriver,
  (state: ContactDataState, driver: Driver) => (driver ? driver.city : null)
);

export const getDriverCountry = createSelector(
  getContactDataState,
  getDriver,
  (state: ContactDataState, driver: Driver) => (driver ? driver.country : null)
);

export const getInsuranceHolder = createSelector(
  getContactDataState,
  (state: ContactDataState) => state.insuranceHolder
);

export const getInsuranceHolderCompany = createSelector(
  getContactDataState,
  getInsuranceHolder,
  (state: ContactDataState, insuranceHolder: InsuranceHolder) => (insuranceHolder ? insuranceHolder.company : null)
);

export const getInsuranceHolderTitle = createSelector(
  getContactDataState,
  getInsuranceHolder,
  (state: ContactDataState, insuranceHolder: InsuranceHolder) => (insuranceHolder ? insuranceHolder.title : null)
);

export const getInsuranceHolderFirstname = createSelector(
  getContactDataState,
  getInsuranceHolder,
  (state: ContactDataState, insuranceHolder: InsuranceHolder) => (insuranceHolder ? insuranceHolder.firstname : null)
);

export const getInsuranceHolderLastname = createSelector(
  getContactDataState,
  getInsuranceHolder,
  (state: ContactDataState, insuranceHolder: InsuranceHolder) => (insuranceHolder ? insuranceHolder.lastname : null)
);

export const getInsuranceHolderStreet = createSelector(
  getContactDataState,
  getInsuranceHolder,
  (state: ContactDataState, insuranceHolder: InsuranceHolder) => (insuranceHolder ? insuranceHolder.street : null)
);

export const getInsuranceHolderZip = createSelector(
  getContactDataState,
  getInsuranceHolder,
  (state: ContactDataState, insuranceHolder: InsuranceHolder) => (insuranceHolder ? insuranceHolder.zip : null)
);

export const getInsuranceHolderCity = createSelector(
  getContactDataState,
  getInsuranceHolder,
  (state: ContactDataState, insuranceHolder: InsuranceHolder) => (insuranceHolder ? insuranceHolder.city : null)
);

export const getInsuranceHolderCountry = createSelector(
  getContactDataState,
  getInsuranceHolder,
  (state: ContactDataState, insuranceHolder: InsuranceHolder) => (insuranceHolder ? insuranceHolder.country : null)
);

export const getInsuranceHolderPhone = createSelector(
  getContactDataState,
  getInsuranceHolder,
  (state: ContactDataState, insuranceHolder: InsuranceHolder) => (insuranceHolder ? insuranceHolder.phone : null)
);

export const getCustomer = createSelector(getContactDataState, (state: ContactDataState) => state.customer);
