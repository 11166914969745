import { createFeatureSelector, createSelector } from "@ngrx/store";
import type { Appointment, AvailableServiceCenters } from "@cg/shared";
import { APPOINTMENT_FEATURE_KEY, type AppointmentState } from "./appointment.reducer";

export const getAppointmentState = createFeatureSelector<AppointmentState>(APPOINTMENT_FEATURE_KEY);

export const hasAppointmentLoaded = createSelector(getAppointmentState, (state: AppointmentState) => state.loaded);

export const isConfirmed = createSelector(getAppointmentState, (state: AppointmentState) => state.confirmed);

export const isLoading = createSelector(getAppointmentState, (state: AppointmentState) => state.loading);

export const getError = createSelector(getAppointmentState, (state: AppointmentState) => state.error);

export const isCalibration = createSelector(getAppointmentState, (state: AppointmentState) => state.calibration);

export const getAvailableAppointments = createSelector(
  getAppointmentState,
  hasAppointmentLoaded,
  (state: AppointmentState, hasLoaded: boolean) =>
    hasLoaded && state.availableAppointments !== null ? state.availableAppointments : null
);

export const getAvailableServiceCenters = createSelector(getAppointmentState, (state: AppointmentState) =>
  state.availableServiceCenters && state.availableServiceCenters.length > 0 ? state.availableServiceCenters : null
);

export const getSearchedAppointment = createSelector(
  getAppointmentState,
  (state: AppointmentState) => state.searchAppointment
);

export const getCurrentAppointment = createSelector(
  getAppointmentState,
  (state: AppointmentState) => state.currentAppointment
);

export const getFormattedAddress = createSelector(
  getAppointmentState,
  (state: AppointmentState) => state.formattedAddress
);

export const getServiceCenterLatLng = createSelector(getAppointmentState, (state: AppointmentState) =>
  state.lat && state.lng ? { lat: state.lat, lng: state.lng } : null
);

export const getAppointmentId = createSelector(getAppointmentState, (state: AppointmentState) => state.appointmentId);

export const getSelectedAppointment = createSelector(
  getAppointmentId,
  getAvailableAppointments,
  (id: string, appointments: Appointment[]) =>
    appointments?.find((appointment: Appointment) => appointment.appointmentId === id)
);

export const getSelectedServiceCenterIds = createSelector(
  getAppointmentState,
  (state: AppointmentState) => state.selectedServiceCenterIds
);

export const getSelectedServiceCenter = createSelector(
  getSelectedAppointment,
  getAvailableServiceCenters,
  (appointment: Appointment, serviceCenters: AvailableServiceCenters[]) =>
    serviceCenters?.find((sc: AvailableServiceCenters) => sc.serviceCenter === appointment?.serviceCenter)
);

export const getStatus = createSelector(getAppointmentState, (state: AppointmentState) => state.status);

export const getPosition = createSelector(getAppointmentState, (state: AppointmentState) => ({
  lat: state.lat,
  lng: state.lng
}));

export const getMobileServiceAvailable = createSelector(
  getAppointmentState,
  (state: AppointmentState) => state.mobileServiceAvailable
);

export const getAppointmentNextLoading = createSelector(
  getAppointmentState,
  (state: AppointmentState) => state.appointmentNextLoading
);

export const getNextLoadingLimitReached = createSelector(
  getAppointmentState,
  (state: AppointmentState) => state.nextLoadingLimitReached
);

export const hasAdverseBuyAppointments = createSelector(
  getAppointmentState,
  (state: AppointmentState) => state.adverseBuyAppointments
);

export const getStartDate = createSelector(getAppointmentState, (state: AppointmentState) => state.start);

export const getDesiredAppointmentDate = createSelector(
  getAppointmentState,
  (state: AppointmentState) => state.desiredAppointmentDate
);

export const getDesiredPeriod = createSelector(getAppointmentState, (state: AppointmentState) => state.desiredPeriod);

export const getDesiredAppointmentId = createSelector(
  getAppointmentState,
  (state: AppointmentState) => state.desiredAppointmentId
);

export const getEarliestPossibleAppointmentDate = createSelector(
  getAppointmentState,
  (state: AppointmentState) => new Date(state.earliestPossibleAppointmentDate)
);

export const getAptModel = createSelector(getAppointmentState, (state: AppointmentState) => state.aptModel);

export const getLocality = createSelector(getAppointmentState, (state: AppointmentState) => state.locality);

export const getSearchClicked = createSelector(getAppointmentState, (state: AppointmentState) => state.searchClicked);

export const getFirstSearch = createSelector(getAppointmentState, (state: AppointmentState) => state.firstSearch);
