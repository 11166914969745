import { type Action, createReducer, on } from "@ngrx/store";
import type { Context } from "@cg/olb/shared";
import * as ContextActions from "./context.actions";

export const CONTEXT_FEATURE_KEY = "context";

export interface ContextState {
  error?: string | null; // last none error (if any)
  loaded: boolean;
  context: Context;
}

export interface ContextPartialState {
  readonly [CONTEXT_FEATURE_KEY]: ContextState;
}

export const initialState: ContextState = {
  error: null,
  loaded: false,
  context: null
};

const contextReducer = createReducer(
  initialState,
  on(ContextActions.createContextSuccess, (state: ContextState, { payload }: { payload: Context }) => ({
    ...state,
    context: payload,
    error: null,
    loaded: true
  })),
  on(ContextActions.setCustomerCaseId, (state: ContextState, { payload }: { payload: { customerCaseId: string } }) => ({
    ...state,
    context: {
      ...state.context,
      customerCaseId: payload.customerCaseId
    }
  })),
  on(ContextActions.createContextFailure, (state: ContextState, { error }: { error: string }) => ({
    ...state,
    error
  })),
  on(ContextActions.clearAeNumber, (state: ContextState) => ({
    ...state,
    context: {
      ...state.context,
      aeNummer: null
    }
  })),
  on(ContextActions.resetContextState, () => ({ ...initialState }))
);

export function reducer(state: ContextState | undefined, action: Action) {
  return contextReducer(state, action);
}
