import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { ToastMessage } from "@cg/toast";
import * as ToastActions from "./toast.actions";
import { ToastPartialState } from "./toast.reducer";
import * as ToastSelectors from "./toast.selectors";

@Injectable({
  providedIn: "root"
})
export class ToastFacade {
  public insuranceToast$ = this.store.pipe(select(ToastSelectors.getInsuranceToast));

  public constructor(private store: Store<ToastPartialState>) {}

  public setInsuranceToast(toast: ToastMessage) {
    this.store.dispatch(ToastActions.setInsuranceToast({ payload: toast }));
  }

  public resetState() {
    this.store.dispatch(ToastActions.resetToastState());
  }
}
