import { type Action, createReducer, on } from "@ngrx/store";
import type { GetGdv } from "@cg/shared";
import * as GdvActions from "./gdv.actions";

export const GDV_FEATURE_KEY = "gdv";

export interface GdvState {
  fetch: GetGdv;
  useNoDate: boolean;
}

export interface GdvPartialState {
  readonly [GDV_FEATURE_KEY]: GdvState;
}

export const initialState: GdvState = {
  fetch: null,
  useNoDate: false
};

const damageReducer = createReducer(
  initialState,
  on(GdvActions.setFetch, (state: GdvState, { payload }: { payload: GetGdv }) => ({ ...state, fetch: payload })),
  on(GdvActions.setUseNoDate, (state: GdvState, { payload }: { payload: boolean }) => ({
    ...state,
    useNoDate: payload
  })),
  on(GdvActions.resetGdvState, () => ({ ...initialState }))
);

export function reducer(state: GdvState | undefined, action: Action) {
  return damageReducer(state, action);
}
