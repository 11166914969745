import { type Action, createReducer, on } from "@ngrx/store";
import * as YextActions from "./yext.actions";

export const YEXT_FEATURE_KEY = "yext";

export interface YextState {
  infos: string | null;
}

export interface YextPartialState {
  readonly [YEXT_FEATURE_KEY]: YextState;
}

export const initialState: YextState = {
  // contains the infos from yext given in the path (scId or location as text e.g. 'Berlin')
  infos: null
};

const yextReducer = createReducer(
  initialState,
  on(YextActions.setInfos, (state: YextState, { payload }: { payload: string }) => ({
    ...state,
    infos: payload
  }))
);

export function reducer(state: YextState | undefined, action: Action) {
  return yextReducer(state, action);
}
