import { createAction, props } from "@ngrx/store";
import type { Context } from "@cg/olb/shared";
import type { EntryChannel } from "@cg/shared";

export const createContext = createAction(
  "[Context] Create Context",
  props<{ payload: { entryChannel: EntryChannel } }>()
);
export const createContextSuccess = createAction("[Context] Create Context Success", props<{ payload: Context }>());
export const createContextFailure = createAction("[Context] Create Context Failure", props<{ error: string }>());
export const resetContextState = createAction("[Context] Reset State");
export const setCustomerCaseId = createAction(
  "[Context] Set Customer Case Id",
  props<{ payload: { customerCaseId: string } }>()
);
export const clearAeNumber = createAction("[Context] Clear Ae Number");
