import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { UnifiedError } from "@cg/core/types";
import { errorToString } from "@cg/core/utils";
import { VAPsEventFlag, VAPsFailureEventData, VAPsSuccessEventData, VAPsTriggerEventData } from "@cg/olb/shared";
import { AdditionalProduct, ChosenProduct, CustomerCase, Lpn, SwitchChannelPayload } from "@cg/shared";
import * as CustomerCaseActions from "./customer-case.actions";
import { CustomerCasePartialState, CustomerCaseState } from "./customer-case.reducer";
import * as CustomerCaseSelectors from "./customer-case.selectors";

@Injectable({
  providedIn: "root"
})
export class CustomerCaseFacade {
  public customerCaseId$ = this.store.pipe(select(CustomerCaseSelectors.getCustomerCaseId));
  public customerCase$ = this.store.pipe(select(CustomerCaseSelectors.getCustomerCase));
  public duplicate$ = this.store.pipe(select(CustomerCaseSelectors.getDuplicate));
  public protectProduct$ = this.store.pipe(select(CustomerCaseSelectors.getProtectProduct));
  public wiperProduct$ = this.store.pipe(select(CustomerCaseSelectors.getWiperProduct));
  public isOrganization$ = this.store.pipe(select(CustomerCaseSelectors.getIsOrganization));

  public constructor(private store: Store<CustomerCasePartialState>) {}

  public setLoaded(loaded: boolean) {
    this.store.dispatch(CustomerCaseActions.setLoaded({ payload: loaded }));
  }

  public setCustomerCaseId(id: string) {
    this.store.dispatch(CustomerCaseActions.setCustomerCaseId({ payload: id }));
  }

  public setContextId(id: string) {
    this.store.dispatch(CustomerCaseActions.setContextId({ payload: id }));
  }

  public getCustomerCase(id: string) {
    this.store.dispatch(CustomerCaseActions.fetchCustomerCase({ payload: id }));
  }

  public updateCustomerCase(customerCase: CustomerCase): void {
    this.store.dispatch(CustomerCaseActions.updateCustomerCase({ payload: customerCase }));
  }

  public confirmCustomerCase(id: string) {
    this.store.dispatch(CustomerCaseActions.confirmCustomerCase({ payload: id }));
  }

  public checkDuplicate(lpn: Lpn, newCustomerCaseId: string) {
    this.store.dispatch(
      CustomerCaseActions.checkDuplicate({
        payload: {
          newCustomerCaseId,
          lpn
        }
      })
    );
  }

  public switchChannel(payload: SwitchChannelPayload) {
    this.store.dispatch(CustomerCaseActions.switchChannel({ payload }));
  }

  public resetState(): void {
    this.store.dispatch(CustomerCaseActions.resetCustomerCaseState());
  }

  public patchStateForResume(state: Partial<CustomerCaseState>) {
    this.store.dispatch(CustomerCaseActions.patchStateForResume({ payload: state }));
  }

  public setOrderCommitment(status: boolean): void {
    this.store.dispatch(CustomerCaseActions.setOrderCommitment({ payload: status }));
  }

  public offerVAP(product: AdditionalProduct, flags?: VAPsEventFlag[]): void {
    this.store.dispatch(CustomerCaseActions.offerVAP({ payload: { product, flags } as VAPsTriggerEventData }));
  }

  public requestAddVAP(product: AdditionalProduct, value: boolean = true, flags?: VAPsEventFlag[]): void {
    this.store.dispatch(
      CustomerCaseActions.requestAddVAP({ payload: { product, flags, value } as VAPsTriggerEventData })
    );
  }

  public addVAP(product: AdditionalProduct, value: boolean = true, flags?: VAPsEventFlag[]): void {
    this.store.dispatch(CustomerCaseActions.addVAP({ payload: { product, flags, value } as VAPsTriggerEventData }));
  }

  public addVAPSuccess(
    product: AdditionalProduct,
    customerCaseId: string,
    value: boolean = true,
    flags?: VAPsEventFlag[]
  ): void {
    this.store.dispatch(
      CustomerCaseActions.addVAPSuccess({ payload: { product, flags, customerCaseId, value } as VAPsSuccessEventData })
    );
  }

  public addVAPFailure(
    product: AdditionalProduct,
    error: UnifiedError,
    customerCaseId: string,
    flags?: VAPsEventFlag[]
  ): void {
    this.store.dispatch(
      CustomerCaseActions.addVAPFailure({
        payload: { product, flags, customerCaseId, errorMessage: errorToString(error) } as VAPsFailureEventData
      })
    );
  }

  public removeVAP(product: AdditionalProduct, flags?: VAPsEventFlag[]): void {
    this.store.dispatch(CustomerCaseActions.removeVAP({ payload: { product, flags } as VAPsTriggerEventData }));
  }

  public removeVAPSuccess(product: AdditionalProduct, customerCaseId: string, flags?: VAPsEventFlag[]): void {
    this.store.dispatch(
      CustomerCaseActions.removeVAPSuccess({ payload: { product, flags, customerCaseId } as VAPsSuccessEventData })
    );
  }

  public removeVAPFailure(
    product: AdditionalProduct,
    error: UnifiedError,
    customerCaseId: string,
    flags?: VAPsEventFlag[]
  ): void {
    this.store.dispatch(
      CustomerCaseActions.removeVAPFailure({
        payload: { product, flags, customerCaseId, errorMessage: errorToString(error) } as VAPsFailureEventData
      })
    );
  }

  public bookVAPSuccess(product: AdditionalProduct, flags?: VAPsEventFlag[]): void {
    this.store.dispatch(CustomerCaseActions.bookVAPSuccess({ payload: { product, flags } as VAPsTriggerEventData }));
  }

  public setShoppingCartEntries(products: ChosenProduct[]): void {
    this.store.dispatch(CustomerCaseActions.setShoppingCartEntries({ payload: products }));
  }
}
