import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ResumeApiRequest } from "@cg/carglass-shared-state";
import { ApiService } from "@cg/core/api";
import { Lpn, Resume, ResumeResponse } from "@cg/shared";

interface ResumeCreateUpdateResponse {
  resumeId: string;
}

@Injectable({
  providedIn: "root"
})
export class ResumeService {
  public constructor(private apiService: ApiService) {}

  // @todo: get rid of unecessary "Resume" postifx on all methods
  public loadResume(resumeId: string, lpn?: Lpn): Observable<Resume> {
    const path = lpn ? `/resume/${resumeId}?lpn=${lpn.region}-${lpn.letters}-${lpn.numbers}` : `/resume/${resumeId}`;

    return this.apiService.get<ResumeResponse>("frontendProxy", path, { disableRetry: true }).pipe(
      map(
        (response: ResumeResponse) =>
          ({
            ...response,
            state: !response.state ? null : JSON.parse(response.state)
          }) as Resume
      )
    );
  }

  public createResume(resumeRequest: ResumeApiRequest): Observable<string> {
    const body = {
      ...resumeRequest,
      state: JSON.stringify(resumeRequest.state)
    };

    return this.apiService
      .post<ResumeCreateUpdateResponse>("resume", "", body)
      .pipe(map((response: ResumeCreateUpdateResponse) => response.resumeId));
  }

  public updateResume(resumeId: string, resumeRequest: ResumeApiRequest): Observable<string> {
    const body = {
      ...resumeRequest,
      state: JSON.stringify(resumeRequest.state)
    };

    return this.apiService
      .put<ResumeCreateUpdateResponse>("resume", "/" + resumeId, body)
      .pipe(map((response: ResumeCreateUpdateResponse) => response.resumeId));
  }
}
