import { createAction, props } from "@ngrx/store";
import type {
  Appointment,
  AppointmentLocation,
  AppointmentPayload,
  AppointmentResponse,
  AppointmentServiceCenterPayload,
  AppointmentValidDesiredDate,
  AptModel,
  JobStatus,
  OpportunityFunnelAppointmentPeriod,
  SetAppointmentPayload
} from "@cg/shared";

export const clearAndRefetchAppointments = createAction(
  "[Appointment] Clear And Refetch Appointment",
  props<{ payload?: { serviceCenterId: string; timerangeBegin?: string } }>()
);

export const confirmAppointment = createAction(
  "[Appointment] Confirm Appointment",
  props<{ payload: SetAppointmentPayload }>()
);
export const confirmAppointmentSuccess = createAction(
  "[Appointment] Confirm Appointment Success",
  props<{ payload: SetAppointmentPayload }>()
);
export const confirmAppointmentFailure = createAction(
  "[Appointment] Confirm Appointment Failure",
  props<{ error: string }>()
);

export const getAppointments = createAction("[Appointment] Get Appointments", props<{ payload: AppointmentPayload }>());
export const fetchTeslaAppointments = createAction(
  "[Appointment] Clear And Refetch Tesla Appointment",
  props<{ payload?: { serviceCenterId: string; timerangeBegin?: string } }>()
);
export const getAppointmentsSuccess = createAction(
  "[Appointment] Get Appointments Success",
  props<{ payload: AppointmentResponse }>()
);
export const getAppointmentsFailure = createAction(
  "[Appointment] Get Appointments Failure",
  props<{ error: string }>()
);

export const getNextAppointments = createAction(
  "[Appointment] Get Next Appointments",
  props<{ payload: { date: string; aptModel?: AptModel } }>()
);
export const fetchNextAppointments = createAction(
  "[Appointment] Fetch Next Appointments",
  props<{ payload: AppointmentPayload }>()
);
export const fetchNextAppointmentsSuccess = createAction(
  "[Appointment] Fetch Next Appointments Success",
  props<{ payload: AppointmentResponse }>()
);
export const fetchNextAppointmentsFailure = createAction(
  "[Appointment] Fetch Next Appointments Failure",
  props<{ error: string }>()
);

export const getServiceCenters = createAction(
  "[Appointment] Get Service Centers",
  props<{ payload: AppointmentServiceCenterPayload }>()
);
export const getServiceCentersSuccess = createAction(
  "[Appointment] Get Service Centers Success",
  props<{ payload: AppointmentResponse }>()
);
export const getServiceCentersFailure = createAction(
  "[Appointment] Get Service Centers Failure",
  props<{ error: string }>()
);

export const validateAppointmentDate = createAction(
  "[Appointment] Validate Date",
  props<{ payload: AppointmentPayload }>()
);
export const validateAppointmentDateSuccess = createAction(
  "[Appointment] Validate Date Success",
  props<{ payload: AppointmentValidDesiredDate }>()
);
export const validateAppointmentDateFailure = createAction(
  "[Appointment] Validate Date Failure",
  props<{ error: string }>()
);

export const setCurrentAppointment = createAction(
  "[Appointment] Set Current Appointment",
  props<{ payload: Appointment }>()
);

export const setFormattedAddress = createAction("[Appointment] Set Formatted Address", props<{ payload: string }>());

export const setLatLng = createAction("[Appointment] Set Lat Lng", props<{ payload: { lat: number; lng: number } }>());
export const setAppointmentId = createAction("[Appointment] Set Selected Appointment", props<{ payload: string }>());
export const setSelectedServiceCenterIds = createAction(
  "[Appointment] Set Selected Service Center Ids",
  props<{ payload: string[] }>()
);

export const setAppointmentLocation = createAction(
  "[Appointment] Set Location",
  props<{ payload: AppointmentLocation }>()
);

export const setMobileServiceAvailable = createAction(
  "[Appointment] Set Mobile Service Available",
  props<{ payload: boolean }>()
);

export const saveAutoCompleteResult = createAction(
  "[Appointment] Save Autocomplete Result",
  props<{
    payload: { lat: number; lng: number; formattedAddress: string; locality: string; timerangeBegin?: string };
  }>()
);

export const setStatus = createAction("[Appointment] Set Status", props<{ payload: JobStatus }>());
export const setNextLoadingLimitReached = createAction(
  "[Appointment] Set Next Loading Limit Reached",
  props<{ payload: boolean }>()
);

export const setDesiredAppointmentDate = createAction(
  "[Appointment] Set Desired Appointment Date",
  props<{ payload: Date }>()
);
export const setDesiredPeriod = createAction(
  "[Appointment] Set Desired Period",
  props<{ payload: OpportunityFunnelAppointmentPeriod }>()
);

export const resetStateForForm = createAction("[Appointment] Reset State For Form");
export const resetAppointmentState = createAction("[Appointment] Reset State");

export const reloadAppointments = createAction("[Appointment] Reload Appoitnments");

export const fetchEarliestPossibleAppointmentDate = createAction(
  "[Appointment] Fetch Earliest Possible Appointment Date"
);

export const fetchEarliestPossibleAppointmentDateSuccess = createAction(
  "[Appointment] Fetch Earliest Possible Appointment Date Success",
  props<{ payload: { desiredAppointmentDate: string; desiredAppointmentId: string } }>()
);

export const fetchEarliestPossibleAppointmentDateFailure = createAction(
  "[Appointment] Fetch Earliest Possible Appointment Date Failure",
  props<{ error: string }>()
);

// was added for OptimizelyExperiment.NEW_APPOINTMENT_TILE, remove this comment if test is successfull
export const setSearchClicked = createAction("[Appointment] Set Search Clicked", props<{ payload: boolean }>());

// was added for OptimizelyExperiment.NEW_APPOINTMENT_TILE, remove this comment if test is successfull
export const setFirstSearch = createAction("[Appointment] Set First Search", props<{ payload: boolean }>());
