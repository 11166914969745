import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { filter, map, take } from "rxjs/operators";
import formatISO from "date-fns/formatISO";
import { DamagedRearWindowCount, DamagedSideWindowCount } from "@cg/olb/shared";
import {
  DamageChipCount,
  DamageLocation,
  DamageSize,
  DamageType,
  DamageWindow,
  Lpn,
  RequiredService
} from "@cg/shared";
import * as DamageActions from "./damage.actions";
import { DamagePartialState, DamageState } from "./damage.reducer";
import * as DamageSelectors from "./damage.selectors";

@Injectable({
  providedIn: "root"
})
export class DamageFacade {
  public damageId$ = this.store.pipe(select(DamageSelectors.getDamageId));
  public selectedDamage$ = this.store.pipe(select(DamageSelectors.getDamagedWindow));
  public damageType$ = this.store.pipe(select(DamageSelectors.getDamageType));
  public damageChipCount$ = this.store.pipe(select(DamageSelectors.getDamageChipCount));
  public damageLocation$ = this.store.pipe(select(DamageSelectors.getLocateDamage));
  public requiredService$ = this.store.pipe(select(DamageSelectors.getRequiredService));
  public damageSize$ = this.store.pipe(select(DamageSelectors.getDamageSize));
  public damageDate$ = this.store.pipe(
    select(DamageSelectors.getDamageDate),
    map((damageDate: string) => (damageDate ? formatISO(new Date(damageDate), { representation: "date" }) : null))
  );
  public lpn$ = this.store.pipe(select(DamageSelectors.getLpn));
  public rawLpn$ = this.store.pipe(select(DamageSelectors.getRawLpn));
  public damageState$ = this.store.pipe(select(DamageSelectors.getDamageState));
  public requiredServiceIsReplace$ = this.store
    .pipe(select(DamageSelectors.getRequiredService))
    .pipe(map((requiredService: RequiredService) => requiredService === RequiredService.REPLACE));
  public damage$ = this.store.pipe(select(DamageSelectors.getDamage));
  public damagedSideWindowCount$ = this.store.pipe(select(DamageSelectors.getDamagedSideWindowCount));
  public damagedRearWindowCount$ = this.store.pipe(select(DamageSelectors.getDamagedRearWindowCount));

  public constructor(private store: Store<DamagePartialState>) {}

  public setId(id: string): void {
    this.store.dispatch(DamageActions.setId({ payload: id }));
  }

  public setDamagedWindow(damagedWindow: DamageWindow): void {
    this.store.dispatch(DamageActions.setDamageWindow({ payload: damagedWindow }));
  }

  public setCrackAmount(crackAmount: DamageType) {
    this.store.dispatch(DamageActions.setDamageType({ payload: crackAmount }));
  }

  public setLocateDamage(locateDamage: DamageLocation) {
    this.store.dispatch(DamageActions.setLocateDamage({ payload: locateDamage }));
  }

  public setDamageChipCount(damageChipCount: DamageChipCount) {
    this.store.dispatch(DamageActions.setDamageChipCount({ payload: damageChipCount }));
  }

  public setRequiredService(requiredService: RequiredService) {
    this.store.dispatch(DamageActions.setRequiredService({ payload: requiredService }));
  }

  public setDamageSize(damageSize: DamageSize) {
    this.store.dispatch(DamageActions.setDamageSize({ payload: damageSize }));
  }

  public setDamageDate(date: Date | null) {
    this.store.dispatch(
      DamageActions.setDamageDate({
        payload: date ? formatISO(date, { representation: "date" }) : null
      })
    );
  }

  public setNoDate() {
    // set date to 01.01.1970 to flag it as "no date", because user does not have to input a date
    this.setDamageDate(new Date(1970, 0, 1));
  }

  public setLpn(lpn: Lpn) {
    this.store.dispatch(DamageActions.setLpn({ payload: lpn }));
  }

  public updateDamage(payload: DamageState) {
    this.requiredService$
      .pipe(
        filter((requiredService: RequiredService | null) => requiredService === null),
        take(1)
      )
      .subscribe(() => this.store.dispatch(DamageActions.updateDamage({ payload })));

    this.setRequiredService(null);
  }

  public resetState() {
    this.store.dispatch(DamageActions.resetDamageState());
  }

  public setDamagedSideWindowCount(count: DamagedSideWindowCount) {
    this.store.dispatch(DamageActions.setDamagedSideWindowCount({ payload: count }));
  }

  public setDamagedRearWindowCount(count: DamagedRearWindowCount) {
    this.store.dispatch(DamageActions.setDamagedRearWindowCount({ payload: count }));
  }
}
