import { createFeatureSelector, createSelector } from "@ngrx/store";
import type { Insurance, InsuranceResponse } from "@cg/olb/shared";
import { INSURANCE_FEATURE_KEY, type InsuranceState } from "./insurance.reducer";

// Lookup the 'Insurance' feature state managed by NgRx
export const getInsuranceState = createFeatureSelector<InsuranceState>(INSURANCE_FEATURE_KEY);

export const hasLoadedInsurance = createSelector(getInsuranceState, (state: InsuranceState) => state.loaded);

export const getInsuranceError = createSelector(getInsuranceState, (state: InsuranceState) => state.error);

export const getInsuranceType = createSelector(getInsuranceState, (state: InsuranceState) => state.type);

export const getChatInsuranceType = createSelector(
  getInsuranceState,
  (state: InsuranceState) => state.chatInsuranceType
);

export const getInsurances = createSelector(getInsuranceState, (state: InsuranceState) => state.insurances);

export const getTopInsurances = createSelector(getInsuranceState, (state: InsuranceState) => state.topInsurances);

export const getGdvVin = createSelector(getInsuranceState, (state: InsuranceState) => state.gdvVin);

export const getPolicyNumber = createSelector(getInsuranceState, (state: InsuranceState) => state.policyNumber);

export const getDamageNumber = createSelector(getInsuranceState, (state: InsuranceState) => state.damageNumber);

export const getSelectedInsurance = createSelector(
  getInsuranceState,
  (state: InsuranceState) => state.selectedInsurance
);

export const getInsuranceWithTopInsurances = createSelector(
  getInsurances,
  getTopInsurances,
  (insurances: Insurance[], topInsurances: Insurance[]) => [...topInsurances, ...insurances]
);

export const getInsuranceNameByNumber = (number: string) =>
  createSelector(getInsuranceWithTopInsurances, (insurances: Insurance[]) => {
    const nameItem = insurances.find((insurance: Insurance) => insurance.number === number);

    return nameItem ? nameItem.name : null;
  });

export const getInsuranceIsPartnerById = (id: string) =>
  createSelector(getInsuranceWithTopInsurances, (insurances: Insurance[]) => {
    const nameItem = insurances.find((insurance: Insurance) => insurance.id === id);

    return nameItem ? nameItem.partner : null;
  });

export const getInsuranceById = (id: string) =>
  createSelector(getInsuranceWithTopInsurances, (insurances: Insurance[]) => {
    const insuranceItem = insurances.find((insurance: Insurance) => insurance.id === id);

    return insuranceItem ? insuranceItem : null;
  });

export const getInsuranceNameById = (id: string) =>
  createSelector(getInsuranceWithTopInsurances, (insurances: Insurance[]) => {
    const nameItem = insurances.find((insurance: Insurance) => insurance.id === id);

    return nameItem ? nameItem.name : null;
  });

export const getInsuranceNumberById = (id: string) =>
  createSelector(getInsuranceWithTopInsurances, (insurances: Insurance[]) => {
    const nameItem = insurances.find((insurance: Insurance) => insurance.id === id);

    return nameItem ? nameItem.number : null;
  });

export const getInsuranceUseCaseById = (id: string) =>
  createSelector(getInsuranceWithTopInsurances, (insurances: Insurance[]) => {
    const nameItem = insurances.find((insurance: Insurance) => insurance.id === id);

    return nameItem ? nameItem.insuranceUseCase : null;
  });

export const getInsuranceResponse = createSelector(
  getInsuranceState,
  (state: InsuranceState) => state.insuranceResponse
);

export const getCarModelFromInsuranceResponse = createSelector(getInsuranceResponse, (state: InsuranceResponse) => {
  if (state?.brand && state?.model) return `${state.brand}, ${state.model}`;
  if (state?.brand) return state.brand;
  if (state?.model) return state.model;
  return null;
});

export const getVinFromInsuranceResponse = createSelector(getInsuranceResponse, (state: InsuranceResponse) => {
  if (state && state.vin) {
    const parts = state.vin.split("-");
    if (parts && parts.length > 0) {
      return parts[0].trim();
    }
  }

  return null;
});

export const getVinChecksumFromInsuranceResponse = createSelector(getInsuranceResponse, (state: InsuranceResponse) => {
  if (state && state.vin) {
    const parts = state.vin.split("-");
    if (parts && parts.length === 2) {
      return parts[1].trim();
    }
  }

  return null;
});
