import { type Action, createReducer, on } from "@ngrx/store";
import {
  type DamageChipCount,
  type DamageLocation,
  type DamageResponse,
  type DamageSize,
  type DamageType,
  type DamageWindow,
  type Lpn,
  RequiredService
} from "@cg/shared";
import type { DamagedRearWindowCount, DamagedSideWindowCount } from "@cg/olb/shared";
import * as DamageActions from "./damage.actions";

export const DAMAGE_FEATURE_KEY = "damage";

export interface DamageState {
  id: string; // which Damage record has been selected
  damageWindow: DamageWindow; // has the Damage list been loaded
  damageType: DamageType;
  damageLocation: DamageLocation;
  damageChipCount: DamageChipCount;
  requiredService: RequiredService;
  damageSize: DamageSize;
  lpn: Lpn;
  damageDate: string;
  error?: string | null;
  damagedSideWindowCount: DamagedSideWindowCount;
  damagedRearWindowCount: DamagedRearWindowCount;
}

export interface DamagePartialState {
  readonly [DAMAGE_FEATURE_KEY]: DamageState;
}

export const initialState: DamageState = {
  id: "",
  damageWindow: null,
  damageType: null,
  damageLocation: null,
  requiredService: null,
  damageSize: null,
  lpn: null,
  damageDate: null,
  damageChipCount: null,
  damagedSideWindowCount: null,
  damagedRearWindowCount: null
};

const damageReducer = createReducer(
  initialState,
  on(DamageActions.setId, (state: DamageState, { payload }: { payload: string }) => ({ ...state, id: payload })),
  on(DamageActions.setDamageWindow, (state: DamageState, { payload }: { payload: DamageWindow }) => ({
    ...state,
    damageWindow: payload
  })),
  on(DamageActions.setDamageType, (state: DamageState, { payload }: { payload: DamageType }) => ({
    ...state,
    damageType: payload
  })),
  on(DamageActions.setLocateDamage, (state: DamageState, { payload }: { payload: DamageLocation }) => ({
    ...state,
    damageLocation: payload
  })),
  on(DamageActions.setRequiredService, (state: DamageState, { payload }: { payload: RequiredService }) => ({
    ...state,
    requiredService: payload
  })),
  on(DamageActions.setDamageSize, (state: DamageState, { payload }: { payload: DamageSize }) => ({
    ...state,
    damageSize: payload
  })),
  on(DamageActions.setDamageChipCount, (state: DamageState, { payload }: { payload: DamageChipCount }) => ({
    ...state,
    damageChipCount: payload
  })),
  on(DamageActions.setLpn, (state: DamageState, { payload }: { payload: Lpn }) => ({ ...state, lpn: payload })),
  on(DamageActions.setDamageDate, (state: DamageState, { payload }: { payload: string }) => ({
    ...state,
    damageDate: payload
  })),
  on(DamageActions.updateDamageSuccess, (state: DamageState, { payload }: { payload: DamageResponse }) => ({
    ...state,
    id: payload.id,
    requiredService: RequiredService[payload.requiredService]
  })),
  on(DamageActions.updateDamageFailure, (state: DamageState, { error }: { error: string }) => ({
    ...state,
    error
  })),
  on(DamageActions.resetDamageState, () => ({ ...initialState })),
  on(
    DamageActions.setDamagedSideWindowCount,
    (state: DamageState, { payload }: { payload: DamagedSideWindowCount }) => ({
      ...state,
      damagedSideWindowCount: payload
    })
  ),
  on(
    DamageActions.setDamagedRearWindowCount,
    (state: DamageState, { payload }: { payload: DamagedRearWindowCount }) => ({
      ...state,
      damagedRearWindowCount: payload
    })
  )
);

export function reducer(state: DamageState | undefined, action: Action) {
  return damageReducer(state, action);
}
