import { type Action, createReducer, on } from "@ngrx/store";
import * as OpportunityFunnnelActions from "./opportunity-funnel.actions";

export const OPPORTUNITY_FUNNEL_FEATURE_KEY = "opportunityFunnel";

export interface OpportunityFunnelState {
  callNow: boolean;
}

export interface OpportunityFunnelPartialState {
  readonly [OPPORTUNITY_FUNNEL_FEATURE_KEY]: OpportunityFunnelState;
}

export const initialState: OpportunityFunnelState = {
  callNow: false
};

const processReducer = createReducer(
  initialState,
  on(OpportunityFunnnelActions.setCallNow, (state: OpportunityFunnelState, { payload }: { payload: boolean }) => ({
    ...state,
    callNow: payload
  }))
);

export function reducer(state: OpportunityFunnelState | undefined, action: Action) {
  return processReducer(state, action);
}
