<div
  class="box-border inline-flex max-w-full flex-row space-x-2 rounded-sm border border-cg-gray-light-70 px-2 py-[6px]"
  part="lpn-wrapper"
  data-cy="lpn-input-wrapper"
  formGroupName="lpn"
>
  <input
    type="text"
    id="license-plate-region"
    data-cy="lpn-input-region"
    class="lpn-input-field w-[80px] disabled:opacity-40"
    part="lpn-input-field"
    [placeholder]="inputPlaceholders.region"
    autocomplete="section-license-plate"
    formControlName="region"
  />
  <div class="flex flex-col justify-center">
    <div class="size-2 rounded-[999px] bg-cg-anthracite-light-80"></div>
    <div class="mt-1 size-2 rounded-[999px] bg-cg-gray-light-70"></div>
  </div>
  @if (form.controls.letters) {
    <input
      type="text"
      id="license-plate-letters"
      data-cy="lpn-input-letters"
      class="lpn-input-field min-w-[45px] disabled:opacity-40"
      part="lpn-input-field"
      [placeholder]="inputPlaceholders.letters"
      autocomplete="section-license-plate"
      formControlName="letters"
    />
  }

  @if (form.controls.numbers) {
    <input
      type="text"
      id="license-plate-numbers"
      data-cy="lpn-input-numbers"
      class="lpn-input-field ml-4 min-w-20 disabled:opacity-40"
      part="lpn-input-field"
      [placeholder]="inputPlaceholders.numbers"
      autocomplete="section-license-plate"
      formControlName="numbers"
    />
  }

  @if (form.controls.additionalNumbers) {
    <input
      type="text"
      id="license-plate-additionalNumbers"
      data-cy="lpn-input-additionalNumbers"
      class="lpn-input-field min-w-20 disabled:opacity-40"
      part="lpn-input-field"
      [placeholder]="inputPlaceholders.additionalNumbers"
      autocomplete="section-license-plate"
      formControlName="additionalNumbers"
    />
  }
</div>
