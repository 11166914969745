import { provideState } from "@ngrx/store";
import * as fromAppointment from "./+state/appointment/appointment.reducer";
import * as fromChannelSwitch from "./+state/channel-switch/channel-switch.reducer";
import * as fromContactData from "./+state/contact-data/contact-data.reducer";
import * as fromContext from "./+state/context/context.reducer";
import * as fromCustomerCase from "./+state/customer-case/customer-case.reducer";
import * as fromDamage from "./+state/damage/damage.reducer";
import * as fromGdv from "./+state/gdv/gdv.reducer";
import * as fromInsurance from "./+state/insurance/insurance.reducer";
import { OlbFacade } from "./+state/olb/olb.facade";
import * as fromOpportunityFunnel from "./+state/opportunity-funnel/opportunity-funnel.reducer";
import * as fromProcess from "./+state/process/process.reducer";
import * as fromProduct from "./+state/product/product.reducer";
import * as fromToast from "./+state/toast/toast.reducer";

export function provideOlbState() {
  return [
    provideState(fromDamage.DAMAGE_FEATURE_KEY, fromDamage.reducer),
    provideState(fromGdv.GDV_FEATURE_KEY, fromGdv.reducer),
    provideState(fromCustomerCase.CUSTOMERCASE_FEATURE_KEY, fromCustomerCase.reducer),
    provideState(fromContext.CONTEXT_FEATURE_KEY, fromContext.reducer),
    provideState(fromProcess.PROCESS_FEATURE_KEY, fromProcess.reducer),
    provideState(fromInsurance.INSURANCE_FEATURE_KEY, fromInsurance.reducer),
    provideState(fromToast.TOAST_FEATURE_KEY, fromToast.reducer),
    provideState(fromAppointment.APPOINTMENT_FEATURE_KEY, fromAppointment.reducer),
    provideState(fromProduct.PRODUCT_FEATURE_KEY, fromProduct.reducer),
    provideState(fromContactData.CONTACTDATA_FEATURE_KEY, fromContactData.reducer),
    provideState(fromChannelSwitch.CHANNELSWITCH_FEATURE_KEY, fromChannelSwitch.reducer),
    provideState(fromOpportunityFunnel.OPPORTUNITY_FUNNEL_FEATURE_KEY, fromOpportunityFunnel.reducer),
    OlbFacade
  ];
}
