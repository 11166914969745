import { createFeatureSelector, createSelector } from "@ngrx/store";
import type { ProcessId, ProcessMetadata } from "@cg/shared";
import { PROCESS_FEATURE_KEY, type ProcessState } from "./process.reducer";

// Lookup the 'Process' feature state managed by NgRx
export const getProcessState = createFeatureSelector<ProcessState>(PROCESS_FEATURE_KEY);

export const getProcessMetaData = createSelector(getProcessState, (state: ProcessState) => state.processMetaData);

export const getCurrentProcessId = createSelector(getProcessState, (state: ProcessState) => state.currentProcessId);

export const getProgressPercentage = createSelector(getProcessState, (state: ProcessState) => state.progressPercentage);

export const getProcessMetaDataForProcessId = (id: ProcessId) =>
  createSelector(getProcessState, (state: ProcessState) =>
    state.processMetaData.find((processMetaData: ProcessMetadata) => processMetaData.id === id)
  );

export const getFunnelActive = createSelector(getProcessState, (state: ProcessState) => state.funnelActive);

export const getExitOlb = createSelector(getProcessState, (state: ProcessState) => state.exitOlb);

export const getUrlToNavigateAfterExit = createSelector(
  getProcessState,
  (state: ProcessState) => state.urlToVisitAfterExit
);

export const getTechnicalError = createSelector(getProcessState, (state: ProcessState) =>
  state.technicalError ? state.technicalError : null
);

export const getChannelSwitchReason = createSelector(
  getProcessState,
  (state: ProcessState) => state.channelSwitchReason
);

export const hasFunnelCompleted = createSelector(getProcessState, (state: ProcessState) => state.funnelCompleted);

export const getResumeId = createSelector(getProcessState, (state: ProcessState) => state.resumeId);

export const restoreInProgress = createSelector(getProcessState, (state: ProcessState) => state.restoreInProgress);

export const enterWithStateData = createSelector(getProcessState, (state: ProcessState) => state.enterWithStateData);

export const getRestoredIdsFromState = createSelector(
  getProcessState,
  (state: ProcessState) => state.restoredIdsFromState
);

export const getPassthroughNextTileId = createSelector(
  getProcessState,
  (state: ProcessState) => state.passthroughNextTileId
);
